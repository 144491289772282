import React, { useEffect, useState } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Paymentinfo.css";
import {
	useContributorPaypallistQuery,
	useContributorPaypalstoreMutation,
} from "../../store/services/Contributor/AuthContributorServices";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import Loader from "../../components/Loader";

const PaymentInfo = () => {
	const [Paypalsave, response] = useContributorPaypalstoreMutation();
	const { data, isLoading, refetch } = useContributorPaypallistQuery();
	const Paypals = data?.data;
	const [subError, subErrors] = useState("");
	const [edit, setEdit] = useState(false);
	const schema = yup.object().shape({
		username: yup
			.string()
			.min(4, "Username must be at least 4 characters long")
			.max(20, "Username cannot be greater than 20 characters long")
			.required("Username Title is Required"),
		email: yup.string().email().required("Email is Required"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const handleEdit = () => {
		setEdit(true);
	};
	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("username", data?.username);
		formData.append("email", data?.email);
		Paypalsave(formData);
	};

	useEffect(() => {
		setValue("username", Paypals?.username);
		setValue("email", Paypals?.email);
	}, [Paypals]);

	useEffect(() => {
		if (response?.isSuccess) {
			refetch();
			swal("Success!", response?.data?.message, "success");
			reset();
			setEdit(false);
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						setError(key, {
							type: "manual",
							message: response?.error?.data?.errors[key][0],
						});
					}
				}
			}
		}

		if (response?.error?.data?.statusCode === 400) {
			subErrors(response?.error?.data?.errors?.[0]?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
	}, [response?.isError]);
	// setEdit(false)
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="my-account-wrapper payment">
								<div className="my-account-heading-wrapper">
									<h3>Payment Information</h3>
									{/* <a href="/payment-cheque" className="btn">
										Cheque
									</a> */}
								</div>
								<div className="pyment-extra-heading">
									<h5>Paypal</h5>
									<p className="SubErrrors">{subError}</p>
								</div>
								<div className="my-account-form-wrapper">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Paypal Email <span>*</span>
												</label>
												{edit ? (
													<FormInput
														id="edit"
														name="email"
														type="email"
														placeholder="Please Enter Email"
														register={register}
														error={errors.email}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Paypals?.email ? Paypals?.email : "No Email Found"
														}
														readOnly
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Paypal Username <span>*</span>
												</label>
												{edit ? (
													<FormInput
														id="edit"
														name="username"
														type="text"
														placeholder="Please Enter User Name"
														register={register}
														error={errors.username}
													/>
												) : (
													<input
														placeholder={
															Paypals?.username
																? Paypals?.username
																: "No Username Found"
														}
														readOnly
													/>
												)}
											</div>

											<div className="col-lg-12 mt-3">
												{edit ? (
													<button className="btn mt-md-4 " type="submit">
														{response?.isLoading ? "Loading..." : "Save"}
													</button>
												) : (
													<button
														type="button"
														onClick={handleEdit}
														className="btn"
													>
														Edit
													</button>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default PaymentInfo;
