import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	profile1,
	profile2,
	profile3,
	profile4,
	profile5,
	profile6,
	profile7,
	profile8,
	profile9,
} from "../constant";
import { contributorlogout } from "../store/reducers/AuthReducer";
import { useDispatch } from "react-redux";
const ArtistSidebar = () => {
	const [active, setactive] = useState(0);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const HandlerClickss = (e) => {
		e.preventDefault();
		dispatch(contributorlogout());
	};
	return (
		<>
			{/* Profile Sidebar starts here */}
			<div className="artistSidebar">
				<div className="artist-menu-links-wrapper">
					<ul>
						<li
							onClick={() => {
								setactive(0);
							}}
						>
							<Link
								className={
									location?.pathname === "/artist-account"
										? "nav-item active"
										: "nav-item"
								}
								to="/artist-account"
							>
								<img src={profile1} className="img-fluid" alt="" />
								<span>My Profile</span>
							</Link>
						</li>
						<li
							onClick={() => {
								setactive(1);
							}}
						>
							<Link
								className={
									location?.pathname === "/payment-info"
										? "nav-item active"
										: "nav-item"
								}
								to="/payment-info"
							>
								<img src={profile6} className="img-fluid" alt="" />
								<span>My Payment Information</span>
							</Link>
						</li>
						<li
							onClick={() => {
								setactive(2);
							}}
						>
							<Link
								className={
									location?.pathname === "/my-earnings"
										? "nav-item active"
										: "nav-item"
								}
								to="/my-earnings"
							>
								<img src={profile7} className="img-fluid" alt="" />
								<span>My Earnings</span>
							</Link>
						</li>
						{/* <li>
							<a
								onClick={() => {
									setactive(3);
									navigate("/artist-favourite");
								}}
								className={
									location?.pathname === "/artist-favourite"
										? "nav-item active"
										: "nav-item"
								}
								href=""
							>
								<img src={profile3} className="img-fluid" alt="" />
								<span>My Favourite</span>
							</a>
						</li> */}
						<li
							onClick={() => {
								setactive(4);
							}}
						>
							<Link
								className={
									location?.pathname === "/artist-report"
										? "nav-item active"
										: "nav-item"
								}
								to="/artist-report"
							>
								<img src={profile8} className="img-fluid" alt="" />
								<span>Reports</span>
							</Link>
						</li>
						<li
							onClick={() => {
								setactive(5);
							}}
						>
							<Link
								className={
									location?.pathname === "/upload-work"
										? "nav-item active"
										: "nav-item"
								}
								to="/upload-work"
							>
								<img src={profile9} className="img-fluid" alt="" />
								<span>My Uploads</span>
							</Link>
						</li>
					</ul>
				</div>
				<div className="artist-logout-btn-wrapper">
					<button onClick={HandlerClickss} className="btn">
						Sign Out
					</button>
				</div>
			</div>
			{/* Profile Sidebar ends here */}
		</>
	);
};

export default ArtistSidebar;
