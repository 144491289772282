import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import EyesIcon from "../../components/InputField/EyesIcon";
import { useUserresetPasswordMutation } from "../../store/services/AuthServices";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const ForgetPassword = () => {
	const [Resetpassword, response] = useUserresetPasswordMutation();
	const [showss, setShow] = useState(false);
	const [showss1, setShow1] = useState(false);
	const [subError, subErrors] = useState("");
	let href = window.location?.search?.split("?token=")[1];
	const schema = yup.object().shape({
		password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Password is required"),
		confirm_password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Confirm Password is required"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		if (response?.isSuccess) {
			swal("Success!", response?.data?.message, "success");
			reset();
			navigate("/login");
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors) {
			setError("password", {
				type: "manual",
				message: response?.error?.data?.errors?.password,
			});
			setError("confirm_password", {
				type: "manual",
				message: response?.error?.data?.errors?.confirm_password,
			});
		}

		if (response?.error?.data?.message) {
			subErrors(response?.error?.data?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
	}, [response?.isError]);

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("password", data?.password);
		formData.append("confirm_password", data?.confirm_password);
		formData.append("token", href);
		Resetpassword(formData);
	};
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<section className="login-sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Reset Password</h2>
									<p>Create a new password</p>
								</div>
								<div className="login-form-wrapper">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group setEyesIcon">
											<label htmlFor="">
												New Password <span>*</span>
											</label>
											<FormInput
												id="password"
												name="password"
												type={showss1 ? "text" : "password"}
												placeholder="Password"
												register={register}
												error={errors.password}
											/>
											<EyesIcon
												showss={showss1}
												setShow={setShow1}
												className="Myeyes"
											/>
										</div>
										<div className="form-group setEyesIcon">
											<label htmlFor="">
												Re-Enter New Password <span>*</span>
											</label>
											<FormInput
												id="confirm_password"
												name="confirm_password"
												type={showss ? "text" : "password"}
												placeholder="Confirm Password"
												register={register}
												error={errors.confirm_password}
											/>
											<EyesIcon showss={showss} setShow={setShow} />
										</div>
										<div className="form-group">
											<button className="btn form-control" type="submit">
												{response?.isLoading ? "Loading..." : "Submit"}{" "}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default ForgetPassword;
