import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { blogContestimg, BlogImg1 } from "../../constant";
import "../../assets/css/artist-contest.css";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { useAtistContesteventsQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import Loader from "../../components/Loader";

const ArtistContest = () => {
	const { data, isLoading } = useAtistContesteventsQuery();
	const SliderAll = data?.data?.all;
	const SlidPro = data?.data?.latest;
	const latestPro = data?.data?.top?.[0];
	const navigate = useNavigate();

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 490,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	const HandlerClicked = (e, item) => {
		e.preventDefault();
		navigate(`/artist-content-detail?slug=${item?.slug}`);
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* artist contest Sec Start Here */}
			<section className="blog_sec artist-news-sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Contests & Events</h1>
					</div>
					<div className="row mt-5">
						<div className="col-lg-8">
							<div className="blog-box-wrapper">
								<div
									className="blog-img-wrapper"
									style={{ cursor: "pointer" }}
									onClick={(e) => HandlerClicked(e, latestPro)}
								>
									<figure>
										{/* <Link to="/artist-content-detail"> */}
										<img
											src={latestPro?.image_url}
											className="img-fluid"
											alt={latestPro?.title}
										/>
										{/* </Link> */}
									</figure>
								</div>
								<div className="blog-content-wrapper">
									<h4>{latestPro?.title}</h4>
									<p>{latestPro?.details}</p>
									<button onClick={(e) => HandlerClicked(e, latestPro)}>
										Learn More...
									</button>
								</div>
								{/* a href="/artist-content-detail" */}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="side-blog-wrapper">
								{SlidPro?.map((item, index) => {
									return (
										<div className="blog-box-wrapper" key={index}>
											<div className="blog-content-wrapper">
												<h4>{item?.title}</h4>
												<p>{item?.details}</p>
												<button onClick={(e) => HandlerClicked(e, item)}>
													Learn More...
												</button>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="row">
						<Slider {...settings}>
							{SliderAll?.map((item, index) => {
								return (
									<div
										key={index}
										className="blog-slide-row col-lg-11 text-center"
									>
										<div className="blog_box">
											<div className="blog-img">
												<figure>
													<img
														src={item?.image_url}
														alt={item?.title}
														className="img-fluid"
													/>
												</figure>
											</div>
											<div className="blog-detail">
												<div className="blog-description">
													<h2>{item?.title}</h2>
													<p>{item?.details}</p>
													<button onClick={(e) => HandlerClicked(e, item)}>
														Learn More...
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</Slider>
					</div>
				</div>
			</section>
			{/* artist contest Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistContest;
