import { BroadcastChannel } from "broadcast-channel";
import { useDispatch } from "react-redux";
import { userlogout } from "../store/reducers/AuthReducer";

const logoutChannel = new BroadcastChannel("logout");
const logoutChannel1 = new BroadcastChannel("logout2");

export const Logout = () => {
	// const dispatch = useDispatch();
	logoutChannel.postMessage("Logout");
	localStorage.removeItem("userData");
	// dispatch(userlogout());
	window.location.reload(true);
	// window.location.href = window.location.origin + "/";
};

export const Contributorlogout = () => {
	// const dispatch = useDispatch();
	logoutChannel1.postMessage("Logout2");
	localStorage.removeItem("ContributorData");
	// dispatch(userlogout());
	window.location.reload(true);
	// window.location.href = window.location.origin + "/";
};

export const LogoutAllTabs = () => {
	logoutChannel.onmessage = () => {
		Logout();
		logoutChannel.close();
	};
};
export const LogoutAllTabs2 = () => {
	logoutChannel1.onmessage = () => {
		Contributorlogout();
		logoutChannel1.close();
	};
};
