import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import { useEffect } from "react";
import { useContributoreditPasswordMutation } from "../../store/services/Contributor/AuthContributorServices";
import EyesIcon from "../../components/InputField/EyesIcon";

function ArtistPassword({ seteditpass }) {
	const [ChangePassword, response] = useContributoreditPasswordMutation();
	const [showss, setShow] = useState(false);
	const [showss1, setShow1] = useState(false);
	const [showss2, setShow2] = useState(false);
	const schemaModule = yup.object().shape({
		password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Password is required"),
		old_password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Old Password is required"),
		password_confirmation: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Confirmation Password is required"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schemaModule),
	});

	useEffect(() => {
		if (response?.isSuccess) {
			swal("Success!", response?.data?.message, "success");
			reset();
			seteditpass(false);
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						setError(key, {
							type: "manual",
							message: response?.error?.data?.errors[key][0],
						});
					}
				}
			}
		}
	}, [response?.isError]);

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("password", data?.password);
		formData.append("old_password", data?.old_password);
		formData.append("password_confirmation", data?.password_confirmation);
		ChangePassword(formData);
	};

	return (
		<form onSubmit={handleSubmit(onSubmitHandler)}>
			<div className="form-group row">
				<div className="col-lg-4 setEyesIconsd">
					<label htmlFor="">
						Old Password <span>*</span>
					</label>
					<FormInput
						id="edit"
						name="old_password"
						type={showss ? "text" : "password"}
						placeholder="Old Password"
						register={register}
						error={errors.old_password}
					/>
					<EyesIcon showss={showss} setShow={setShow} className="Myeyes" />
				</div>
				<div className="col-lg-4 setEyesIconsd">
					<label htmlFor="">
						New Password <span>*</span>
					</label>

					<FormInput
						id="edit"
						name="password"
						type={showss1 ? "text" : "password"}
						placeholder="New Password"
						register={register}
						error={errors.password}
					/>
					<EyesIcon showss={showss1} setShow={setShow1} className="Myeyes" />
				</div>
				<div className="col-lg-4 setEyesIconsd">
					<label htmlFor="">
						Confirm Password <span>*</span>
					</label>

					<FormInput
						id="edit"
						name="password_confirmation"
						type={showss2 ? "text" : "password"}
						placeholder="Confirm Password"
						register={register}
						error={errors.password_confirmation}
					/>
					<EyesIcon showss={showss2} setShow={setShow2} className="Myeyes" />
				</div>
				<div className="col-lg-4 ">
					<button type="submit" className="btn mt-5">
						{response?.isLoading ? "Loading..." : "Save Password"}
					</button>
				</div>
			</div>
		</form>
	);
}

export default ArtistPassword;
