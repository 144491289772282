import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Private = () => {
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);

	return contrubutorToken !== "" ? (
		<Outlet />
	) : (
		<Navigate to="/Login-page" />
	);
};
export default Private;
