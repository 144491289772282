import React, { useState, useRef, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/contact.css";
import { contactmail, contactPhone, backArrow } from "../../constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GoTop from "../../components/GoTop";
import { useContactUSMutation } from "../../store/services/ContactServices";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Backhistory from "../../components/Backhistory";

const Contact = () => {
	const [contactData, response] = useContactUSMutation();
	const navigate = useNavigate();
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const schema = yup.object().shape({
		email: yup.string().email().required("Email is a required field"),
		first_name: yup
			.string()
			.min(3, "First name must be at least 3 characters long")
			.max(15, "First name cannot be greater than 15 characters long")
			.required("First name is required"),
		last_name: yup
			.string()
			.min(2, "Last name must be at least 2 characters long")
			.max(15, "Last name cannot be greater than 15 characters long")
			.required("Last name is required"),
		contact_number: yup.string().nullable().notRequired(),
	});
	// .matches(phoneRegExp, "Phone number is not valid")
	const [message, setMessage] = useState("");
	const [subError, subErrors] = useState("");
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (response?.isSuccess) {
			reset();
			setMessage("");
			setShow(true);

			// swal("Success!", response?.data?.message, "success");
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors) {
			setError("email", {
				type: "manual",
				message: response?.error?.data?.errors?.email,
			});
			setError("first_name", {
				type: "manual",
				message: response?.error?.data?.errors?.first_name,
			});
			setError("last_name", {
				type: "manual",
				message: response?.error?.data?.errors?.last_name,
			});
			setError("contact_number", {
				type: "manual",
				message: response?.error?.data?.errors?.contact_number,
			});
		}
	}, [response?.isError]);

	const onSubmitHandler = (data) => {
		if (message === "") {
			subErrors("Please Enter message");
		} else {
			let formData = new FormData();
			formData.append("first_name", data?.first_name);
			formData.append("last_name", data?.last_name);
			formData.append("contact_number", data?.contact_number);
			formData.append("email", data?.email);
			formData.append("message", message);
			contactData(formData);
			setTimeout(() => {
				subErrors("");
			}, 3000);
		}
	};

	const inputEl = useRef(null);

	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		navigate(-1);
	};
	const handleShow = () => setShow(true);

	const HandleSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<>
			<GoTop />
			{/* Contact Page modal starts here */}
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				className="contactmodal"
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					{/* contact form modal starts here */}
					<div className="contact-form-modal-content-wrapper">
						<h2>Your Query Has Been Successfully Submitted</h2>
						<p>We will get back to you shortly</p>
					</div>

					{/* contact form modal starts here */}
				</Modal.Body>
				{/* <Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary">Understood</Button>
				</Modal.Footer> */}
			</Modal>
			{/* Contact Page modal starts here */}
			<Header />
			{/* Contact sec starts here */}
			<section className="contact">
				<div className="container">
					<Backhistory classNa={"back_icon d-flex justify-content-end mb-3"} />

					<div className="row pt-3">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="contact-us-card-wrapper">
								<div className="contact-heading-wrapper">
									<h2>Get In Touch</h2>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do <br /> eiusmod tempor incididunt ut labore et dolore
										magna aliqua.
									</p>
								</div>
								{/* <div className="contact-icon-wrapper">
									<div className="row">
										<div className="col-lg-6">
											<div className="single-contact-wrapper">
												<div className="single-contact-img-wrapper">
													<img
														src={contactPhone}
														className="img-fluid"
														alt=""
													/>
												</div>
												<div className="single-contact-content-wrapper">
													<h5>Mail</h5>
													<h4>Info@Example.Com</h4>
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="single-contact-wrapper">
												<div className="single-contact-img-wrapper">
													<img src={contactmail} className="img-fluid" alt="" />
												</div>
												<div className="single-contact-content-wrapper">
													<h5>Contact</h5>
													<h4>+123 456 789</h4>
												</div>
											</div>
										</div>
									</div>
								</div> */}
								<div className="contact-form-wrapper mt-5">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group">
											<label htmlFor="">
												First name <span>*</span>
											</label>
											<FormInput
												id="first_name"
												name="first_name"
												type="text"
												placeholder="First Name"
												register={register}
												error={errors.first_name}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Last Name <span>*</span>
											</label>
											<FormInput
												id="last_name"
												name="last_name"
												type="text"
												placeholder="Last Name"
												register={register}
												error={errors.last_name}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Email Address <span>*</span>
											</label>
											<FormInput
												id="email"
												name="email"
												type="email"
												placeholder="Email Address"
												register={register}
												error={errors.email}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Contact Number </label>
											<FormInput
												id="contact_number"
												name="contact_number"
												type="number"
												placeholder="Phone Number"
												register={register}
												error={errors.contact_number}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Message <span>*</span>
											</label>
											<textarea
												className="form-control"
												id="exampleFormControlTextarea1"
												rows="3"
												placeholder="Message here..."
												required=""
												name="message"
												value={message}
												onChange={(e) => setMessage(e.target.value)}
											></textarea>
											{subError && <p className="errormessage">{subError}</p>}
										</div>
										<div className="form-group">
											<div className="btn-wrapper text-end">
												<Button
													type="submit"
													className="btn"
													variant="primary"
													// onClick={handleShow}
												>
													{response?.isLoading ? "Loading..." : "Send Message"}{" "}
												</Button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* Contact sec ends here */}
			<Footer />
		</>
	);
};

export default Contact;
