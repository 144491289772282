import React from "react";
import { logo } from "../constant";
import "../assets/css/notFound.css";
import Header from "./Header";
import Footer from "./Footer";
const Notfound = () => {
	return (
		<>
			<Header />
			<main className="not__found-wrap">
				<div className="about-banner-text">
					<h1>404 Not Found</h1>
					<h2>The page you’re looking for can’t be found.</h2>
				</div>
				<img src={logo} alt="Moonscape" className="img-fluid" />
			</main>
			<Footer />
		</>
	);
};

export default Notfound;
