import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
	BlogImg1,
	BlogImg2,
	BlogImg3,
	RecentNews1,
	backArrow,
} from "../../constant";
import "../../assets/css/blog.css";
import { Link } from "react-router-dom";
import GoTop from "../../components/GoTop";
import { useState } from "react";
import moment from "moment/moment";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import { useArtistBlogContentQuery } from "../../store/services/ArtistCornerServices/ArtistNewsService";
import Backhistory from "../../components/Backhistory";

const Blog = () => {
	const [Category, setCategory] = useState("");
	const [currentpage, setCurrentpage] = useState(1);

	// Artist Corner Blog Api Call
	let datads = {
		page: currentpage,
		slug: Category,
	};
	const { data, isLoading } = useArtistBlogContentQuery(datads);
	let blogData = data?.data;
	// Pagination
	const pages = Math.ceil(blogData?.blogs?.total_size / blogData?.blogs?.limit);
	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			<GoTop />
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec">
				<div className="container">
					<div className="back_gif_main d-flex justify-content-between align-items-center">
						<div className="testimonials-wrapper">
							<h1>Blog</h1>
						</div>
						<Backhistory classNa={"back_icon"} />
					</div>
					<div className="row setDatas">
						<div className="col-lg-8">
							{blogData?.blogs?.blogss?.length > 0 ? (
								blogData?.blogs?.blogss?.map((item, index) => {
									return (
										<div className="all_blogs" key={index}>
											<Link to={`/blog-detail?slug=${item?.slug}`}>
												<div className="blog_box">
													<div className="blog-img">
														<figure>
															<img
																src={item?.image_url}
																alt="Blog Image"
																className="img-fluid"
															/>
														</figure>
														<div className="date">
															<h4>
																{moment(item?.created_at).format("Do MMM")}
															</h4>
														</div>
													</div>
													<div className="blog-detail">
														<div className="posted">
															<h5>
																{item?.addedby?.name} /
																<span>{item?.category?.name}</span>
															</h5>
														</div>
														<div className="blog-description">
															<h2>{item?.title}</h2>
															<div
																className="blog-inner-para"
																dangerouslySetInnerHTML={{
																	__html: item?.description,
																}}
															></div>
														</div>
													</div>
												</div>
											</Link>
										</div>
									);
								})
							) : (
								<h2
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										textAlign: "center",
									}}
									className="notFounddata"
								>
									Blog Not Found
								</h2>
							)}
							{blogData?.blogs?.blogss?.length > 0 && (
								<div className="paginations-wrapper">
									<Pagination pages={pages} handlePageClick={handlePageClick} />
								</div>
							)}
						</div>

						<div className="col-lg-4">
							<div className="sidebar">
								{blogData?.categories?.length > 0 && (
									<div className="categories sidebar-box">
										<div className="title">
											<h4>Categories</h4>
										</div>
										<ul className="list">
											{blogData?.categories?.map((item, index) => {
												return (
													<li key={index}>
														<a onClick={() => setCategory(item?.slug)}>
															<span className="name">{item?.name}</span>
															<span className="count">
																({item?.blogs_count})
															</span>
														</a>
													</li>
												);
											})}
										</ul>
									</div>
								)}
								{blogData?.recentpost?.length > 0 && (
									<div className="recent-news sidebar-box">
										<div className="title">
											<h4>Recent Posts</h4>
										</div>
										<ul className="list">
											{blogData?.recentpost?.map((item, index) => {
												return (
													<li key={index}>
														<a href={`/blog-detail?slug=${item?.slug}`}>
															<div className="img-box">
																<figure className="mb-0">
																	<img
																		src={item?.image_url}
																		alt="Blog Image"
																		className="img-fluid"
																	/>
																</figure>
															</div>
															<div className="content">
																<p className="date">
																	<i className="fa-solid fa-calendar"></i>
																	{item?.date}
																</p>
																<div
																	className="blog-inner-para"
																	dangerouslySetInnerHTML={{
																		__html: item?.description,
																	}}
																></div>
															</div>
														</a>
													</li>
												);
											})}
										</ul>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Blog;
