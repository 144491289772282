import React, { useState } from "react";
import { TwitterPicker } from "react-color";
import { useProductsFilterQuery } from "../store/services/ContactServices";
import { useDispatch, useSelector } from "react-redux";
import { backArrow } from ".././constant";
import {
	selectColor,
	selectType,
	setCategoryId,
	setFilterId,
	setTagsname,
	setTypeId,
} from "../store/reducers/ImageFilterReducer";
import { useEffect } from "react";
import { autherName } from "../store/reducers/AuthReducer";
import Backhistory from "./Backhistory";
function ImageFilter({
	setShow,
	notshowRegister,
	refetch,
	allNotShow,
	setTpye,
	Total_size = 0,
	type,
}) {
	console.log(type, "typeeee");
	const dispatch = useDispatch();
	const { tagsName, filterId, cateId, tyId, colors } = useSelector(
		(state) => state.ImageFilterReducer,
	);
	const { userToken, autherLink } = useSelector((state) => state.AuthReducer);

	const { data: allFilters, isLoading: filterload } = useProductsFilterQuery();
	const [showColor, setShowColor] = useState(5);
	const category_filters = allFilters?.categories;
	const imageType_filters = allFilters?.types;
	const Trending_filters = allFilters?.trending;
	const Colors_Codes = allFilters?.colorcodes;
	const Tags_filter = allFilters?.tags;
	const [categoryFilter, setCategoryFilter] = useState(cateId);
	const [categoryTypes, setTypesFilter] = useState(tyId);
	const [categoryTrending, setTrendingFilter] = useState(filterId);
	const [filter, setFilter] = useState(false);
	const [background, setBackground] = useState("");
	const [hexa, setHexa] = useState(background);
	const [Iamgetype, setIamgeType] = useState(type == "Video" ? type : "Image");
	const [colorCode, setColorCode] = useState("");

	const [tagsData, setTagsData] = useState(tagsName);

	const setHandleCategoryFilter = (item) => {
		setCategoryFilter(item);
		dispatch(setCategoryId(item));
	};

	const HandleTagClick = (e, item) => {
		e.preventDefault();
		const filtered = tagsData.filter((clr) => clr.tag !== item.tag);
		setTagsData([...filtered, item]);
	};

	const deleteColor = (item) => {
		const filtered = tagsData.filter((clr) => clr.tag !== item.tag);
		setTagsData(filtered);
	};

	const autherFilter = autherLink !== "" ? 1 : 0;
	const tagFilterlength = tagsName?.length > 0 ? 1 : 0;
	const pakagelength = tyId !== "" ? 1 : 0;
	const trendinglength = filterId !== "" ? 1 : 0;
	const categorylength = cateId !== "" ? 1 : 0;
	const colorlength = colors ? 1 : 0;
	const totalCount =
		autherFilter +
		tagFilterlength +
		pakagelength +
		trendinglength +
		categorylength;

	const ClearAllData = () => {
		setTagsData([]);
		refetch();
	};

	const HandleReset = () => {
		setCategoryFilter("");
		setTypesFilter("");
		setTrendingFilter("");
		setColorCode("");
		setTagsData([]);
		dispatch(selectColor(null));
		dispatch(selectType(""));
		dispatch(setCategoryId(""));
		dispatch(setFilterId(""));
		dispatch(setTagsname([]));
		dispatch(setTypeId(""));
		dispatch(autherName(""));
	};

	useEffect(() => {
		dispatch(setTagsname(tagsData));
		dispatch(setFilterId(categoryTrending));
		dispatch(setTypeId(categoryTypes));
		dispatch(selectType(Iamgetype));
	}, [tagsData, categoryTrending, categoryTypes, Iamgetype]);

	useEffect(() => {
		if (colorCode) {
			dispatch(selectColor(colorCode ? colorCode?.split("#")?.[1] : null));
		}
	}, [colorCode]);

	const [picker, setPicker] = useState(false);
	const toggleChecked = () => setPicker((value) => !value);

	const saveColors = (color) => {
		setBackground(color.hex);
		setHexa(color.hex);
	};

	const saveColors2 = (e) => {
		setHexa(e.target.value);
		setBackground(e.target.value);
	};
	return (
		<div className="topbar">
			<div className="left-bar">
				{allNotShow && (
					<div className="heading_wrapper">
						<h2>{setShow}</h2>
						<p>
							{Total_size} stock photos and photography are available
							royalty-free.
						</p>
					</div>
				)}

				<div className="filter-div">
					<ul className="filters">
						{tagsData?.map((item, index) => {
							return (
								<li key={index}>
									<span className="name">{item?.tag}</span>
									<span className="cancel" onClick={() => deleteColor(item)}>
										x
									</span>
								</li>
							);
						})}
					</ul>
					{tagsData?.length > 0 && (
						<div className="button-group">
							<button className="clear" onClick={ClearAllData}>
								Clear All
							</button>
						</div>
					)}
				</div>
			</div>

			<div className="right-bar">
				<Backhistory classNa={"back_icon mb-2"} />

				{!userToken && (
					<div className="title">
						<h3>
							{" "}
							<a
								style={{ color: "var(--extra-color-2)" }}
								href="/register-buyer"
							>
								Register Now
							</a>
						</h3>
						<p>And Get 12 Free Images</p>
					</div>
				)}

				<div className="button-group mt-2">
					<button className="filter mx-sm-3" onClick={HandleReset}>
						Reset Filters ({totalCount + colorlength})
					</button>
					<button onClick={() => setFilter(true)} className="filter">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="25"
							height="19.985"
							viewBox="0 0 25 19.985"
						>
							<g
								id="Group_8672"
								data-name="Group 8672"
								transform="translate(-791.99 -405.606)"
							>
								<path
									id="Path_8719"
									data-name="Path 8719"
									d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
									transform="translate(-113.083 0)"
									fill="#fff"
								/>
								<path
									id="Path_8720"
									data-name="Path 8720"
									d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
									transform="translate(-226.192 -0.18)"
									fill="#fff"
								/>
								<path
									id="Path_8721"
									data-name="Path 8721"
									d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
									transform="translate(0 -0.188)"
									fill="#fff"
								/>
							</g>
						</svg>
						Filter
					</button>
				</div>
				<div className={filter ? "flter-box show" : "flter-box"}>
					<button onClick={() => setFilter(false)} className="cancel">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							fill="#fff"
							className="bi bi-x"
							viewBox="0 0 16 16"
						>
							<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
						</svg>
					</button>
					<div className="topbar">
						<button
							className="btn"
							type="button"
							onClick={() => setFilter(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="25"
								height="19.985"
								viewBox="0 0 25 19.985"
							>
								<g
									id="Group_8672"
									data-name="Group 8672"
									transform="translate(-791.99 -405.606)"
								>
									<path
										id="Path_8719"
										data-name="Path 8719"
										d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
										transform="translate(-113.083 0)"
										fill="#fff"
									/>
									<path
										id="Path_8720"
										data-name="Path 8720"
										d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
										transform="translate(-226.192 -0.18)"
										fill="#fff"
									/>
									<path
										id="Path_8721"
										data-name="Path 8721"
										d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
										transform="translate(0 -0.188)"
										fill="#fff"
									/>
								</g>
							</svg>
						</button>
						<h4>Filter</h4>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="radio-box">
								<div className="head">
									<h3>Categories</h3>
								</div>
								<ul className="list">
									{category_filters?.map((item, index) => {
										return (
											<li key={index}>
												<label
													className="filter_container form-check-label"
													htmlFor={`categorysd${index}`}
												>
													<input
														id={`categorysd${index}`}
														className={`${
															categoryFilter === ""
																? "form-check-input unactive"
																: "form-check-input active"
														}`}
														hidden
														defaultChecked={item?.id === categoryFilter}
														type="radio"
														name="category_radio"
														onClick={() => setHandleCategoryFilter(item?.id)}
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>{item?.name}</h4>
													</span>
												</label>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="radio-box">
								<div className="head">
									<h3>Image / Video Type</h3>
								</div>
								<ul className="list">
									{imageType_filters?.map((item, index) => {
										return (
											<li key={index}>
												<label className="filter_container form-check-label">
													<input
														className={`${
															categoryTypes === ""
																? "form-check-input unactive"
																: "form-check-input active"
														}`}
														hidden
														type="radio"
														name="image_radio"
														defaultChecked={item?.name === categoryTypes}
														onClick={() => setTypesFilter(item?.name)}
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>{item?.name}</h4>
													</span>
												</label>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						{setTpye && (
							<div className="col-lg-12">
								<div className="radio-box">
									<div className="head">
										<h3>Select Type</h3>
									</div>
									<ul className="list">
										<li>
											<label
												className="filter_container form-check-label"
												onClick={() => setIamgeType("Image")}
											>
												<input
													className="form-check-input"
													hidden
													type="radio"
													name="image_radio"
													defaultChecked={Iamgetype === "Image"}
												/>
												<span className="checkmark">
													<div className="dot"></div>
													<h4>Image</h4>
												</span>
											</label>
										</li>
										<li>
											<label
												className="filter_container form-check-label"
												onClick={() => setIamgeType("Video")}
											>
												<input
													className="form-check-input"
													hidden
													type="radio"
													name="image_radio"
													defaultChecked={Iamgetype === "Video"}
												/>
												<span className="checkmark">
													<div className="dot"></div>
													<h4>Video</h4>
												</span>
											</label>
										</li>
									</ul>
								</div>
							</div>
						)}

						<div className="col-lg-12">
							<div className="radio-box">
								<div className="head">
									<h3>Trending Now</h3>
								</div>
								<ul className="list">
									{Trending_filters?.map((item, index) => {
										return (
											<li key={index}>
												<label className="filter_container form-check-label">
													<input
														className={`${
															categoryTrending === ""
																? "form-check-input unactive"
																: "form-check-input active"
														}`}
														hidden
														type="radio"
														name="trending__radio"
														defaultChecked={item?.name === categoryTrending}
														onClick={() => setTrendingFilter(item?.name)}
													/>
													<span className="checkmark">
														<div className="dot"></div>
														<h4>{item?.name}</h4>
													</span>
												</label>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="radio-box tags">
								<div className="head">
									<h3>Tags</h3>
									{/* <button className="clear">Clear All</button> */}
								</div>
								<ul>
									{Tags_filter?.map((item, index) => {
										return (
											<li key={index}>
												<button onClick={(e) => HandleTagClick(e, item)}>
													{/* <span className="cut">x</span> */}
													<span className="name">{item?.tag}</span>
												</button>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						{setTpye === true
							? Iamgetype === "Image" && (
									<div className="col-lg-12">
										<div className="radio-box colors">
											<div className="head">
												<h3>Color</h3>
												{Colors_Codes?.length > 5 && (
													<button
														className="clear"
														onClick={() => setShowColor(showColor + 5)}
													>
														Show More
													</button>
												)}
											</div>
											<ul className="list">
												{Colors_Codes?.map((item, index) => {
													return (
														index < showColor && (
															<li
																onClick={() => setColorCode(item)}
																key={index}
															>
																<label className="filter_container form-check-label">
																	<input
																		className="form-check-input"
																		hidden
																		type="radio"
																		name="color_radio"
																		defaultChecked={colorCode === item}
																	/>
																	<span className="checkmark">
																		<div
																			className="dot red"
																			style={{ backgroundColor: item }}
																		></div>
																	</span>
																</label>
															</li>
														)
													);
												})}
											</ul>
										</div>
									</div>
							  )
							: setShow === "Images" && (
									<div className="col-lg-12">
										<div className="radio-box colors">
											<div className="head">
												<h3>Color</h3>
												{Colors_Codes?.length > 5 && (
													<button
														className="clear"
														onClick={() => setShowColor(showColor + 5)}
													>
														Show More
													</button>
												)}
											</div>
											<ul className="list">
												{Colors_Codes?.map((item, index) => {
													return (
														index < showColor && (
															<li
																onClick={() => setColorCode(item)}
																key={index}
															>
																<label className="filter_container form-check-label">
																	<input
																		className="form-check-input"
																		hidden
																		type="radio"
																		name="color_radio"
																		defaultChecked={colorCode === item}
																	/>
																	<span className="checkmark">
																		<div
																			className="dot red"
																			style={{ backgroundColor: item }}
																		></div>
																	</span>
																</label>
															</li>
														)
													);
												})}
											</ul>
										</div>
									</div>
							  )}

						{/* <div className="col-lg-12">
							<div className="radio-box color-picker">
								<div className="head">
									<h3>Hex Code</h3>
								</div>
								<div className="form-group">
									<div className="clip-field">
										<input
											type="text"
											value={hexa}
											onChange={saveColors2}
											placeholder="Enter Hex or select "
											className="form-control"
										/>
										<button
											onClick={toggleChecked}
											className="background"
											style={{ backgroundColor: hexa }}
										></button>
									</div>
									<TwitterPicker
										className={picker ? "picker-show" : ""}
										onChangeComplete={saveColors}
										color={hexa}
									/>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageFilter;
