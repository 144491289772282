import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseUrl, pricePackage } from "../../utils/Urls";

const PricePackagesServices = createApi({
	reducerPath: "pricedata",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrl,
	}),
	endpoints: (builder) => {
		return {
			pricePage: builder.query({
				query: () => {
					return {
						url: pricePackage,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const { usePricePageQuery } = PricePackagesServices;
export default PricePackagesServices;
