import React from "react";

function ShowError({ errorArray = [], type }) {
	const Filter = errorArray?.filter((item) => item?.type === type);
	return (
		Filter?.length !== 0 && <p className="SubErrrors">{Filter?.[0]?.message}</p>
	);
}

export default ShowError;
