import React, { useEffect } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/removal-content.css";
import { upload1, upload2, upload3, upload4 } from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	useContributorRemovalProductMutation,
	useContributorRemovalQuery,
	useContributorusermeInfoQuery,
} from "../../store/services/Contributor/AuthContributorServices";
import { useState } from "react";
import ShowError from "../Upload/ShowError";
import swal from "sweetalert";
const RemovalContent = () => {
	const { data } = useContributorusermeInfoQuery();
	const { data: removalPoint, isLoading } = useContributorRemovalQuery();
	const [deleteData, response] = useContributorRemovalProductMutation();
	const [errorss, setErrorss] = useState("");
	const getRemoval = removalPoint;
	const [removalOne, setHandleRemovalOne] = useState("");
	const [removalTwo, setHandleRemovalTwo] = useState("");
	const [description, setDescription] = useState("");
	const [multipleError, setMultipleError] = useState([]);
	const [error, setError] = useState([]);
	const Userinfo = data?.data;
	const location = useLocation();
	const product = location?.state?.data;
	const navigate = useNavigate();

	const HandleDelete = (e) => {
		e.preventDefault();
		if (removalOne === "") {
			setError({
				name: "removalOne_radio",
				message: "Please Select Remove Content",
			});
		} else if (removalTwo === "") {
			setError({
				name: "removalTwo_radio",
				message: "Please Select Remove Content",
			});
		} else if (description === "") {
			setError({ name: "description", message: "Please Add Description" });
		} else if (description?.length < 15 || description?.length > 300) {
			setError({
				name: "description",
				message:
					"Description must be at least 15 characters long And Description cannot be greater than 300 characters long",
			});
		} else {
			setError([]);
			const formData = new FormData();
			formData.append("product_id", product?.id);
			formData.append("removal_id_one", removalOne);
			formData.append("contributor_name", Userinfo?.f_name + Userinfo?.l_name);
			formData.append("asset_types", product?.asset_type);
			formData.append("asset_id", product?.code);
			formData.append("description", description);
			formData.append("removal_id_two", removalTwo);
			deleteData(formData);
		}
	};

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						setMultipleError([
							{
								type: key,
								message: response?.error?.data?.errors[key][0],
							},
						]);
					}
				}
			}
		}

		if (response?.error?.data?.message) {
			setErrorss(response?.error?.data?.message);
			setTimeout(() => {
				setErrorss("");
			}, 5000);
		}
	}, [response?.isError]);

	useEffect(() => {
		if (response?.isSuccess) {
			swal(
				"Success!",
				"Your request has been submitted successfully and is sent to the admin for approval",
				"success",
			);
			setHandleRemovalOne("");
			setHandleRemovalTwo("");
			setDescription("");
			navigate("/upload-work");
		}
	}, [response.isSuccess]);

	return (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-6">
							<div className="request-removal-wrapper">
								<div className="removal-heading-wrapper">
									<h3>Request For Removal OF Content</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim{" "}
									</p>
									<p className="SubErrrors">
										{errorss !== "Bad Request!" && errorss}
									</p>
								</div>
								<div className="request-form-wrapper">
									<form>
										<div className="form-group">
											<label htmlFor="">
												Contributor Name <span>*</span>
											</label>
											<input
												type="text"
												placeholder={`${Userinfo?.f_name}  ${Userinfo?.l_name}`}
												className="form-control"
												disabled
											/>
										</div>

										<div className="form-group row">
											<div className="col-lg-6">
												<label htmlFor="">
													Asset Type <span>*</span>
												</label>
												<input
													type="text"
													placeholder={`${product?.asset_type}`}
													className="form-control"
													disabled
												/>
											</div>
											<div className="col-lg-6">
												<label htmlFor="">
													Asset Id Number <span>*</span>
												</label>
												<input
													type="text"
													placeholder={`${product?.code}`}
													className="form-control"
													disabled
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<h6>Why would you like us to remove this content?</h6>
												{getRemoval?.first?.map((item, index) => {
													return (
														<div className="form-check" key={index + 1}>
															<input
																id={`point${index}`}
																className="form-check-input"
																defaultChecked={item?.id === removalOne}
																type="radio"
																name="removalOne_radio"
																onClick={() => setHandleRemovalOne(item?.id)}
															/>
															<label
																className="form-check-label"
																htmlFor={`point${index}`}
															>
																{item?.points}
															</label>
														</div>
													);
												})}
												<p className="errormessage">
													{error?.name === "removalOne_radio" && (
														<div>{error?.message}</div>
													)}
												</p>
												<ShowError
													errorArray={multipleError}
													type="removal_id_one"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-10">
												<span className="will">
													“We will be sorry to see you go. Can you please take a
													moment to let us know why you are leaving, so that we
													can make some improvements?{" "}
												</span>
												{getRemoval?.second?.map((item, index) => {
													return (
														<div className="form-check" key={index + 1}>
															<input
																id={`pointss${index}`}
																className="form-check-input"
																defaultChecked={item?.id === removalTwo}
																type="radio"
																name="removalTwo_radio"
																onClick={() => setHandleRemovalTwo(item?.id)}
															/>
															<label
																className="form-check-label"
																htmlFor={`pointss${index}`}
															>
																{item?.points}
															</label>
														</div>
													);
												})}

												<p className="errormessage">
													{error?.name === "removalTwo_radio" && (
														<div>{error?.message}</div>
													)}
												</p>
												<ShowError
													errorArray={multipleError}
													type="removal_id_two"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<span className="light">
													We value your opinion and hope that you can write a
													few words to elaborate or to provide additional
													feedback.
												</span>
												<textarea
													className="form-control"
													name="description"
													placeholder="Please type your comments here"
													id=""
													cols="30"
													rows="5"
													value={description}
													onChange={(e) => setDescription(e.target.value)}
												></textarea>
												<p className="errormessage">
													{error?.name === "description" && (
														<div>{error?.message}</div>
													)}
												</p>
												<ShowError
													errorArray={multipleError}
													type="description"
												/>
											</div>
										</div>
										<div className="form-group">
											<div className="divider-wrapper">
												<div className="divider"></div>
											</div>
										</div>
										<div className="form-group">
											<div className="col-lg-12">
												<div className="btn-wrapper">
													<button onClick={HandleDelete} className="btn">
														{response?.isLoading
															? "Loading..."
															: "Save & Proceed"}
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default RemovalContent;
