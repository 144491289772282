import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/help.css";
import { help1, help2, help3, help4, mail, backArrow } from "../../constant";
import { useGetHelpDataQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";
import Backhistory from "../../components/Backhistory";

const HowCanHelp = () => {
	const { data, isLoading } = useGetHelpDataQuery();
	let help_data = data?.data?.help;
	let populat_data = data?.data?.popular;
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Help page header starts here */}
			<section className="help-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="help-card-wrapper">
								<div className="help-heading-wrapper">
									<h2>{help_data?.section_1_title}</h2>
									<p
										dangerouslySetInnerHTML={{
											__html: help_data?.section_1_description,
										}}
									></p>
								</div>
								{/* <div className="search-wrapper">
									<input type="text" placeholder="Search here..." />
									<button className="submit">
										<i className="fa fa-search"></i>
									</button>
								</div> */}
							</div>
						</div>
						<div className="col-lg-3">
							<Backhistory classNa={"back_icon text-end"} />
						</div>
					</div>
				</div>
			</section>
			{/* Help page header ends here */}
			{/* Popular starts here */}
			<section className="popular-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="populat-heading-wrapper">
								<h2>Popular Topics</h2>
							</div>
							<div className="popular-cards-wrapper">
								{populat_data?.map((item, index) => {
									return (
										<div className="popular-card-wrapper" key={index}>
											<div className="popular-img-wrapper">
												<img
													src={item?.image_url}
													className="img-fluid"
													alt="Image not found"
												/>
											</div>
											<div className="popular-content-wrapper">
												<h4>{item?.title}</h4>
												<p
													dangerouslySetInnerHTML={{
														__html: item?.description,
													}}
												></p>
											</div>
										</div>
									);
								})}
							</div>
							<div className="cant-find-wrapper">
								<a href={`mailto:${data?.data?.email?.value}`}>
									<div className="cant-find-heading-wrapper">
										<h2>{help_data?.section_3_title}</h2>
									</div>
									<div className="cant-find-card-wrapper">
										<div className="cant-img-wrapper">
											<img src={mail} className="img-fluid" alt="" />
										</div>
										<div className="cant-content-wrapper">
											<h4>{help_data?.section_3_sub_title}</h4>
											<p>{help_data?.section_3_description}</p>
										</div>
										<div className="cant-button-wrapper">
											{/* <a href="#" className="btn">
											Choose
										</a> */}
										</div>
									</div>
								</a>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* Popular ends here */}
			<Footer />
		</>
	);
};

export default HowCanHelp;
