import React, { useState } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/upload-work.css";
import { saveAs } from "file-saver";

import { upload1, upload2, upload3, upload4 } from "../../constant";
import {
	useContributorUploadWorkDeleteMutation,
	useContributorUploadWorkQuery,
} from "../../store/services/Contributor/AuthContributorServices";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
const UploadedWork = () => {
	const { data, isLoading } = useContributorUploadWorkQuery();
	const UploadData = data?.data;
	const navigate = useNavigate();
	const HandleDelete = (e, item) => {
		e.preventDefault();
		navigate("/removal-content", { state: { data: item } });
		// deleteData(id);
	};

	const ImageDownloader = (imageSrc, image) => {
		saveAs(imageSrc, image);
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="uploaded-work-wrapper">
								<div className="uploaded-heading-wrapper">
									<h2>Uploaded Work</h2>
								</div>
								<div className="uploaded-table-wrapper">
									<div className="table-responsive">
										{UploadData?.length > 0 ? (
											<table className="table">
												<thead>
													<tr>
														<th className="img-col"></th>
														<th className="title-heading">Title</th>
														<th className="">Status</th>
														<th className="">Refrence Number</th>
														<th className="Actions-heading action-right">
															Actions
														</th>
													</tr>
												</thead>
												<tbody>
													{UploadData?.map((item, index) => {
														return (
															<tr key={index}>
																<td className="img-wrapper">
																	<figure>
																		<img
																			src={item?.image_url}
																			className="img-fluid"
																			alt={item?.name}
																		/>
																	</figure>
																</td>
																<td className="title-head">
																	<h5>{item?.name}</h5>
																</td>
																<td className="title-head">
																	<h5>{item?.product_status}</h5>
																</td>
																<td className="title-head">
																	<h5>{item?.code}</h5>
																</td>
																<td className="actions-btn-wrapper">
																	<div className="btn-wrapper">
																		<a
																			href={item?.watermark_image_url}
																			target="_blank"
																		>
																			View
																		</a>
																		<button
																			onClick={() => {
																				ImageDownloader(
																					item?.image_url,
																					item?.image,
																				);
																			}}
																		>
																			Download{" "}
																		</button>
																		<button
																			onClick={(e) => HandleDelete(e, item)}
																		>
																			Remove
																		</button>
																	</div>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										) : (
											<h2
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													textAlign: "center",
													margin: "200px auto",
												}}
											>
												No Record Found
											</h2>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default UploadedWork;
