import React from "react";
import { Link } from "react-router-dom";
import {
	footerFb,
	footerIn,
	footerTw,
	footeryoutube,
	linkedin,
	logo,
} from "../constant";
import { useSelector } from "react-redux";
import { useHomePageQuery } from "../store/services/HomeServices";

const Footer = () => {
	const { data, isLoading } = useHomePageQuery();
	const Socialicons = data?.data?.socialicons;
	const responseData = data?.data?.footertext;
	const emailData = data?.data?.email?.value;
	const Copyrighticons = data?.data?.copyright?.value;
	const Footerlogoicons = data?.data?.footerlogo?.image_url;
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	const d = new Date();
	let year = d.getFullYear();
	return (
		<>
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="footer-widget-wrapper">
								<div className="footer-logo-wrapper">
									<a href="/">
										<img
											src={Footerlogoicons}
											className="img-fluid"
											alt="Footer Logo"
										/>
									</a>
								</div>
								<div className="footer-content-wrapper">
									<p
										dangerouslySetInnerHTML={{
											__html: responseData?.value,
										}}
									></p>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Quick Links</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<a href="/faqs">
												{" "}
												<i className="fa fa-angle-right"></i> <span>FAQs</span>
											</a>
										</li>
										<li>
											<a href="/blogs">
												<i className="fa fa-angle-right"></i> <span>Blog</span>
											</a>
										</li>
										<li>
											<Link to="/testimonial">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Testimonials</span>
											</Link>
										</li>
										<li>
											<Link to="/contact">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Contact Us</span>
											</Link>
										</li>
										<li>
											<Link to="/help">
												<i className="fa fa-angle-right"></i>{" "}
												<span>How Can We Help?</span>
											</Link>
										</li>
										{/* <li>
											<Link to="/sitemap">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Sitemap</span>
											</Link>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Legal</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<a href="/website-term">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Website Terms of Use</span>
											</a>
										</li>
										{/* <li>
											<a href="/terms">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Terms Of Service</span>
											</a>
										</li>
										<li>
											<a href="/Policy">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Privacy Policy</span>
											</a>
										</li> */}
										<li>
											<a href="/agreement">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Contributor Agreement</span>
											</a>
										</li>
										<li>
											<Link to="/LicenseAgreement">
												<i className="fa fa-angle-right"></i>{" "}
												<span>License Agreement</span>
											</Link>
										</li>
										<li>
											<a href="/copyright">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Copyright</span>
											</a>
										</li>
										<li>
											<a href="/release-form">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Release Forms</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							{" "}
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Learn More</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul>
										{contrubutorToken === "" && (
											<>
												<li>
													<a href="/pricing">
														<i className="fa fa-angle-right"></i>{" "}
														<span>Subscription Info</span>
													</a>
												</li>
												<li>
													<a href="/register-contributor">
														<i className="fa fa-angle-right"></i>{" "}
														<span>Sell Your Work</span>
													</a>
												</li>
											</>
										)}

										<li>
											<a href="/tips">
												<i className="fa fa-angle-right"></i>{" "}
												<span>Tips and Tricks For Creatives</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-2-widget-wrapper">
								<div className="footer-heading">
									<h5>Social Media</h5>
								</div>
								<div className="footer-links-wrapper">
									<ul className="social-icon">
										{/* Socialicons */}
										<li>
											<a
												href={Socialicons?.[2]?.link}
												className=""
												target="_blank"
											>
												<img src={footerFb} className="img-fluid" alt="Facebook" />
											</a>
										</li>
										<li>
											<a href={Socialicons?.[1]?.link} target="_blank">
												<img src={footerIn} className="img-fluid" alt="Instagram" />
											</a>
										</li>
										<li>
											<a href={Socialicons?.[0]?.link} target="_blank">
												<img src={linkedin} className="img-fluid" alt="Linkedin" />
											</a>
										</li>
										<li>
											<a href={Socialicons?.[3]?.link} target="_blank">
												<img src={footeryoutube} className="img-fluid" alt="Youtube" />
											</a>
										</li>
									</ul>
								</div>
								<div className="footer-heading">
									<h5>Contact Info</h5>
								</div>
								<div className="email-wrapper">
									<a href={`mailto:${emailData}`}>{emailData}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* COPYRIGHT starts here */}
			<div id="copyright">
				<div className="container">
					<div className="row">
						<div className="col-lg-9">
							<div className="copyright-text-wrapper text-center">
								<p>{Copyrighticons}</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-menu-wrapper">
								<ul>
									<li>
										<Link to="/Policy">PRIVACY POLICY</Link>
									</li>
									<li>
										<Link to="/terms">TERMS & CONDITIONS</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* COPYRIGHT ends here */}
		</>
	);
};

export default Footer;
