import React, { useState, useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/terms.css";
import "../../assets/css/artist-recourses.css";
import {
	aboutpageimg,
	angleimg,
	frame1,
	frame2,
	framevideoimg,
	recoursecheckimg,
	backArrow,
} from "../../constant";
import { useGeneralTipsQuery } from "../../store/services/ContactServices";
import ReactPlayer from "react-player";
import Loader from "../../components/Loader";
import Backhistory from "../../components/Backhistory";

const Tips = () => {
	const { data, isLoading } = useGeneralTipsQuery();
	let Tips = data?.data?.content;

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Faq starts here */}
			<section className="terms">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="terme-heading-wrapper">
									<h2>{Tips?.section_1_title}</h2>
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Faq ends here */}
			{/* recources Sec Start Here */}
			<section className="recourses-sec ">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="recourse-card-wrapper">
								<div className="recourse-content-wrapper">
									<h2>{Tips?.section_1_sub_title}</h2>
									<p
										dangerouslySetInnerHTML={{
											__html: Tips?.section_1_description,
										}}
									></p>
								</div>
								<div className="about-img-wrapper border-line-left">
									<figure>
										<img
											src={Tips?.section_one_image}
											className="img-fluid"
											alt="Tips Image"
										/>
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* recources Sec End Here */}
			{/* our mission starts here */}
			<section className="our-mission pt-5  mt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper pe-2">
								<h2>{Tips?.section_2_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Tips?.section_2_description,
									}}
								></p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img
										src={Tips?.section_two_image}
										className="img-fluid"
										alt="Tips Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* Look sec starts here */}
			<section className="look-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="look-content-wrapper">
								<h2>{Tips?.section_3_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Tips?.section_3_description,
									}}
								></p>
							</div>
							<div className="look-content-wrapper">
								<h2>{Tips?.section_4_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Tips?.section_4_description,
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Look sec ends here */}
			{/* our mission starts here */}
			<section className="our-mission pt-lg-5  mt-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left pe-lg-5">
								<figure>
									<img
										src={Tips?.section_four_image}
										className="img-fluid"
										alt="Tips Image"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left pe-lg-5">
								<figure>
									<img
										src={Tips?.section_three_image}
										className="img-fluid"
										alt="Tips Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* Look sec starts here */}
			<section className="look-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="look-content-wrapper">
								<h2>{Tips?.section_5_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Tips?.section_5_description,
									}}
								></p>
							</div>
							<div className="look-content-wrapper">
								<h2>{Tips?.section_6_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Tips?.section_6_description,
									}}
								></p>
							</div>
							<div className="look-content-wrapper">
								<h2>{Tips?.section_7_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Tips?.section_7_description,
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Look sec ends here */}
			{/* recources Sec Start Here */}
			<section className="recourses-sec pt-0 pb-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="recourse-card-wrapper">
								<div className="about-img-wrapper border-line-left">
									<div className="figure">
										<ReactPlayer
											url={Tips?.section_seven_video}
											controls
											width={window.innerWidth < 540 && "100%"}
											height={window.innerWidth < 540 && "100%"}
											config={{
												file: {
													attributes: {
														controlsList:
															"nodownload nofullscreen noremoteplayback",
														disablePictureInPicture: "true",
													},
												},
											}}
											muted
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* recources Sec End Here */}
			<Footer />
		</>
	);
};

export default Tips;
