import React, { useState, useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-release-form.css";
import ArtistLicensingAgreements from "../ArtistCorner/ArtistLicensingAgreements";

const LicenseAgreement = () => {
	const release_form = [
		{
			id: 1,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 2,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 3,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 4,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
		{
			id: 5,
			title: "Etiam ultricies m eget dui. Etiamrient montes,se Form.png",
		},
	];
	return (
		<>
			<Header />

			{/* release Sec Start Here */}
			<ArtistLicensingAgreements setShow={true} />
			{/* release Sec End Here */}
			<Footer />
		</>
	);
};

export default LicenseAgreement;
