import React from "react";
// import { IoEyeSharp } from "react-icons/Io";
import { BsFillEyeSlashFill,BsFillEyeFill } from "react-icons/bs";

function EyesIcon({ showss, setShow }) {
	const HandleChange = () => {
		setShow(false);
	};
	const HandleChanges = () => {
		setShow(true);
	};
	return (
		<>
			{showss ? (
				<BsFillEyeFill onClick={HandleChange} />
			) : (
				<BsFillEyeSlashFill onClick={HandleChanges} />
			)}
		</>
	);
}

export default EyesIcon;
