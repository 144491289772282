import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useRef } from "react";
import StripeForm from "../../components/StripeForm";
import {
	useCreateSubscriptionApiMutation,
	useUpdatedSubscriptionApiMutation,
} from "../../store/services/ContactServices";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import UpgradeStripeFrom from "../../components/UpgradeStripeFrom";
function PricingList({
	radioShow = "no",
	description,
	firstPrice,
	secondPrice,
	thirdPrice,
	link,
	firstName,
	SecondName,
	thirdName,
	firstSlug,
	secondSlug,
	thirdSlug,
	index = 0,
}) {
	const { type } = useParams();

	const { userToken } = useSelector((state) => state.AuthReducer);
	const [show, setShow] = useState(false);
	const [types, setTypes] = useState("image");
	const [Newslet, setNews] = useState(true);
	const [subscriptionData, response] = useCreateSubscriptionApiMutation();
	const [upgradesubscriptionData, responseDatas] =
		useUpdatedSubscriptionApiMutation();
	const [slugData, setSlugData] = useState(firstSlug);
	const handleClose = () => setShow(false);
	const [stripeData, setStripe] = useState(false);
	const handleShow = () => {
		if (userToken === "") {
			swal("Error!", "Please Login First", "error");
		} else {
			setShow(true);
		}
	};
	const inputElement = useRef();

	useEffect(() => {
		if (response?.isSuccess) {
			if (response?.data?.message !== "You don't have any subscription") {
				window.location.href = response?.data?.data;
			} else {
				swal("Error!", response?.data?.message, "error");
			}
		}
	}, [response?.isSuccess]);

	useEffect(() => {
		if (responseDatas?.isSuccess) {
			if (responseDatas?.data?.message !== "You don't have any subscription") {
				window.location.href = responseDatas?.data?.data;
			} else {
				swal("Error!", responseDatas?.data?.message, "error");
			}
		}
	}, [responseDatas?.isSuccess]);

	useEffect(() => {
		if (response?.error?.data?.statusCode === 400) {
			swal("Error!", response?.error?.data?.message, "error");
		}
	}, [response?.isError]);

	useEffect(() => {
		if (responseDatas?.error?.data?.statusCode === 400) {
			swal("Error!", responseDatas?.error?.data?.message, "error");
		}
	}, [responseDatas?.isError]);

	// else {
	// 	swal("Error!", response?.data?.message, "error");
	// }

	const focusInput = () => {
		inputElement.current.focus();
	};
	const Closemodal = () => {
		setShow(false);
	};

	const PlaceOrderData = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("slug", slugData);
		FormDatas.append("payment_type", "paypal");
		FormDatas.append("type", types);
		FormDatas.append("newsletter", Newslet ? "yes" : "no");
		type === "upgrade"
			? upgradesubscriptionData(FormDatas)
			: subscriptionData(FormDatas);
	};

	return (
		<>
			<Modal show={show} onHide={handleClose} className="login-modal">
				{/* <Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					<div className="login-card-wrapper">
						<button className="modal-close" onClick={Closemodal}>
							X
						</button>
						<div className="login-heading-wrapper">
							<h2>Subscription </h2>
							<p>Enter card details for purchasing subscription</p>
							<h6
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							></h6>
							<select
								name=""
								id=""
								className="form-control hover_input"
								value={slugData}
								onChange={(e) => setSlugData(e.target.value)}
							>
								<option
									value={firstSlug}
								>{`${firstName} (price: $${firstPrice})`}</option>
								<option
									value={secondSlug}
								>{`${SecondName} (price: $${secondPrice})`}</option>
								<option
									value={thirdSlug}
								>{`${thirdName} (price: $${thirdPrice})`}</option>
							</select>
							{/* {
								Currentsubscription?.get_subscription_usage?.total_videos_limit >
								0 &&
							} */}
							{radioShow === "yes" && (
								<div className="row pb-3">
									<div className="col-lg-12">
										<div className="radio_main">
											<div className="radio_btn d-flex align-items-center gap-2">
												<input
													type="radio"
													id="javascript"
													name="fav_language"
													defaultChecked
													value={types}
													onChange={() => setTypes("image")}
												/>
												<label className="radio_label">Image</label>
											</div>
											<div className="radio_btn d-flex align-items-center gap-2">
												<input
													type="radio"
													id="javascript"
													name="fav_language"
													value={types}
													onChange={() => setTypes("video")}
												/>
												<label className="radio_label">Video</label>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="row pb-3">
								<div className="col-lg-12">
									<div className="radio_btn text-start d-flex align-items-center">
										<input
											type="checkbox"
											id="javascript"
											name="fav_language"
											defaultChecked
											value={Newslet}
											onChange={() => setNews(!Newslet)}
										/>
										<label className="radio_label check_radius">
											Subscribe To Our News Letter
										</label>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="payment_method">
									<div className="icon ButtonPreview">
										<button onClick={PlaceOrderData} className="Paypal_btn">
											{response?.isLoading ? "Load" : "Pay"}
											<span className="paypal_pal">
												{response?.isLoading ? "ing..." : "Pal"}
											</span>
										</button>
									</div>
									<div className="icon ButtonPreviewsd">
										<button
											onClick={() => setStripe(!stripeData)}
											className="stripe_btn"
										>
											Stripe
										</button>
									</div>
									{stripeData === true &&
										(type === "upgrade" || type !== undefined ? (
											<UpgradeStripeFrom
												slug={slugData}
												type={types}
												newsletter={Newslet}
											/>
										) : (
											<StripeForm
												slug={slugData}
												type={types}
												newsletter={Newslet}
											/>
										))}
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div className="col-lg-3 col-3 mt-3 px-2 ps-0">
				<div className="pricing-box blue">
					<div className="data-heading">
						{index === 2 && <h6 className="riban-best mb-0">BEST VALUE</h6>}
						<h6
							dangerouslySetInnerHTML={{
								__html: description,
							}}
						></h6>
					</div>
					<div className="pricing-list">
						<div className="single-price">
							<span>${firstPrice}</span>
						</div>
						<div className="single-price">
							<span>${secondPrice}</span>
						</div>
						<div className="single-price">
							<span>${thirdPrice}</span>
						</div>
					</div>
					<div className="buy-now mt-4">
						<button onClick={handleShow} className="btn">
							Buy Now
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default PricingList;
