import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-news.css";
import { BlogImg1 } from "../../constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { useArtistnewsContentQuery } from "../../store/services/ArtistCornerServices/ArtistNewsService";
import moment from "moment";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";

const ArtistNews = () => {
	const [currentpage, setCurrentpage] = useState(1);
	const { data, isLoading } = useArtistnewsContentQuery(currentpage);
	const News = data?.data?.news;
	const pages = Math.ceil(data?.data?.total_size / data?.data?.limit);
	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}
			{/* Blog Sec Start Here */}
			<section className="blog_sec artist-news-sec">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>News</h1>
					</div>
					<div className="row">
						{News?.map((item, index) => {
							return (
									<div key={index} className="col-lg-4">
										<div className="blog_box">
											<div className="blog-img">
												<figure>
													<Link to={`/artist-news-detail?slug=${item?.slug}`}>
														<img
															src={item?.image_url}
															alt={item?.title}
															className="img-fluid"
														/>
													</Link>
												</figure>
												<div className="date">
													<h4>{moment(item?.created_at).format("Do MMM")}</h4>
												</div>
											</div>
											<div className="blog-detail">
												<div className="blog-description">
													<h2>{item?.title?.length > 35 ? `${item?.title.substring(0, 35)}...` : item?.title}</h2>
													<p
														className="artist_description"
														dangerouslySetInnerHTML={{
															__html: item?.description,
														}}
													></p>
													<a href={`/artist-news-detail?slug=${item?.slug}`}>
														Learn More...
													</a>
												</div>
											</div>
										</div>
									</div>
							);
						})}
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="paginations-wrapper">
								{/* <ul>
									<li className="pagination-item">
										<button className="prev pagination-link">
											<i className="fa fa-arrow-left"></i>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>01.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link active pagination-link">
											{" "}
											<span>02.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>03.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>04.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="next pagination-link">
											<i className="fa fa-arrow-right"></i>
										</button>
									</li>
								</ul> */}
								<Pagination pages={pages} handlePageClick={handlePageClick} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistNews;
