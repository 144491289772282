import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/checkout.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	useAddaddressApiMutation,
	useCurrentSubscriptionApiQuery,
	useGetAddressApiQuery,
	usePlaceOrderDataApiMutation,
} from "../../store/services/ContactServices";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import { useEffect } from "react";
import PlaceOrderstripe from "../../components/placeOrderstripe";
import currency from "currency-formatter";
import moment from "moment/moment";
import Loader from "../../components/Loader";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Checkout = () => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const schema = yup.object().shape({
		email: yup.string().email().required("Email is Required"),
		first_name: yup
			.string()
			.min(2, "First name must be at least 2 characters long")
			.max(15, "First name cannot be greater than 15 characters long")
			.required("First Name is Required"),
		last_name: yup
			.string()
			.min(2, "Last name must be at least 2 characters long")
			.max(15, "Last name cannot be greater than 15 characters long")
			.required("Last Name is Required"),
		address: yup
			.string()
			.min(3, "Address must be at least 3 characters long")
			.max(70, "Address cannot be greater than 70 characters long")
			.required("Address is Required"),
		address_type: yup
			.string()
			.min(3, "Apartment must be at least 3 characters long")
			.max(15, "Apartment cannot be greater than 15 characters long")
			.required("Apartment is Required"),
		city: yup
			.string()
			.min(3, "City must be at least 3 characters long")
			.max(15, "City cannot be greater than 15 characters long")
			.required("City is Required"),
		country: yup
			.string()
			.min(3, "Country must be at least 3 characters long")
			.max(15, "Country cannot be greater than 15 characters long")
			.required("Country is Required"),
		state: yup
			.string()
			.min(3, "State must be at least 3 characters long")
			.max(15, "State cannot be greater than 15 characters long")
			.required("State is Required"),
		zip_code: yup
			.string()
			.min(5, "Zip Code must be at least 5 characters long")
			.max(5, "Zip Code cannot be greater than 5 characters long")
			.required("Zip Code is Required"),
		phone_no: yup
			.string()
			.required("Phone No is Required")
			.matches(phoneRegExp, "Phone number is not valid"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const { cart, total } = useSelector((item) => item?.cartReducers);
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	const navigate = useNavigate();
	const [addaddress, responseData] = useAddaddressApiMutation();
	const [placeDatas, response] = usePlaceOrderDataApiMutation();
	const { data: currentSubcribe } = useCurrentSubscriptionApiQuery();
	const { data, isLoading } = useGetAddressApiQuery();
	const Currentsubscription = currentSubcribe?.data?.currentsubscription;
	const Save_address = data?.data?.address;
	const [setCheckVal, setCheckvalue] = useState(false);
	const [stripeData, setStripe] = useState(false);
	const inputElement = useRef();
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const [checkedTerm, setCheckedTerm] = useState(false);
	const handleChangecheckterm = () => {
		setCheckedTerm(!checkedTerm);
	};

	useEffect(() => {
		if (Save_address?.length === 0) {
			setCheckvalue(true);
		}
	}, [Save_address?.length === 0]);

	useEffect(() => {
		if (response?.isSuccess && response?.data?.data?.paypalurl) {
			window.location.href = response?.data?.data?.paypalurl;
		}
		if (response?.isSuccess && response?.data?.data?.thankyouurl) {
			window.location.href = response?.data?.data?.thankyouurl;
		}
	}, [response?.isSuccess]);

	useEffect(() => {
		if (response?.error?.data?.statusCode === 400) {
			setShow(false);
			swal("Error!", response?.error?.data?.errors?.[0], "error");
		}
		if (response?.error?.data?.statusCode === 500) {
			setShow(false);
			swal("Error!", response?.error?.data?.errors, "error");
		}
	}, [response?.isError]);

	let CatDatas = cart?.map((item) => {
		return {
			id: item?.id,
			qty: 1,
			type: item?.asset_type,
		};
	});

	const paymentHandler = (e) => {
		e.preventDefault();
		if (checkedTerm === false) {
			swal("Error!", "Please Check Terms & Condition", "error");
		} else {
			const FormDatas = new FormData();
			FormDatas.append("cart", JSON.stringify(CatDatas));
			FormDatas.append("total_amount", total);
			FormDatas.append("payment_method", "paypal");
			FormDatas.append("first_name", Save_address?.[0]?.first_name);
			FormDatas.append("last_name", Save_address?.[0]?.last_name);
			FormDatas.append("phone_no", Save_address?.[0]?.phone);
			FormDatas.append("email", Save_address?.[0]?.email);
			FormDatas.append("address", Save_address?.[0]?.address);
			FormDatas.append("address_type", Save_address?.[0]?.address_type);
			FormDatas.append("city", Save_address?.[0]?.city);
			FormDatas.append("country", Save_address?.[0]?.country);
			FormDatas.append("state", Save_address?.[0]?.state);
			FormDatas.append("zip_code", Save_address?.[0]?.zip);
			placeDatas(FormDatas);
		}
	};
	const paymentHandler2 = (e) => {
		e.preventDefault();
		if (checkedTerm === false) {
			swal("Error!", "Please Check Terms & Condition", "error");
		} else {
			const FormDatas = new FormData();
			FormDatas.append("cart", JSON.stringify(CatDatas));
			FormDatas.append("total_amount", total);
			FormDatas.append("payment_method", "subscription");
			FormDatas.append("first_name", Save_address?.[0]?.first_name);
			FormDatas.append("last_name", Save_address?.[0]?.last_name);
			FormDatas.append("phone_no", Save_address?.[0]?.phone);
			FormDatas.append("email", Save_address?.[0]?.email);
			FormDatas.append("address", Save_address?.[0]?.address);
			FormDatas.append("address_type", Save_address?.[0]?.address_type);
			FormDatas.append("city", Save_address?.[0]?.city);
			FormDatas.append("country", Save_address?.[0]?.country);
			FormDatas.append("state", Save_address?.[0]?.state);
			FormDatas.append("zip_code", Save_address?.[0]?.zip);
			placeDatas(FormDatas);
		}
	};
	const handleClose = () => setShow(false);
	// const PlaceOrderData = (e) => {
	// 		e.preventDefault();
	// 		const FormDatas = new FormData();
	// 		FormDatas.append("slug", slugData);
	// 		FormDatas.append("payment_type", "paypal");
	// 		subscriptionData(FormDatas);
	// 	};
	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("first_name", data?.first_name);
		formData.append("last_name", data?.last_name);
		formData.append("address", data?.address);
		formData.append("address_type", data?.address_type);
		formData.append("city", data?.city);
		formData.append("country", data?.country);
		formData.append("state", data?.state);
		formData.append("zip_code", data?.zip_code);
		formData.append("email", data?.email);
		formData.append("phone_no", data?.phone_no);
		addaddress(formData);
	};

	const Closemodal = () => {
		setShow(false);
	};

	useEffect(() => {
		if (contrubutorToken !== "") {
			navigate("/");
		}
	}, []);

	useEffect(() => {
		if (responseData?.isSuccess) {
			swal("Success!", responseData?.data?.message, "success");
			reset();
			setCheckvalue(false);
		}
	}, [responseData.isSuccess]);

	useEffect(() => {
		if (responseData?.isError && responseData?.error?.data?.errors) {
			setError("first_name", {
				type: "manual",
				message: responseData?.error?.data?.errors?.first_name,
			});
			setError("last_name", {
				type: "manual",
				message: responseData?.error?.data?.errors?.last_name,
			});
			setError("address", {
				type: "manual",
				message: responseData?.error?.data?.errors?.address,
			});
			setError("address_type", {
				type: "manual",
				message: responseData?.error?.data?.errors?.address_type,
			});
			setError("city", {
				type: "manual",
				message: responseData?.error?.data?.errors?.city,
			});
			setError("country", {
				type: "manual",
				message: responseData?.error?.data?.errors?.country,
			});
			setError("state", {
				type: "manual",
				message: responseData?.error?.data?.errors?.state,
			});
			setError("zip_code", {
				type: "manual",
				message: responseData?.error?.data?.errors?.zip_code,
			});
			setError("email", {
				type: "manual",
				message: responseData?.error?.data?.errors?.email,
			});
			setError("phone_no", {
				type: "manual",
				message: responseData?.error?.data?.errors?.phone_no,
			});
		}
	}, [responseData?.isError]);

	useEffect(() => {
		if (Save_address?.length > 0) {
			setValue(
				"first_name",
				Save_address?.[0]?.contact_person_name?.split(" ")?.[0],
			);
			setValue(
				"last_name",
				Save_address?.[0]?.contact_person_name?.split(" ")?.[1],
			);
			setValue("address", Save_address?.[0]?.address);
			setValue("address_type", Save_address?.[0]?.address_type);
			setValue("city", Save_address?.[0]?.city);
			setValue("country", Save_address?.[0]?.country);
			setValue("state", Save_address?.[0]?.state);
			setValue("zip_code", Save_address?.[0]?.zip);
			setValue("email", Save_address?.[0]?.email);
			setValue("phone_no", Save_address?.[0]?.phone);
		}
	}, [Save_address]);

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				className="login-modal checkout_modal"
			>
				<Modal.Body>
					<div className="login-card-wrapper">
						<button className="modal-close" onClick={Closemodal}>
							X
						</button>
						<div className="login-heading-wrapper">
							<h2>Subscription </h2>
						</div>
						<div className="form-group row">
							<div className="col-lg-6 mb-3">
								<div className="sub-pack-wrapper">
									<h6>BASIC</h6>
									<h3>
										{currency.format(Currentsubscription?.get_package?.price, {
											code: "USD",
										})}
									</h3>
									<h5>Standard License</h5>
								</div>
							</div>
							<div className="col-lg-6 mb-3">
								<div className="sub-pack-wrapper">
									<h6>PLAN EXPIRES</h6>
									<h3>
										{moment(Currentsubscription?.ends_at).format("Do MMM YYYY")}
									</h3>
									{/* <h5>At 11:35 am GMT +5</h5> */}
								</div>
							</div>
							<div className="col-lg-6 mb-3">
								<div className="sub-pack-wrapper">
									<h6>IMAGE DOWNLOADS REMAINING</h6>
									<h3>
										{Currentsubscription?.get_subscription_usage !== null
											? Currentsubscription?.get_subscription_usage
													?.images_limit
											: 0}
										/
										{Currentsubscription?.get_subscription_usage
											?.total_image_limit !== null
											? Currentsubscription?.get_subscription_usage
													?.total_image_limit
											: 0}
									</h3>
									<h5>
										Ends{" "}
										{moment(Currentsubscription?.ends_at).format("Do MMM YYYY")}
									</h5>
								</div>
							</div>

							<div className="col-lg-6 mb-3">
								<div className="sub-pack-wrapper">
									<h6>VIDEO DOWNLOADS REMAINING</h6>
									<h3>
										{Currentsubscription?.get_subscription_usage
											?.total_videos_limit !== null
											? Currentsubscription?.get_subscription_usage
													?.total_videos_limit
											: 0}
										/
										{Currentsubscription?.get_subscription_usage !== null
											? Currentsubscription?.get_subscription_usage
													?.videos_limit
											: 0}
									</h3>
									<h5>
										Ends
										{moment(Currentsubscription?.ends_at).format("Do MMM YYYY")}
									</h5>
								</div>
							</div>
							<div className="col-lg-12 mb-3 text-end">
								<button className="btn me-2" onClick={Closemodal}>
									Cancel
								</button>
								<button className="btn" onClick={paymentHandler2}>
									{response?.isLoading ? "Loading..." : "Confirm"}
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Checkout Sec Start Here */}
			<section className="checkout_sec">
				<div className="container">
					<div className="back_gif_main d-flex justify-content-between align-items-center">
						<div className="testimonials-wrapper">
							<h1>Checkout</h1>
						</div>
						<Backhistory classNa={"back_icon text-end"} />
					</div>
					<div className="checkout_wrapper">
						<div className="row">
							<div className="col-lg-7">
								<div className="checkout-form">
									<div className="topbar">
										<h4>Checkout details</h4>
										{/* <p>
											Already Have an account? <a href="/login">Log in</a>
										</p> */}
									</div>
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="addresses w-100">
											<div className="row">
												{Save_address?.map((items, index) => {
													return (
														<div className="col-md-12" key={index}>
															<label
																htmlFor={`addressRadio${index}`}
																className="address-container mb-2 w-100"
															>
																<input
																	type="radio"
																	name="addressRadio"
																	className="form-check"
																	hidden
																	checked
																	id={`addressRadio${index}`}
																/>
																<span className="span-checkmark">
																	<div className="address-box dark-background position-relative py-3 px-3">
																		<span className="dot"></span>
																		<h3 className="address-title heading-font light-text font-22">
																			{items?.address_type}
																		</h3>
																		<p className="fst-italic general-font light-text font-15">
																			{items?.address}
																		</p>
																		<h5 className="fst-italic font-15 general-font light-text">
																			{items?.city},{items?.country}
																		</h5>
																	</div>
																</span>
															</label>
														</div>
													);
												})}
											</div>
										</div>
										<div className="bottom-bar bottom-bargs">
											<div className="form-group">
												<input
													type="checkbox"
													id="privacyCheckboxs"
													value={setCheckVal}
													defaultChecked={setCheckVal === true}
													onChange={() => setCheckvalue(!setCheckVal)}
													className="form-check-input"
												/>
												<label htmlFor="privacyCheckboxs">
													{Save_address?.length > 0
														? "Edit Shipping Address."
														: "Add New Shipping Address."}
												</label>
											</div>
										</div>
										{setCheckVal === true && (
											<div className="row mt-3">
												<div className="col-md-6">
													<div className="form-group">
														<label>First Name</label>
														<FormInput
															id="first_name"
															name="first_name"
															type="text"
															placeholder="Enter your FirstName"
															register={register}
															error={errors.first_name}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Last Name</label>
														<FormInput
															id="last_name"
															name="last_name"
															type="text"
															placeholder="Enter your LastName"
															register={register}
															error={errors.last_name}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Phone</label>
														<FormInput
															id="phone_no"
															name="phone_no"
															type="tel"
															placeholder="Enter your Phone Number"
															register={register}
															error={errors.phone_no}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Email</label>
														<FormInput
															id="email"
															name="email"
															type="email"
															placeholder="Enter your Email"
															register={register}
															error={errors.email}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Address</label>
														<FormInput
															id="address"
															name="address"
															type="text"
															placeholder="Enter your Address"
															register={register}
															error={errors.address}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Suite, Apartment, etc </label>
														<FormInput
															id="address_type"
															name="address_type"
															type="text"
															placeholder="Enter your Suite, Apartment, etc"
															register={register}
															error={errors.address_type}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>City</label>
														<FormInput
															id="city"
															name="city"
															type="text"
															placeholder="Enter your City"
															register={register}
															error={errors.city}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label>Country/Region</label>
														<FormInput
															id="country"
															name="country"
															type="text"
															placeholder="Enter your Country/Region"
															register={register}
															error={errors.country}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label>State</label>
														<FormInput
															id="state"
															name="state"
															type="text"
															placeholder="Enter your State"
															register={register}
															error={errors.state}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label>Zip Code</label>
														<FormInput
															id="zip_code"
															name="zip_code"
															type="number"
															placeholder="Enter your Zip Code"
															register={register}
															error={errors.zip_code}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<div className="btn-wrapper text-end">
															<Button
																type="submit"
																className="btn"
																variant="primary"
																// onClick={handleShow}
															>
																{responseData?.isLoading
																	? "Loading..."
																	: "Add Address"}
															</Button>
														</div>
													</div>
												</div>
											</div>
										)}
									</form>
									<div className="col-lg-12">
										<div className="payment_method">
											<div className="heading">
												<h3>Payment Details</h3>
											</div>
											<div className="icon ButtonPreview">
												<button onClick={paymentHandler} className="Paypal_btn">
													{response?.isLoading ? "Load" : "Pay"}
													<span className="paypal_pal">
														{response?.isLoading ? "ing..." : "Pal"}
													</span>
												</button>
											</div>
											<div className="icon ButtonPreviewsd">
												<button
													onClick={() => setStripe(!stripeData)}
													className="stripe_btn"
												>
													Stripe
												</button>
											</div>
											{stripeData === true && (
												<PlaceOrderstripe checkedTerm={checkedTerm} />
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="detail-box">
									<div className="heading">
										<h4>PRODUCT DETAILS</h4>
									</div>
									{/* <video
																controls
																className="img-fluid"
																controlsList="nodownload nofullscreen noremoteplayback"
																disablePictureInPicture={true}
															>
																<source
																	src={item?.video_url}
																	type="video/mp4"
																/>
															</video> */}
									<div className="product-list">
										{cart?.map((item, index) => {
											return (
												<div className="product-box" key={index}>
													<div className="img-box">
														{item?.item_type === "video" ? (
															<figure className="mb-0">
																<img
																	src={item?.thumbnail_url}
																	alt="Product Video"
																	className="img-fluid"
																/>
															</figure>
														) : (
															<figure className="mb-0">
																<img
																	src={item?.watermark_image_url}
																	alt="Product Image"
																	className="img-fluid"
																/>
															</figure>
														)}
													</div>
													<div className="txt">
														<h4
															className="name"
															style={{ textTransform: "capitalize" }}
														>
															{item?.name}
														</h4>
														<h2 style={{ textTransform: "capitalize" }}>
															${item?.price} {item?.package?.name}
														</h2>
														<p>{item?.file_resolution}</p>
													</div>
												</div>
											);
										})}
									</div>
									<div className="item-total">
										<h5>
											<span className="propety">Item Total :</span>
											<span className="value">${total}</span>
										</h5>
									</div>
									<div className="order-total">
										<h5>
											<span className="property">Order Total</span>
											<span className="value">${total}</span>
										</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="bottom-bar">
							<div className="form-group d-flex">
								<input
									type="checkbox"
									id="privacyCheckbox"
									className="form-check-input"
									onChange={handleChangecheckterm}
								/>
								<label htmlFor="privacyCheckbox" style={{ fontSize: "15px" }}>
									You authorize the payment for your order, and agree to the
									charges associated with your purchase.
								</label>
							</div>
							<div className="button-group">
								<Link to="/cart" className="cart-btn">
									<i className="fa fa-angle-left" aria-hidden="true"></i>
									My Cart
								</Link>

								{/* <Link className="btn" to="/thank-you">
									Checkout
								</Link> */}
								<button
									className="btn"
									onClick={handleShow}
									disabled={Currentsubscription === undefined ? true : false}
								>
									{response?.isLoading ? "Loading..." : "Pay with subscription"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Checkout Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Checkout;
