import React, { useState, useEffect } from "react";
import PublicRoutes from "./routes/PublicRoutes";
import { LogoutAllTabs, LogoutAllTabs2 } from "./components/AuthSetup";

function App() {
	useEffect(() => {
		window.scrollTo(0, 0);
		LogoutAllTabs();
		LogoutAllTabs2();
	}, []);
	// useEffect(() => {
	// 	return () => {
	// 		console.clear();
	// 	};
	// }, []);
	// useEffect(() => {
	// 	document.addEventListener("contextmenu", function (e) {
	// 		e.preventDefault();
	// 	});
	// 	document.querySelector("*").oncontextmenu = () => false;
	// 	document.onkeydown = function (e) {
	// 		if (e.keyCode === 123) {
	// 			return false;
	// 		}
	// 		if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
	// 			return false;
	// 		}
	// 		if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
	// 			return false;
	// 		}
	// 		if (e.ctrlKey && e.shiftKey && e.keyCode == "3".charCodeAt(0)) {
	// 			return false;
	// 		}
	// 		if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
	// 			return false;
	// 		}
	// 	};
	// });

	return <PublicRoutes />;
}

export default App;
