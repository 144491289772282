import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNewsLetterMutation } from "../store/services/ContactServices";
import { FormInput } from "./InputField/input";

const Newsletter = () => {
	const [Newsdata, response] = useNewsLetterMutation();
	const schema = yup.object().shape({
		email: yup.string().email().required("Email is Required"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [subError, subErrors] = useState("");
	const values = getValues();
	useEffect(() => {
		if (response?.isSuccess) {
			swal("Success!", response?.data?.message, "success");
			reset();
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						setError(key, {
							type: "manual",
							message: response?.error?.data?.errors[key][0],
						});
					}
				}
			}
		}
	}, [response?.isError]);

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("email", data?.email);
		Newsdata(formData);
	};
	return (
		<>
			{/* NewsLetter starts here */}
			<section className="newsletter">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4">
							<div className="newsletter-content-wrapper">
								<h5>Sign Up For Newsletters</h5>
								<p>
									Get E-mail updates about our latest shop and Special Offers
								</p>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-7">
							<div className="form-newsletter-wrapper">
								<form onSubmit={handleSubmit(onSubmitHandler)}>
									<div className="input-group">
										<FormInput
											id="email"
											name="email"
											type="text"
											placeholder="Your Email Address"
											register={register}
											error=""
										/>
										<span className="input-group-btn">
											<button className="btn" type="submit">
												{response?.isLoading ? "Loading..." : "Submit"}{" "}
											</button>
										</span>
									</div>
									<p className="errormessage">
										{errors?.email && <div>{errors?.email?.message}</div>}
									</p>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* NewsLetter ends here */}
		</>
	);
};

export default Newsletter;
