import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";
import "../../assets/css/resgister-page.css";
import GoTop from "../../components/GoTop";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const RegisterPage = () => {
	return (
		<>
			<GoTop />
			<Header />
			{/* Login sec starts here */}
			<section className="login-sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="row pt-3">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Register Your Account </h2>
								</div>
								<div className="login-form-wrapper mt-4">
									{/* <form action=""> */}
									<div className="form-group">
										<a href="/Register-buyer" className="btn form-control res">
											Register As A Buyer
										</a>
									</div>
									<div className="form-group">
										<a
											href="/register-contributor"
											className="btn form-control res"
										>
											Register As A Contributor
										</a>
									</div>
									{/* </form> */}
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default RegisterPage;
