import React, { useState, useMemo } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-profile.css";
import { TwitterPicker } from "react-color";
import { profileAvatar } from "../../constant";
import Masnory from "../../components/Masnory";
import { useAutherproductsQuery } from "../../store/services/ContactServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import ImageFilter from "../../components/ImageFilter";
import { useSelector } from "react-redux";
import ImagesMasnory from "../../components/ImagesMasnory";
import Loader from "../../components/Loader";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ArtistProfile = () => {
	const location = useLocation();
	const type = location?.state;
	// console.log(type, "typeee");
	console.log(type);
	let query = useQuery();
	let id = query.get("author_slug");
	const navigate = useNavigate();
	const { contrubutorToken, autherLink, productstags } = useSelector(
		(state) => state.AuthReducer,
	);
	const { tagsName, filterId, cateId, tyId, imageType, colors } = useSelector(
		(state) => state.ImageFilterReducer,
	);

	const Tagsids = tagsName?.map((item) => item?.id);

	useEffect(() => {
		if (id === null || id === "" || contrubutorToken !== "") {
			return navigate("/");
		}
	}, [id]);

	let datads = {
		type: imageType,
		autherName: id,
		tag_ids: Tagsids,
		package_type: tyId,
		trend_ids: filterId,
		category_id: cateId,
		color_code: colors,
	};

	const { data, isLoading, refetch } = useAutherproductsQuery(datads);
	// const offset = Math.ceil(data?.limit * currentpage);
	const Auther_details = data?.author;
	const Auther_products = data?.products;
	const [filter, setFilter] = useState(false);
	const [background, setBackground] = useState("");
	const [hexa, setHexa] = useState(background);

	const [picker, setPicker] = useState(false);
	const toggleChecked = () => setPicker((value) => !value);

	const saveColors = (color) => {
		setBackground(color.hex);
		setHexa(color.hex);
	};

	const saveColors2 = (e) => {
		setHexa(e.target.value);
		setBackground(e.target.value);
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Artist Profile Sec Start Here */}
			<section className="artist_profile">
				{Auther_products?.length > 0 ? (
					<div className="container">
						<div className="top-header">
							<div className="img-box">
								<figure>
									<img
										src={Auther_details?.image_url}
										alt="User Image"
										className="img-fluid"
									/>
								</figure>
							</div>
							<div className="content_wrapper">
								<h2>
									<span className="property">Artist :</span>{" "}
									<span className="value">{Auther_details?.f_name}</span>
								</h2>
								<h4 className="uploads">
									<span className="property">Total number of uploads :</span>
									<span className="value">
										{Auther_products?.length > 10
											? Auther_products?.length
											: `0${Auther_products?.length}`}
									</span>
								</h4>
							</div>
						</div>

						<div className="filter-bar">
							<div className="showing-result"></div>

							<ImageFilter
								column="col-lg-12"
								setShow={"Video"}
								notshowRegister={false}
								allNotShow={false}
								refetch={refetch}
								setTpye={true}
								type={type}
							/>
						</div>
						{Auther_products?.[0]?.asset_type === "Video" ? (
							<Masnory product={Auther_products} />
						) : (
							<ImagesMasnory
								product={Auther_products}
								reloadData={refetch}
								userData={data?.users}
							/>
						)}
						{/* Masnory Start Here */}

						{/* Masnory End Here */}
					</div>
				) : (
					<h2
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						Product Not Found
					</h2>
				)}
			</section>
			{/* Artist Profile Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ArtistProfile;
