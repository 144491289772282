import React, { useRef, useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import { useAuthForgetMutation } from "../../store/services/AuthServices";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const ForgetPasswordEmail = () => {
	const [Forgetdata, response] = useAuthForgetMutation();
	const [showss, setShow] = useState(false);
	const schema = yup.object().shape({
		email: yup.string().email().required("Email is Required"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [subError, subErrors] = useState("");
	const values = getValues();
	useEffect(() => {
		if (response?.isSuccess) {
			if (response?.data?.statusCode === 204) {
				swal("Error!", response?.data?.message, "error");
			} else {
				swal("Success!", response?.data?.message, "success");
				sessionStorage.setItem("email", values?.email);
				// navigate("/new-password");
				reset();
			}
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors) {
			setError("email", {
				type: "manual",
				message: response?.error?.data?.message,
			});
		}
		if (response?.error?.data?.statusCode === 401) {
			subErrors(response?.error?.data?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
	}, [response?.isError]);

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("email", data?.email);
		Forgetdata(formData);
	};
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<section className="login-sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="row pt-3">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Forgot Password</h2>
									<p className="mb-0">Please Enter Your Email Below</p>
									<p>We will send you a recovery Link</p>
								</div>
								<div className="login-form-wrapper">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group">
											<label htmlFor="">
												Enter Email <span>*</span>
											</label>
											<FormInput
												id="email"
												name="email"
												type="text"
												placeholder="Email Address"
												register={register}
												error={errors.email}
											/>
										</div>
										<div className="form-group">
											<button className="btn form-control" type="submit">
												{response?.isLoading ? "Loading..." : "Submit"}{" "}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default ForgetPasswordEmail;
