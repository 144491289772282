import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/myaccount.css";
import { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import {
	useUsereditInfoMutation,
	useUsermeInfoQuery,
} from "../../store/services/AuthServices";
import ChangePassword from "./ChangePassword";
import Loader from "../../components/Loader";

const MyAccout = () => {
	const [EditData, response] = useUsereditInfoMutation();
	const [subError, subErrors] = useState("");
	const schema = yup.object().shape({
		full_name: yup
			.string()
			.max(
				15,
				"First name And Last name cannot be greater than 15 characters long",
			),
		// facebook_email: yup.string().email(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const { data, isLoading } = useUsermeInfoQuery();
	const Userinfo = data?.data;
	const [editaccount, Seteditaccount] = useState(false);
	const [editpass, seteditpass] = useState(false);
	const handleEdit = () => {
		Seteditaccount(true);
	};
	const handlePass = () => {
		seteditpass(true);
	};
	useEffect(() => {
		setValue("full_name", `${Userinfo?.f_name}  ${Userinfo?.l_name}`);

		// setValue("facebook_email", Userinfo?.facebook_email);
	}, [Userinfo]);
	useEffect(() => {
		if (response?.isSuccess) {
			Seteditaccount(false);
			swal("Success!", response?.data?.message, "success");
			reset();
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						setError(key, {
							type: "manual",
							message: response?.error?.data?.errors?.[key]?.[0],
						});
					}
				}
			}
		}
	}, [response?.isError]);

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("email", Userinfo?.email);
		formData.append("full_name", data?.full_name);
		// formData.append(
		// 	"facebook_email",
		// 	data?.facebook_email ? data?.facebook_email : null,
		// );
		EditData(formData);
	};
	return isLoading || response?.isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader EditData={EditData} response={response} />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="my-account-wrapper">
								<div className="my-account-heading-wrapper">
									<h3>My Profile</h3>
									{/* <h3>My Account</h3> */}
									{!editaccount ? (
										editpass ? (
											""
										) : (
											<button onClick={handleEdit} className="btn">
												Edit Profile
											</button>
										)
									) : (
										""
									)}
								</div>
								<div className="my-account-form-wrapper">
									{editpass ? (
										<ChangePassword seteditpass={seteditpass} />
									) : (
										<form onSubmit={handleSubmit(onSubmitHandler)}>
											<div className="form-group row">
												<div className="col-lg-4">
													<label htmlFor="">
														First & last name <span>*</span>
													</label>
													{editaccount ? (
														<FormInput
															id="edit"
															name="full_name"
															type="text"
															placeholder="John Doe"
															register={register}
															error={errors.full_name}
														/>
													) : (
														<input
															type="text"
															placeholder={`${Userinfo?.f_name}  ${Userinfo?.l_name}`}
															readOnly
														/>
													)}
												</div>
												<div className="col-lg-4">
													<label htmlFor="">
														Email <span>*</span>
													</label>
													{editaccount ? (
														<input
															type="text"
															placeholder={`${Userinfo?.email}`}
															disabled
															id="edit"
														/>
													) : (
														<input
															type="text"
															placeholder={`${Userinfo?.email}`}
															readOnly
														/>
													)}
												</div>
												{/* <div className="col-lg-4">
													<label htmlFor="">
														Facebook email <span>*</span>
													</label>
													{editaccount ? (
														<FormInput
															id="edit"
															name="facebook_email"
															type="email"
															placeholder="JohnDoe@example.com"
															register={register}
															error={errors.facebook_email}
														/>
													) : (
														<input
															type="text"
															placeholder={`${
																Userinfo?.facebook_email === null
																	? "Facebook Email Not Found"
																	: Userinfo?.facebook_email
															}`}
															readOnly
														/>
													)}
												</div> */}
											</div>
											<div className="form-group row">
												<div className="col-lg-4">
													<label htmlFor="">
														Password <span>*</span>
													</label>
													<div className="pass-btn-field-wrapper">
														<input
															type="password"
															placeholder="************"
															readOnly
														/>
														<button
															onClick={handlePass}
															type="button"
															className="edit-pass"
														>
															Edit Password
														</button>
													</div>
												</div>
												<div className="col-lg-4">
													<label htmlFor="">
														Subscription Package <span>*</span>
													</label>
													<input
														type="text"
														placeholder={`${
															Userinfo?.subscription === null
																? "No Subscription Found"
																: Userinfo?.subscription?.get_package?.name
														}`}
														readOnly
													/>
												</div>
												<div className="col-lg-4 align-self-end">
													{editaccount ? (
														<button
															// onClick={() => Seteditaccount(false)}
															type="submit"
															className="btn"
														>
															{response?.isLoading
																? "Loading..."
																: "Save Profile Details"}
														</button>
													) : (
														""
													)}
												</div>
											</div>
										</form>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default MyAccout;
