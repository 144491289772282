import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../assets/css/images.css";
import Masnory from "../../components/Masnory";
import ImagesMasnory from "../../components/ImagesMasnory";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import {
	useDownloadimageMutation,
	useProductsFilterQuery,
	// useVideoSectionQuery,
} from "../../store/services/ContactServices";
import { useVideoSectionQuery } from "../../store/services/FavouriteServices";
import ImageFilter from "../../components/ImageFilter";
import Loader from "../../components/Loader";
import swal from "sweetalert";
import {
	selectColor,
	selectType,
	setCategoryId,
	setFilterId,
	setTagsname,
	setTypeId,
} from "../../store/reducers/ImageFilterReducer";
import { setUserToken } from "../../store/reducers/AuthReducer";

const Image = () => {
	const { contrubutorToken, autherLink, productstags, userToken } = useSelector(
		(state) => state.AuthReducer,
	);
	const { tagsName, filterId, cateId, tyId, colors } = useSelector(
		(state) => state.ImageFilterReducer,
	);
	let dispatch = useDispatch();

	const [downloadImage, response] = useDownloadimageMutation();
	const [currentpage, setCurrentpage] = useState(1);
	const Tagsids = tagsName?.map((item) => item?.id);

	useEffect(() => {
		if (
			response?.error?.data?.statusCode === 400 &&
			response?.error?.data?.errors?.[0] ===
				"You cannot download because your free image limit reached"
		) {
			swal("Error!", response?.error?.data?.errors?.[0], "error");
		}
	}, [response?.error?.data?.statusCode === 400]);

	let datads = {
		type: "Image",
		autherName: autherLink,
		tag_ids: Tagsids,
		package_type: tyId,
		trend_ids: filterId,
		category_id: cateId,
		color_code: colors,
		page: currentpage,
		token: userToken ? userToken : "",
	};
	const { data, isLoading, refetch } = useVideoSectionQuery(datads);
	const Total_size = data?.total_size;

	useEffect(() => {
		if (data?.users !== undefined) {
			dispatch(
				setUserToken({
					token: userToken,
					user: data?.users,
				}),
			);
		}
	}, [data?.users]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(selectColor(""));
	// 		dispatch(selectType(""));
	// 		dispatch(setCategoryId(""));
	// 		dispatch(setFilterId(""));
	// 		dispatch(setTagsname([]));
	// 		dispatch(setTypeId(""));
	// 	};
	// }, []);

	const pages = Math.ceil(data?.total_size / data?.limit);
	const offset = Math.ceil(data?.limit * currentpage);

	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};

	useEffect(() => {
		return () => {
			localStorage.removeItem("ANAME");
		};
	}, []);

	const navigate = useNavigate();

	useEffect(() => {
		if (contrubutorToken !== "") {
			return navigate("/");
		}
	}, []);
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Image Sec Start Here */}
			<section className="image_sec">
				<div className="container">
					<ImageFilter
						column="col-lg-12"
						setShow={"Images"}
						notshowRegister={true}
						allNotShow={true}
						refetch={refetch}
						setTpye={false}
						Total_size={Total_size}
					/>
					<div className="img-txt-wrapper pt-5 ">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									{data?.products?.length > 0 && (
										<div className="content-wrapper">
											<p>
												Search result... Showing {offset === 0 ? 1 : offset} of{" "}
												{data?.total_size}
												...
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{data?.products?.length > 0 ? (
						<>
							<ImagesMasnory
								product={data?.products}
								reloadData={refetch}
								userData={data?.users}
							/>
							<div className="paginations-wrapper">
								<Pagination pages={pages} handlePageClick={handlePageClick} />
							</div>
						</>
					) : (
						<h2
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
								margin: "50px auto",
							}}
						>
							Images Not Found
						</h2>
					)}

					<div className="trending_images">
						<div className="heading_wrapper">
							<h2>Trending Images</h2>
						</div>
						{data?.trending?.length > 0 ? (
							<>
								<ImagesMasnory
									product={data?.trending}
									reloadData={refetch}
									userData={data?.users}
								/>
							</>
						) : (
							<h2
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
								}}
							>
								Trending Images Not Found
							</h2>
						)}
					</div>
				</div>
			</section>
			{/* Image Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Image;
