import { configureStore } from "@reduxjs/toolkit";
import AuthServices from "./services/AuthServices";
import AuthReducer from "./reducers/AuthReducer";
import cartReducers from "./reducers/CartrReducer";
import ArtistBlogService from "./services/ArtistCornerServices/ArtistBlogService";
import ArtistNewsService from "./services/ArtistCornerServices/ArtistNewsService";
import ContactServices from "./services/ContactServices";
import HomeServices from "./services/HomeServices";
import PricePackagesServices from "./services/PricePackagesServices";
import AuthContributorServices from "./services/Contributor/AuthContributorServices";
import ImageFilterReducer from "./reducers/ImageFilterReducer";
import FavouriteServices from "./services/FavouriteServices";
// import ContributorReducer from "./reducers/ContributorReducer";

const Index = configureStore({
	reducer: {
		[AuthServices.reducerPath]: AuthServices.reducer,
		[ArtistBlogService.reducerPath]: ArtistBlogService.reducer,
		[ArtistNewsService.reducerPath]: ArtistNewsService.reducer,
		[ContactServices.reducerPath]: ContactServices.reducer,
		[PricePackagesServices.reducerPath]: PricePackagesServices.reducer,
		[AuthContributorServices.reducerPath]: AuthContributorServices.reducer,
		[FavouriteServices.reducerPath]: FavouriteServices.reducer,
		[HomeServices.reducerPath]: HomeServices.reducer,
		AuthReducer: AuthReducer,
		cartReducers: cartReducers,
		ImageFilterReducer: ImageFilterReducer,
		// ContributorReducer: ContributorReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			AuthServices.middleware,
			ArtistBlogService.middleware,
			ArtistNewsService.middleware,
			HomeServices.middleware,
			PricePackagesServices.middleware,
			AuthContributorServices.middleware,
			FavouriteServices.middleware,
			ContactServices.middleware,
		]),
});

export default Index;
