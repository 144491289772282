import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/Mysubscription.css";
import {
	useCouponCodeMutation,
	useCurrentSubscriptionApiQuery,
	useRenewSubscriptionApiMutation,
	useUpdatedSubscriptionApiMutation,
} from "../../store/services/ContactServices";
import currency from "currency-formatter";
import moment from "moment/moment";
import Modal from "react-bootstrap/Modal";

import Loader from "../../components/Loader";
import { FormInput } from "../../components/InputField/input";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert";
import StripeFormRenew from "../../components/StripeFormRenew";
import { Navigate, useNavigate } from "react-router-dom";

const MySubscription = () => {
	const [show, setShow] = useState(false);
	const [types, setTypes] = useState("image");
	const [Newslet, setNews] = useState(true);
	const [renewsubscriptionData, response] = useRenewSubscriptionApiMutation();
	const [couponData, responseData] = useCouponCodeMutation();
	const { data, isLoading } = useCurrentSubscriptionApiQuery();
	const Currentsubscription = data?.data?.currentsubscription;
	const Historysubscription = data?.data?.history;
	const [slugData, setSlugData] = useState(
		Currentsubscription?.get_package?.slug,
	);
	const [discountPrice, setDiscountPrice] = useState(0);
	const [discountCode, setDiscountCode] = useState("");
	const schema = yup.object().shape({
		code: yup
			.string()
			.max(15, "Coupon Code cannot be greater than 15 characters long")
			.required("Coupon Code is Required"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("code", data?.code);
		formData.append("total_amount", Currentsubscription?.get_package?.price);
		couponData(formData);
	};

	const HandleCancel = () => {
		setDiscountPrice(0);
	};

	useEffect(() => {
		if (responseData?.isSuccess) {
			swal("Success!", responseData?.data?.message, "success");
			setDiscountCode(responseData?.data?.response?.data?.coupon_code);
			setDiscountPrice(responseData?.data?.response?.data?.discount_price);
			reset();
		}
	}, [responseData.isSuccess]);

	useEffect(() => {
		if (
			responseData?.isError &&
			responseData?.error?.data?.statusCode === 400
		) {
			swal("Error!", responseData?.error?.data?.errors?.[0], "error");
		}
	}, [responseData?.isError]);

	useEffect(() => {
		if (response?.isError && response?.error?.data?.statusCode === 400) {
			swal("Error!", response?.error?.data?.message, "error");
		}
	}, [response?.isError]);
	const date = new Date();
	let day = date.getDate();

	const [showCoupon, setShowcoupon] = useState(false);

	const handleClose = () => setShow(false);
	const [stripeData, setStripe] = useState(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		if (response?.isSuccess && response?.data?.data) {
			window.location.href = response?.data?.data;
		}
	}, [response?.isSuccess]);

	const Closemodal = () => {
		setShow(false);
	};

	const navigate = useNavigate();

	const packagepage = () => {
		navigate("/pricing/upgrade");
	};

	const PlaceOrderData = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("slug", Currentsubscription?.get_package?.slug);
		FormDatas.append("payment_type", "paypal");
		FormDatas.append("type", types);
		FormDatas.append("newsletter", Newslet ? "yes" : "no");
		discountCode !== "" && FormDatas.append("code", discountCode);
		renewsubscriptionData(FormDatas);
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Modal show={show} onHide={handleClose} className="login-modal">
				{/* <Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					<div className="login-card-wrapper">
						<button className="modal-close" onClick={Closemodal}>
							X
						</button>
						<div className="login-heading-wrapper">
							<h2>Subscription </h2>
							<p>Enter card details for purchasing subscription</p>
							<h6
								dangerouslySetInnerHTML={{
									__html: Currentsubscription?.get_package?.description,
								}}
							></h6>
							<select
								name=""
								id=""
								className="form-control hover_input"
								value={slugData}
								onChange={(e) => setSlugData(e.target.value)}
							>
								<option value={Currentsubscription?.get_package?.slug}>{`${
									Currentsubscription?.get_package?.packagetype?.name
								} (price: ${currency.format(
									Currentsubscription?.get_package?.price,
									{
										code: "USD",
									},
								)})`}</option>
							</select>

							<div className="col-lg-12">
								<div className="Add_coupon">
									<button
										className="btn_coupon"
										onClick={() => setShowcoupon(!showCoupon)}
									>
										{showCoupon ? "Remove Coupon Code" : "Add Coupon Code"}
									</button>
								</div>
								{showCoupon && (
									<form
										className="coupon_section"
										onSubmit={handleSubmit(onSubmitHandler)}
									>
										<div className="current_price">
											<div className="price_text">Price</div>
											<div className="price_value">
												{currency.format(
													Currentsubscription?.get_package?.price,
													{
														code: "USD",
													},
												)}
											</div>
										</div>
										<div className="current_price">
											<div className="price_text">Discount Price</div>
											<div className="price_value">
												{currency.format(discountPrice, {
													code: "USD",
												})}
												<div className="cancel">
													<button onClick={HandleCancel}>
														<i className="fa-solid fa-xmark"></i>
													</button>
												</div>
											</div>
										</div>
										<div className="current_price">
											<div className="price_text">Total Price</div>
											<div className="price_value">
												{currency.format(
													Currentsubscription?.get_package?.price -
														discountPrice,
													{
														code: "USD",
													},
												)}
											</div>
										</div>
										{discountPrice === 0 && (
											<div className="coupon_input">
												<label htmlFor="edit" className="coupen-label">
													Coupon Code
												</label>
												<FormInput
													id="edit"
													name="code"
													type="text"
													placeholder="Coupon Code"
													register={register}
													error={errors.code}
												/>
											</div>
										)}
										{discountPrice === 0 && (
											<div className="subscription-btn mb-5">
												<button type="submit " className="btn w-100">
													{responseData?.isLoading ? "Loading..." : "Submit"}
												</button>
											</div>
										)}
									</form>
								)}
							</div>
							{Currentsubscription?.get_subscription_usage?.total_videos_limit >
								0 && (
								<div className="row pb-3">
									<div className="col-lg-12">
										<div className="radio_main">
											<div className="radio_btn d-flex align-items-center gap-2">
												<input
													type="radio"
													id="javascript"
													name="fav_language"
													defaultChecked
													value={types}
													onChange={() => setTypes("image")}
												/>
												<label className="radio_label">Image</label>
											</div>
											<div className="radio_btn d-flex align-items-center gap-2">
												<input
													type="radio"
													id="javascript"
													name="fav_language"
													value={types}
													onChange={() => setTypes("video")}
												/>
												<label className="radio_label">Video</label>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="row pb-3">
								<div className="col-lg-12">
									<div className="radio_btn d-flex align-items-center gap-2">
										<input
											type="checkbox"
											id="javascript"
											name="fav_language"
											defaultChecked
											value={Newslet}
											onChange={() => setNews(!Newslet)}
										/>
										<label className="radio_label">
											Subscribe To Our News Letter
										</label>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="payment_method">
									<div className="icon ButtonPreview">
										<button onClick={PlaceOrderData} className="Paypal_btn">
											Pay
											<span className="paypal_pal">Pal</span>
										</button>
									</div>
									<div className="icon ButtonPreviewsd">
										<button
											onClick={() => setStripe(!stripeData)}
											className="stripe_btn"
										>
											Stripe
										</button>
									</div>
									{stripeData === true && (
										<StripeFormRenew
											type={types}
											newsletter={Newslet}
											slug={Currentsubscription?.get_package?.slug}
											discountCode={discountCode}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="my-subscription-wrapper">
								<div className="sub-wrapper">
									<div className="my-subscription-heading-wrapper">
										<h2>My Subscription</h2>
										<button onClick={packagepage} className="change-sub">
											Change Subscription
										</button>
										<i
											className="fa fa-info bg-white rounded-circle d-flex align-items-center justify-content-center text-dark"
											style={{
												width: "25px",
												height: "25px",
												cursor: "pointer",
											}}
											data-bs-toggle="tooltip"
											data-bs-placement="top"
											title="Upon changing the membership plan, the payment will be made instantly and will be effective once the current plan will be expired."
											role="tooltip"
										>
											{" "}
										</i>
									</div>
									{Currentsubscription === undefined ? (
										<h2
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												textAlign: "center",
												margin: "50px auto",
											}}
										>
											No Record Found
										</h2>
									) : (
										<>
											<div className="form-group row">
												<div className="col-lg-12">
													<div className="sub-pack-wrapper">
														<h6>BASIC</h6>
														<h3>
															{currency.format(
																Currentsubscription?.get_package?.price,
																{
																	code: "USD",
																},
															)}
														</h3>
														<h5>Standard License</h5>
													</div>
												</div>
											</div>
											<div className="form-group row">
												<div className="col-lg-4">
													<div className="sub-pack-wrapper">
														<h6>PLAN EXPIRES</h6>
														<h3>
															{moment(Currentsubscription?.ends_at).format(
																"Do MMM YYYY",
															)}
														</h3>
														{/* <h5>At 11:35 am GMT +5</h5> */}
													</div>
												</div>
												<div className="col-lg-4">
													<div className="sub-pack-wrapper">
														<h6>IMAGE DOWNLOADS REMAINING</h6>
														<h3>
															{Currentsubscription?.get_subscription_usage !==
															null
																? Currentsubscription?.get_subscription_usage
																		?.images_limit
																: 0}
															/
															{Currentsubscription?.get_subscription_usage
																?.total_image_limit !== null
																? Currentsubscription?.get_subscription_usage
																		?.total_image_limit
																: 0}
														</h3>
														<h5>
															Ends{" "}
															{moment(Currentsubscription?.ends_at).format(
																"Do MMM YYYY",
															)}
														</h5>
													</div>
												</div>
												<div className="col-lg-4">
													<div className="sub-pack-wrapper">
														<h6>VIDEO DOWNLOADS REMAINING</h6>
														<h3>
															{Currentsubscription?.get_subscription_usage
																?.total_videos_limit !== null
																? Currentsubscription?.get_subscription_usage
																		?.total_videos_limit
																: 0}
															/
															{Currentsubscription?.get_subscription_usage !==
															null
																? Currentsubscription?.get_subscription_usage
																		?.videos_limit
																: 0}
														</h3>
														<h5>
															Ends
															{moment(Currentsubscription?.ends_at).format(
																"Do MMM YYYY",
															)}
														</h5>
													</div>
												</div>
											</div>
										</>
									)}

									{data?.response?.data?.length !== 0 && (
										<div className="form-group row">
											<div className="col-lg-12">
												<div className="btn-wrapper">
													<button
														// disabled={
														// 	moment(date).format("DD MMM YYYY") !==
														// 	moment(Currentsubscription?.ends_at).format(
														// 		"DD MMM YYYY",
														// 	)
														// }
														onClick={handleShow}
														className="btn"
													>
														Renew Subscription
													</button>
													{/* <a href="#" className="btn ms-4">
													Cancel Subscription
												</a> */}
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="divider-wrapper">
									<div className="divide"></div>
								</div>
								<div className="prev-wrapper">
									<div className="prev-heading-wrapper">
										<h3>Previous Purchase History</h3>
									</div>
									<div className="prev-table-wrapper">
										<div className="table-responsive">
											{Historysubscription?.length > 0 ? (
												<table className="table">
													<thead>
														<tr>
															<th>
																<span className="date">Date</span>
															</th>
															<th>
																<span className="plan">Subscription Plan</span>
															</th>
															<th>
																<span className="payment">Payment</span>
															</th>
															<th>
																<span className="expire">Plan Expire</span>
															</th>
														</tr>
													</thead>
													<tbody>
														{Historysubscription?.map((item, index) => {
															return (
																<tr key={index}>
																	<td>
																		{moment(item?.created_at).format(
																			"Do MMM YYYY",
																		)}
																	</td>
																	<td>{item?.get_package?.name}</td>
																	<td>
																		{currency.format(item?.get_package?.price, {
																			code: "USD",
																		})}
																	</td>
																	<td>{item?.ends_at}</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											) : (
												<h2
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														textAlign: "center",
														margin: "50px auto",
													}}
												>
													No Record Found
												</h2>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default MySubscription;
