import React, { useState } from "react";
import "../../assets/css/product-detail.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
	ProductDetailImg,
	detailsgallery,
	copyimg,
	similar1,
	similar2,
	similar3,
	similar4,
	similar5,
	similar6,
	similar7,
	similar8,
	platinumimg,
	star,
	backArrow,
} from "../../constant";
import SimilarImages from "../../components/SimilarImages";
import ReviewForm from "../../components/ReviewForm";
import SimilarVideo from "../../components/SimilarVideo";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import currency from "currency-formatter";
import moment from "moment";
import {
	selectColor,
	setTagsname,
} from "../../store/reducers/ImageFilterReducer";
import { autherName } from "../../store/reducers/AuthReducer";
import { addCart, removeItem } from "../../store/reducers/CartrReducer";
import swal from "sweetalert";
import Masnory from "../../components/Masnory";
import { useEffect } from "react";
import ShareLink from "react-facebook-share-link";
import { PinterestShareButton } from "react-share";
import { PinterestIcon } from "react-share";
import Loader from "../../components/Loader";
import { saveAs } from "file-saver";
import {
	useDeletefavouriteMutation,
	useUserCreatefavouriteQuery,
} from "../../store/services/FavouriteServices";
import {
	useDetailVariantsQuery,
	useGetProductreviewQuery,
} from "../../store/services/ContactServices";
import { useDetailSectionQuery } from "../../store/services/FavouriteServices";
import { useQuery } from "../../components/Query";
import Backhistory from "../../components/Backhistory";

const ProductDetail2 = () => {
	let query = useQuery();
	let slugs = query.get("slug");
	const AllData = {
		slug: slugs,
	};
	const { contrubutorToken, userToken } = useSelector(
		(state) => state.AuthReducer,
	);
	const { tagsName } = useSelector((state) => state.ImageFilterReducer);
	const [tagsData, setTagsData] = useState(tagsName);
	const { data, isLoading, refetch } = useDetailSectionQuery(AllData);
	console.log(data, "detalss");
	const [variantsValue, setVariantsValue] = useState(null);
	const { data: variantsData } = useDetailVariantsQuery(variantsValue);
	const Product = data?.product;
	const { data: productReview } = useGetProductreviewQuery(Product?.id);
	const SideImage = data?.sideimages;
	const Variants = data?.variants;
	const variantsDetaisl = variantsData?.product?.[0];
	const Similarimg = data?.similarimages;
	const ReviewData = productReview?.data;
	const [favoritId, setFavoritId] = useState("");
	const [deleteFav, response] = useDeletefavouriteMutation();
	const {
		createFav,
		isLoading: load,
		isSuccess,
	} = useUserCreatefavouriteQuery(favoritId);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleprofile = (id) => {
		navigate(`/artist-profile?author_slug=${id}`, { state: "Video" });
	};

	const ImageDownloader = (imageSrc, image) => {
		saveAs(imageSrc, image);
	};

	const wishlistpage = (id) => {
		if (userToken === "") {
			swal("Error!", "Please Login First", "error");
		} else {
			setFavoritId(id);
			refetch();
		}
		// window.location.href = "/MyFavourite";
	};
	const HandleDelte = (e, id) => {
		e.preventDefault();
		deleteFav(id);
		refetch();
	};

	// const HandleColor = (e, item) => {
	// 	e.preventDefault();
	// 	let colorsplit = item?.split("#")?.[1];
	// 	dispatch(selectColor(colorsplit));
	// 	navigate("/videos");
	// };

	const [currentVideo, setCurrentVideo] = useState(null);

	const playVideo = (src) => {
		setCurrentVideo(src);
	};

	const HandleCancel = () => {
		setCurrentVideo(null);
	};

	const minutes = Math.floor(Product?.video_duration / 60);
	const seconds = Math.floor(Product?.video_duration % 60).toFixed(0);

	const HandleDetaild = (e, slug) => {
		e.preventDefault();
		navigate(`/video-detail?slug=${slug}`);
	};

	const HandleTags = (e, item) => {
		e.preventDefault();
		const filtered = tagsData.filter((clr) => clr.tag !== item.tag);
		dispatch(setTagsname([...filtered, item]));
		navigate("/videos");
	};

	const HandleAuther = (e) => {
		e.preventDefault();
		let ConcatAuth = Product?.seller?.slug;
		dispatch(autherName(ConcatAuth));
		navigate("/videos");
	};

	let [extractcolor, setExtractcolor] = useState();

	const HandleCart = (item) => {
		const CheckingValue = variantsValue === null ? item : variantsDetaisl;
		const cart = localStorage.getItem("cart");
		const cartItems = cart ? JSON.parse(cart) : [];
		const checkItem = cartItems?.find(
			(items) =>
				items.name === CheckingValue?.name && items?.id === CheckingValue?.id,
		);
		if (!checkItem) {
			dispatch(addCart(CheckingValue));
			cartItems.push(CheckingValue);
			// toast.success(`${item.name} is add in cart`);
			localStorage.setItem("cart", JSON.stringify(cartItems));
			navigate("/cart");
		} else {
			const cart = localStorage.getItem("cart");
			const cartItems = cart ? JSON.parse(cart) : [];
			const checkItem = cartItems?.find(
				(items) =>
					items.name === CheckingValue?.name && items?.id === CheckingValue?.id,
			);
			if (checkItem) {
				swal("Error!", "Already exists in cart", "error");
			}
		}
	};

	useEffect(() => {
		if (slugs === null || slugs === "" || contrubutorToken !== "") {
			return navigate("/");
		}
	}, [slugs]);

	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* HeaderStart */}
			<Header />
			{/* Header End Here */}

			{/* Product Detail Start Here */}
			<section className="product-detail">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="row mt-4">
						<div className="col-lg-7">
							<div className="video-leftside">
								<div className="video-sec">
									<div className="img-preview">
										<button className="zoomImage">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												class="bi bi-zoom-in"
												viewBox="0 0 16 16"
											>
												<path
													fill-rule="evenodd"
													d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
												/>
												<path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
												<path
													fill-rule="evenodd"
													d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"
												/>
											</svg>
										</button>
										{currentVideo !== null ? (
											<div className="video_main">
												<div
													className="video-timer"
													onClick={HandleCancel}
													style={{ zIndex: "2" }}
												>
													<div className="iconds">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="currentColor"
															className="bi bi-x"
															viewBox="0 0 16 16"
														>
															<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
														</svg>
													</div>
												</div>
												<video
													controls
													autoPlay
													controlsList="nodownload nofullscreen noremoteplayback"
													disablePictureInPicture={true}
												>
													<source src={currentVideo} type="video/mp4" />
													Your browser does not support the video tag.
												</video>
											</div>
										) : (
											<figure>
												<div
													className="video-timer"
													onClick={() => playVideo(Product?.video_url)}
												>
													<div className="icon">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="7.5"
															height="8.75"
															viewBox="0 0 7.5 8.75"
														>
															<path
																id="Polygon_11"
																data-name="Polygon 11"
																d="M3.511,1.481a1,1,0,0,1,1.728,0L7.873,6a1,1,0,0,1-.864,1.5H1.741A1,1,0,0,1,.877,6Z"
																transform="translate(7.5) rotate(90)"
																fill="#fff"
															/>
														</svg>
													</div>
													<h4 className="time">
														{Math.floor(Product?.video_duration / 60)} :
														{Math.floor(Product?.video_duration % 60).toFixed(
															0,
														)}{" "}
													</h4>
												</div>

												<img
													src={
														// image?.thumbnail === null
														// 	? Masnory1
														// 	: image?.thumbnail_url
														Product?.thumbnail_url
													}
													style={{
														width: "100%",
														display: "block",
														cursor: "pointer",
													}}
													alt="Product Video"
												/>
											</figure>
										)}
									</div>

									<div className="bottom-bar">
										<div className="printest">
											<button>
												<PinterestShareButton
													url={window?.location?.href}
													media={Product?.thumbnail_url}
												>
													<span className="icon">
														<i className="fa-brands fa-pinterest-p"></i>
													</span>
													<span className="txt">Post on pinterest</span>
												</PinterestShareButton>
											</button>
										</div>

										<div className="other-action">
											<button onClick={HandleAuther}>
												<span className="icon">
													<i className="fa-solid fa-copy"></i>
												</span>
												<span className="txt">More Like This</span>
											</button>
											{Product?.favourite === "yes" ? (
												<button onClick={(e) => HandleDelte(e, Product?.id)}>
													<span className="icon">
														{/* <i className="fa-regular fa-heart"></i> */}
														<i className="fa-solid fa-heart"></i>
													</span>
													<span className="txt">Add To Favourite</span>
												</button>
											) : (
												<button onClick={() => wishlistpage(Product?.id)}>
													<span className="icon">
														<i className="fa-regular fa-heart"></i>
													</span>
													<span className="txt">Add To Favourite</span>
												</button>
											)}

											{/* <button
												onClick={() => {
													ImageDownloader(Product?.video_url, Product?.video);
												}}
											>
												<span className="icon">
													<i className="fa-solid fa-download"></i>
												</span>
												<span className="txt">Download Preview</span>
											</button> */}
											<button>
												<span className="icon">
													<i className="fa-solid fa-share-nodes"></i>
												</span>
												<ShareLink link={window?.location?.href}>
													{(link) => (
														<a href={link} target="_blank">
															<span className="txt">Facebook Share</span>
														</a>
													)}
												</ShareLink>
											</button>
										</div>
									</div>
								</div>
								<div className="description">
									<div className="top-text">
										<h6>Description</h6>
										<p>
											{variantsValue === null
												? Product?.details
												: variantsDetaisl?.details}
										</p>
									</div>
									<div className="details">
										<h6>Details</h6>
										<div className="col-md-10">
											<div className="row">
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Category :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.category?.name
																: variantsDetaisl?.category?.name}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data ">
														<span className="question">Duration of Clip :</span>
														<span className="answer">
															{variantsValue === null
																? `${Math.floor(
																		Product?.video_duration / 60,
																  )} min : ${Math.floor(
																		Product?.video_duration % 60,
																  ).toFixed(0)} sec`
																: `${Math.floor(
																		variantsDetaisl?.video_duration / 60,
																  )} min : ${Math.floor(
																		variantsDetaisl?.video_duration % 60,
																  ).toFixed(0)} sec`}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Asset Type :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.asset_type
																: variantsDetaisl?.asset_type}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data ">
														<span className="question">Frame Rate :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.package?.name
																: variantsDetaisl?.package?.name}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Asset Style :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.asset_style
																: variantsDetaisl?.asset_style}
														</span>
													</div>
												</div>
												{/* <div className="col-md-6">
													<div className="single-data right-side">
														<span className="question">Codec(s) :</span>
														<span className="answer">H.264</span>
													</div>
												</div> */}
												<div className="col-md-6">
													<div className="single-data ">
														<span className="question">File Style :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.file_extension
																: variantsDetaisl?.file_extension}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data ">
														<span className="question">Capture Date :</span>
														<span className="answer">
															{variantsValue === null
																? moment(Product?.capture_date).format(
																		"Do MMM YYYY",
																  )
																: moment(variantsDetaisl?.capture_date).format(
																		"Do MMM YYYY",
																  )}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">File Size :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.file_size
																: variantsDetaisl?.file_size}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data ">
														<span className="question">Capture Location :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.location
																: variantsDetaisl?.location}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="single-data">
														<span className="question">Image Size :</span>
														<span className="answer">
															{variantsValue === null
																? Product?.file_resolution
																: variantsDetaisl?.file_resolution}
														</span>
													</div>
												</div>
												<div className="col-md-6"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-5">
							<div className="about-product">
								<div className="top-heading">
									<div className="top-heading">
										<span style={{ textTransform: "capitalize" }}>
											{Product?.item_type} / {Product?.package_type}
										</span>
										<h2>{Product?.name}</h2>
									</div>
									{Product?.package_type === "standard" && (
										<div className="pla-img-wrapper">
											<img src={platinumimg} className="img-fluid" alt="" />
										</div>
									)}
								</div>
								<div className="product-data">
									<div className="id-availalbality">
										<span className="item-id">Item ID : {Product?.code}</span>
										{Product?.package_type === "standard" && (
											<span className="availability">
												Available In Subscription
											</span>
										)}
									</div>
									<div className="artist-color">
										<div className="artist">
											<span>
												Artist :{" "}
												<span
													onClick={() => handleprofile(Product?.seller?.slug)}
													className="name"
												>
													{Product?.seller?.f_name} {Product?.seller?.l_name}
												</span>
											</span>
										</div>
										{/* {Product?.color?.length > 0 && (
											<div className="colors">
												<span>Color Pallete :</span>
												<div className="colors-list">
													{Product?.color?.map((item, index) => {
														return (
															<span
																className="single-color"
																key={index}
																style={{
																	background: item?.color_codes,
																	cursor: "pointer",
																}}
																onClick={(e) =>
																	HandleColor(e, item?.color_codes)
																}
															>
																{item?.color_codes}
															</span>
														);
													})}
												</div>
											</div>
										)} */}
									</div>
								</div>
								<div className="resolutionlists">
									{Variants?.map((item, index) => {
										return (
											<div
												className="single-resolution"
												key={index}
												onClick={() => setVariantsValue(item?.slug)}
											>
												<input
													type="radio"
													id={`test${index + 1}`}
													name="radio-group"
													defaultChecked={index === 0}
													// checked={index === 0}
												/>
												<label htmlFor={`test${index + 1}`}>
													<h6>
														{currency.format(item?.price, {
															code: "USD",
														})}{" "}
														({item?.package_type}){" "}
													</h6>
													<p>{item?.file_resolution}</p>
												</label>
											</div>
										);
									})}

									<div className="abcd px-4">
										<button
											className="btn d-block w-100 mt-3"
											onClick={() => HandleCart(Product)}
										>
											Add To Cart
										</button>
									</div>
								</div>
								<div className="tags">
									{Product?.tags?.length > 0 && (
										<>
											<span className="heading">Related Tags:</span>
											<div className="tags-flex">
												{Product?.tags?.map((item, index) => {
													return (
														<button
															type="button"
															className="single_tag"
															key={index}
															onClick={(e) => HandleTags(e, item)}
														>
															<span className="tag-name">{item?.tag}</span>
														</button>
													);
												})}
											</div>
										</>
									)}

									<div className="image-gallery mt-4">
										<div className="row">
											{SideImage?.map((item, index) => {
												return (
													<div
														className="col-md-4 mt-3"
														key={index}
														style={{ cursor: "pointer" }}
														onClick={(e) => HandleDetaild(e, item?.slug)}
													>
														{/* <figure>
															<img
																src={item?.video_url}
																className="img-fluid gal-img"
																alt=""
															/>
														</figure> */}
														<figure>
															{/* <video
																controls
																controlsList="nodownload nofullscreen noremoteplayback"
																muted
																disablePictureInPicture={true}
																className="img-fluid gal-img"
																style={{ height: "90px", objectFit: "cover" }}
															>
																<source
																	src={item?.video_url}
																	type="video/mp4"
																/>
															</video> */}
															<img
																src={item?.thumbnail_url}
																style={{
																	height: "90px",
																	objectFit: "cover",
																}}
																alt="Product Image"
															/>
														</figure>
													</div>
												);
											})}

											<div className="col-md-4 mt-3" style={{ height: "90px" }}>
												<button
													className="morelikethis form-control"
													onClick={HandleAuther}
												>
													<span>
														<img src={copyimg} alt="" /> More Like This
													</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Similar Images Start here */}
			<SimilarVideo product={Similarimg} />
			{/* <Masnory product={Similarimg} /> */}
			{/* Similar Images Start here */}
			{/* Review Form Start */}
			<ReviewForm product_id={Product?.id} getReview={ReviewData} />
			{/* Review Form End */}
			{/* Product Detail End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ProductDetail2;
