import React, { useState } from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/myearning.css";
import { calendar, current_table } from "../../constant";
import { Chart } from "react-charts";
import ArtistChart from "../../components/ArtistChart";
import { useContributorEarningReportQuery } from "../../store/services/Contributor/AuthContributorServices";
import currency from "currency-formatter";
import Loader from "../../components/Loader";
const ArtistReport = () => {
	const { data, isLoading } = useContributorEarningReportQuery();
	const YearlyReport = data?.data?.Yearlyearning;
	const CurrentMonth = data?.data?.Currentmonth;

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="earning-card-wrapper">
								<div className="my-earnings-wrapper">
									<div className="my-earnings-heading-wrapper">
										<h3>Reports</h3>
										{/* <span className="month">
											<img src={calendar} className="img-fluid" alt="" />{" "}
											<h6>Monthly</h6>
										</span> */}
									</div>
								</div>
								<div
									className="chart-wrapper"
									style={{ width: "90%", margin: "0 auto" }}
								>
									<div className="container">
										<ArtistChart data={YearlyReport} />
									</div>
								</div>
								<div className="current-month-wrapper">
									<div className="current-month-heading-wrapper">
										<h2>Current Month Payments</h2>
									</div>

									<div className="current-table-wrapper">
										<div className="table-responsive">
											{CurrentMonth?.length > 0 ? (
												<table className="table">
													<thead>
														<th>
															<span className="img-col"></span>
														</th>
														<th className="pro-head">
															<span>Product Name</span>
														</th>
														<th className="pro-earn">
															<span>Total Earnings</span>
														</th>
														<th className="download-head">
															<span>Download Times</span>
														</th>
													</thead>
													<tbody>
														{CurrentMonth?.map((item, index) => {
															return (
																<tr key={index}>
																	<td className="pro-img">
																		<div className="pro-img-wrapper">
																			<figure>
																				<img
																					src={item?.watermark_image_url}
																					className="img-fluid"
																					alt={item?.name}
																				/>
																			</figure>
																		</div>
																	</td>
																	<td className="pro-name">
																		<h5>{item?.name}</h5>
																	</td>
																	<td className="pro-price">
																		<h5>
																			USD{" "}
																			{currency.format(item?.price, {
																				code: "USD",
																			})}
																		</h5>
																	</td>
																	<td className="pro-download">
																		<h5>{item?.order_details_count}</h5>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											) : (
												<h2
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														textAlign: "center",
														margin: "100px auto",
													}}
												>
													No Record Found
												</h2>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* <div className="paginations-wrapper">
								<ul>
									<li className="pagination-item">
										<button className="prev pagination-link">
											<i className="fa fa-arrow-left"></i>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>01.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link active pagination-link">
											{" "}
											<span>02.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>03.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="page-link pagination-link">
											<span>04.</span>
										</button>
									</li>
									<li className="pagination-item">
										<button className="next pagination-link">
											<i className="fa fa-arrow-right"></i>
										</button>
									</li>
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default ArtistReport;
