import React, { useEffect, useState } from "react";
import { aur1, star } from "../constant";
import { Rating } from "react-simple-star-rating";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert";
import { FormInput } from "./InputField/input";
import { useReviewApiMutation } from "../store/services/ContactServices";
import moment from "moment";
import Star from "./Star";
import { useUsermeInfoQuery } from "../store/services/AuthServices";

const ReviewForm = ({ product_id, getReview }) => {
	const limit = /^(?!_)(?!.*_$).{1,800}$/;
	const { data, isLoading } = useUsermeInfoQuery();
	const Userinfo = data?.data;
	const [reviewData, response] = useReviewApiMutation();
	const [showReview, setShowReview] = useState(5);
	const [subError, subErrors] = useState({});
	let [rating, setRating] = useState(0);
	const [reviews, seReviews] = useState("");

	const handleRating = (rate) => {
		setRating(rate);
	};

	const SubmitHandler = () => {
		if (rating === 0) {
			document.getElementById("rating").previousSibling.scrollIntoView();
			subErrors({ error: "rating", message: "Please Select Rating" });
			setTimeout(() => {
				subErrors({});
			}, 2000);
		} else if (reviews === "") {
			document.getElementById("reviews").previousSibling.scrollIntoView();
			subErrors({ error: "comment", message: "Please Add Comment" });
			setTimeout(() => {
				subErrors({});
			}, 2000);
		} else if (limit.test(reviews) === false) {
			document.getElementById("reviews").previousSibling.scrollIntoView();
			subErrors({
				error: "comment",
				message: "Comment cannot be greater than 800 words long",
			});
			setTimeout(() => {
				subErrors({});
			}, 2000);
		} else {
			let formData = new FormData();
			formData.append("email", Userinfo?.email);
			formData.append("name", Userinfo?.f_name);
			formData.append("product_id", product_id);
			formData.append("rating", rating);
			formData.append("comment", reviews);
			reviewData(formData);
		}
	};
	useEffect(() => {
		if (response?.isSuccess) {
			swal("Success!", response?.data?.message, "success");
			seReviews("");
			setRating(0);
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				swal("Error!", response?.error?.data?.errors?.email?.[0], "error");
			}
		}
	}, [response?.isError]);
	return (
		<>
			<section className="review-form">
				<div className="container">
					{getReview
						?.slice()
						.reverse()
						?.map((item, index) => {
							return (
								index < showReview && (
									<div className="all-reviews mb-3" key={index}>
										<div className="row for-margin review_box">
											<div className="col-md-1">
												<div className="review-img">
													<img
														alt="User Image"
														width="100px"
														height="120px"
														className="img-fluid"
														src={item?.customer?.image_url}
													/>
												</div>
											</div>
											<div className="col-md-11">
												<div className="review-tab-all d-flex justify-content-between">
													<div className="tab-text text-adjust">
														<h5>
															{item?.customer?.f_name}{" "}
															<span>
																-{" "}
																{moment(item?.created_at).format("Do MMM YYYY")}
															</span>
														</h5>
														<p>{item?.comment}</p>
													</div>
													<div className="stars reviews-stars">
														{item?.rating !== 0 ? (
															<Star stars={item?.rating} />
														) : (
															<Star stars={0} />
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							);
						})}
					{getReview?.length > showReview && (
						<div className=" d-flex justify-content-center align-content-center align-items-center">
							<button
								className="btn"
								onClick={() => setShowReview(showReview + 5)}
							>
								More
							</button>
						</div>
					)}

					<div className="heading_wrapper">
						<h2>Add a Review</h2>
					</div>
					<form>
						<div className="form_start mt-3">
							<div className="form-group mb-0">
								<label htmlFor="description">
									Your email address will not be published.Required field are
									marked <span>(*)</span>{" "}
								</label>
							</div>

							<div className="form-group" id="rating">
								<label htmlFor="description">Your rating</label>
								<div className="d-flex gap-1 mt-3">
									<Rating
										onClick={handleRating}
										initialValue={rating}
										size={24}
										color2={"#ffd700"}
									/>
								</div>
								<p className="SubErrrors">
									{subError?.error === "rating" && subError?.message}
								</p>
							</div>
							<div className="form-group" id="reviews">
								<label htmlFor="description">
									Your Review <span>*</span>
								</label>
								<textarea
									name="comment"
									value={reviews}
									onChange={(e) => seReviews(e.target.value)}
									cols="30"
									rows="10"
									className="form-control"
									id="description"
								></textarea>
								<p className="SubErrrors">
									{subError?.error === "comment" && subError?.message}
								</p>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
								<div className="form-group">
									<label htmlFor="Name*">
										Name<span>*</span>
									</label>
									<input
										id="Name*"
										className="form-control"
										name="name"
										type="text"
										placeholder={Userinfo?.f_name}
										readOnly
									/>
								</div>
								<div className="form-group">
									<label htmlFor="Email">
										Email<span>*</span>
									</label>
									<input
										className="form-control"
										id="Email*"
										name="email"
										type="email"
										placeholder={Userinfo?.email}
										readOnly
									/>
								</div>
								<div className="submit-btn">
									<button
										className="btn mt-3"
										type="button"
										onClick={SubmitHandler}
									>
										{response?.isLoading ? "Loading..." : "Submit"}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	);
};
export default ReviewForm;
