import React from "react";

export const FormInput = ({
	register,
	error = "",
	name,
	type,
	label,
	id,
	placeholder,
}) => {
	return (
		<>
			<input
				{...register(name, { required: true })}
				id={id}
				type={type}
				placeholder={placeholder}
				className={
					error?.message
						? "form-control setforminput hover_input"
						: "form-control hover_input"
				}
			/>
			<p className="errormessage">{error && <div>{error?.message}</div>}</p>
		</>
	);
};
