import React, { useEffect, useState } from "react";
import { profileAvatar, backArrow } from "../constant";
import "../assets/css/ArtistHeader.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
	useContributorusermeInfoQuery,
	useUsereditInfoMutation,
} from "../store/services/Contributor/AuthContributorServices";
import Backhistory from "./Backhistory";

const ArtistHeader = ({ EditData = null, response = null }) => {
	const { data, refetch } = useContributorusermeInfoQuery();
	const Userinfo = data?.data;
	// const [EditData, response] = useUsereditInfoMutation();
	// const navigate = useNavigate();
	const location = useLocation();
	const [filename, setfilename] = useState("");
	const hiddenFileInput = React.useRef(null);
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState();
	const handleImageUpload = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		const formData = new FormData();
		formData.append("full_name", Userinfo?.f_name + Userinfo?.l_name);
		formData.append("email", Userinfo?.email);
		// formData.append("facebook_email", Userinfo?.facebook_email);
		formData.append("image", file);
		EditData(formData);
	};
	const navigate = useNavigate();
	const handleUpload = () => {
		navigate("/upload");
	};

	useEffect(() => {
		refetch();
	}, [response?.isSuccess]);

	return (
		<>
			{/* Profile Header starts here */}
			<section className="artistHeader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="artist-heading-wrapper">
									<h1>Artist's Portal</h1>
									{/* <p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</p> */}
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="artist-avatar-wrapper">
								<div className="artist-img-wrapper">
									<figure>
										<img
											src={Userinfo?.image_url}
											className="img-fluid"
											alt="user image"
										/>

										{location.pathname == "/artist-account" && (
											<>
												<label htmlFor="avatarInput" className="changeimg">
													Change image
												</label>
												<input
													type="file"
													id="avatarInput"
													multiple={false}
													accept="image/*"
													onChange={handleImageUpload}
													style={{ display: "none" }}
												/>
											</>
										)}
									</figure>
								</div>
								<div className="artist-content-wrapper">
									<h4>
										Artist :{" "}
										<span className="name">{`${Userinfo?.f_name} ${Userinfo?.l_name}`}</span>
									</h4>
									<h6>
										Total number of uploads :{" "}
										<span className="total-uploads">
											{Userinfo?.total_product_count}
										</span>
									</h6>

									<button onClick={handleUpload} className="btn">
										Upload Content
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Profile Header ends here */}
		</>
	);
};

export default ArtistHeader;
