import React, { useCallback, useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/DownloadHistory.css";
import {
	download1,
	download2,
	download3,
	download4,
	download5,
	download6,
	download7,
	download8,
	download9,
} from "../../constant";

import { saveAs } from "file-saver";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
	useDeletefavouriteMutation,
	useUserfavouriteQuery,
} from "../../store/services/FavouriteServices";
import { setUserToken } from "../../store/reducers/AuthReducer";
import { useVideoSectionQuery } from "../../store/services/FavouriteServices";

const MyFavourite = () => {
	const [filter, setFilter] = useState("Photo");
	const datas = {
		type: filter == "Photo" ? "Image" : "Video",
	};
	const { refetch: reloadData } = useVideoSectionQuery(datas);
	const { userToken, user } = useSelector((state) => state.AuthReducer);
	const dispatch = useDispatch();
	let AllData = {
		filterData: filter,
		token: userToken ? userToken : "",
	};
	const { data, isLoading, refetch } = useUserfavouriteQuery(AllData);
	const [deleteFav, response] = useDeletefavouriteMutation();
	const Myfavourite = data?.data;
	console.log(Myfavourite, "lsdkds");

	const getImageLimit = async (Baseurl, id) => {
		let url1 = `https://backend-moonscape.developer-ourbase-camp.com/api/v1/products/download-get?filename=${Baseurl}&id=${id}&token=${userToken}`;
		let url = `https://backend-moonscape.developer-ourbase-camp.com/api/v1/products/usage-limit?id=${id}&token=${userToken}`;
		const ImageLimit = await fetch(url);
		window.location.href = url1;
	};
	const navigate = useNavigate();
	const [isZoomed, setIsZoomed] = useState(false);
	useEffect(() => {
		if (Myfavourite?.users !== undefined) {
			dispatch(
				setUserToken({
					token: userToken,
					user: Myfavourite?.users,
				}),
			);
		}
	}, [Myfavourite?.users]);

	const downloaderFromUrl = async (Baseurl, id) => {
		console.log(Baseurl, id, "popeow");
		if (userToken === "") {
			swal("Error!", "Please Login First", "error");
		}
		if (user?.free_image_limit > 0) {
			await getImageLimit(Baseurl, id);
			setTimeout(refetch(), 1000);
			await dispatch(
				setUserToken({
					token: userToken,
					user: Myfavourite?.users,
				}),
			);
		}
		if (user?.free_image_limit == 0) {
			swal("Error!", "Your Image download limit full", "error");
		}
	};

	const HandleChange = (e) => {
		setFilter(e.target.value);
	};

	useEffect(() => {
		refetch();
	}, [filter]);

	const handleZoomChange = useCallback((shouldZoom) => {
		setIsZoomed(shouldZoom);
	}, []);

	const ImageDownloader = (imageSrc, image) => {
		saveAs(imageSrc, image);
	};

	const HandleDelte = (e, id) => {
		e.preventDefault();
		deleteFav(id);
		reloadData();
	};
	{
		/* <video
																controls
																className="img-fluid"
																controlsList="nodownload nofullscreen noremoteplayback"
																disablePictureInPicture={true}
																style={{ borderRadius: "11px", zIndex: "1" }}
															>
																<source
																	src={data?.photo?.video_url}
																	type="video/mp4"
																/>
															</video> */
	}
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="download-history-wrapper">
								<div className="download-heading-wrapper">
									<h3>My Favourite</h3>
									<div className="asset-wrapper">
										<div className="asset-list-wrapper">
											<h6>Asset Type</h6>
											<select
												name=""
												onChange={HandleChange}
												value={filter}
												className="form-control"
												id=""
											>
												<option value="Photo" className="setImage43">
													Images
												</option>
												<option value="Video Style" className="setImage43">
													Video
												</option>
											</select>
										</div>
									</div>
								</div>
								{Myfavourite?.favourites?.data?.length > 0 ? (
									<div className="download-content-wrapper">
										{Myfavourite?.favourites?.data?.map((data, index) => {
											console.log(data, "msddms");
											return (
												<>
													<div key={index} className="download-img-wrapper">
														{data?.photo?.item_type === "video" ? (
															<figure>
																<Zoom>
																	<img
																		src={data?.photo?.thumbnail_url}
																		className="img-fluid"
																		alt="Product Video"
																	/>
																</Zoom>
															</figure>
														) : (
															<figure>
																<Zoom>
																	<img
																		src={data?.photo?.watermark_image_url}
																		className="img-fluid"
																		alt="Product Image"
																	/>
																</Zoom>
															</figure>
														)}
														{/* <figure>
														<Zoom>
															<img
																src={data?.photo?.watermark_image_url}
																className="img-fluid"
																alt=""
															/>
														</Zoom>
													</figure> */}
														<div className="download-btns-wrapper">
															<button
																onClick={() =>
																	navigate(
																		`/product-detail?slug=${data?.photo?.slug}`,
																	)
																}
															>
																<i className="fa-solid fa-magnifying-glass"></i>
															</button>
															{filter === "Photo" && (
																<button
																	onClick={(e) => {
																		downloaderFromUrl(
																			data?.photo?.image,
																			data?.photo?.id,
																		);
																	}}
																>
																	<i className="fa-solid fa-download"></i>
																</button>
															)}

															<button
																onClick={(e) =>
																	HandleDelte(e, data?.product_id)
																}
															>
																<i className="fa-solid fa-trash"></i>
															</button>
														</div>
													</div>
												</>
											);
										})}
									</div>
								) : (
									<h2
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											textAlign: "center",
											margin: "50px auto",
										}}
									>
										No Record Found
									</h2>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default MyFavourite;
