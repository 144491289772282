import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Public = () => {
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	return contrubutorToken !== "" ? (
		<Navigate to="/artist-account" />
	) : (
		<Outlet />
	);
};
export default Public;
