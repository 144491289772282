import React, { useState } from "react";
import { TwitterPicker } from "react-color";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../assets/css/images.css";
import Masnory from "../../components/Masnory";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useVideoSectionQuery } from "../../store/services/FavouriteServices";
import ImageFilter from "../../components/ImageFilter";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import {
	selectColor,
	selectType,
	setCategoryId,
	setFilterId,
	setTagsname,
	setTypeId,
} from "../../store/reducers/ImageFilterReducer";
import { setUserToken } from "../../store/reducers/AuthReducer";

const Videos = () => {
	const dispatch = useDispatch();
	const { contrubutorToken, autherLink, productstags, userToken } = useSelector(
		(state) => state.AuthReducer,
	);
	const { tagsName, filterId, cateId, tyId, colors } = useSelector(
		(state) => state.ImageFilterReducer,
	);
	const [currentpage, setCurrentpage] = useState(1);
	const Tagsids = tagsName?.map((item) => item?.id);
	let datads = {
		type: "Video",
		autherName: autherLink,
		tag_ids: Tagsids,
		package_type: tyId,
		trend_ids: filterId,
		category_id: cateId,
		color_code: "",
		page: currentpage,
		token: "",
	};

	const { data, isLoading, refetch } = useVideoSectionQuery(datads);
	const Total_size = data?.total_size;
	const offset = Math.ceil(data?.limit * currentpage);
	const pages = Math.ceil(data?.total_size / data?.limit);

	// useEffect(() => {
	// 	if (data?.users !== undefined) {
	// 		dispatch(
	// 			setUserToken({
	// 				token: userToken,
	// 				user: data?.users,
	// 			}),
	// 		);
	// 	}
	// }, [data?.users !== undefined]);

	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(selectColor(""));
	// 		dispatch(selectType(""));
	// 		dispatch(setCategoryId(""));
	// 		dispatch(setFilterId(""));
	// 		dispatch(setTagsname([]));
	// 		dispatch(setTypeId(""));
	// 	};
	// }, []);

	const [filter, setFilter] = useState(false);
	const [background, setBackground] = useState("");
	const [hexa, setHexa] = useState(background);
	const navigate = useNavigate();
	const [picker, setPicker] = useState(false);
	const toggleChecked = () => setPicker((value) => !value);

	const saveColors = (color) => {
		setBackground(color.hex);
		setHexa(color.hex);
	};

	const saveColors2 = (e) => {
		setHexa(e.target.value);
		setBackground(e.target.value);
	};

	useEffect(() => {
		if (contrubutorToken !== "") {
			return navigate("/");
		}
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Image Sec Start Here */}
			<section className="image_sec">
				<div className="container">
					<ImageFilter
						column="col-lg-12"
						setShow={"Videos"}
						notshowRegister={false}
						refetch={refetch}
						allNotShow={true}
						setTpye={false}
						Total_size={Total_size}
					/>
					<div className="img-txt-wrapper pt-5 ">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									{data?.products?.length > 0 && (
										<div className="content-wrapper">
											<p>
												Search result... Showing {offset === 0 ? 1 : offset} of{" "}
												{data?.total_size}
												...
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{data?.products?.length > 0 ? (
						<>
							<Masnory reloadData={refetch} product={data?.products} />
							<div className="paginations-wrapper">
								<Pagination pages={pages} handlePageClick={handlePageClick} />
							</div>
						</>
					) : (
						<h2
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
							}}
						>
							Videos Not Found
						</h2>
					)}

					<div className="trending_images">
						<div className="heading_wrapper">
							<h2>Trending Videos</h2>
						</div>
						{data?.trending?.length > 0 ? (
							<Masnory reloadData={refetch} product={data?.trending} />
						) : (
							<h2
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
								}}
							>
								Trending Videos Not Found
							</h2>
						)}
					</div>
				</div>
			</section>
			{/* Image Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Videos;
