import { createSlice } from "@reduxjs/toolkit";
const cartData = localStorage.getItem("cart");
const cartArray = cartData ? JSON.parse(cartData) : [];
function allItems(data) {
	let items = 0;
	for (let i = 0; i < data.length; i++) {
		items += 1;
	}
	return items;
}
function calcuateTotal(data) {
	let total = 0;
	for (let i = 0; i < data.length; i++) {
		total += data[i].price * 1;
	}
	return total;
}
const cartReducers = createSlice({
	name: "cart",
	initialState: {
		cart: cartArray.length > 0 ? cartArray : [],
		items: cartArray.length > 0 ? allItems(cartArray) : 0,
		total: cartArray.length > 0 ? calcuateTotal(cartArray) : 0,
	},
	reducers: {
		addCart: (state, { payload }) => {
			state.cart.push(payload);
			state.items += 1;
			state.total += payload.price * 1;
		},
		removeItem: (state, { payload }) => {
			const find = state.cart.find(
				(item) => item.id === payload?.id && item?.name === payload?.name,
			);
			if (find) {
				const index = state.cart.indexOf(find);
				state.items = 0;
				state.total -= find.price * 1;
				state.cart.splice(index, 1);
				localStorage.setItem("cart", JSON.stringify(state.cart));
			}
		},
		emptyCart: (state) => {
			state.cart = [];
			state.items = 0;
			state.total = 0;
			localStorage.removeItem("cart");
		},
	},
});
export const { addCart, removeItem, emptyCart } = cartReducers.actions;
export default cartReducers.reducer;
