import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseUrl, aboutPage, homePage } from "../../utils/Urls";

const HomeServices = createApi({
	reducerPath: "homedata",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrl,
	}),
	endpoints: (builder) => {
		return {
			homePage: builder.query({
				query: () => {
					return {
						url: homePage,
						method: "GET",
					};
				},
			}),
            aboutusPage: builder.query({
				query: () => {
					return {
						url: aboutPage,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const { useHomePageQuery,useAboutusPageQuery } = HomeServices;
export default HomeServices;
