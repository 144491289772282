import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/DownloadHistory.css";
import { saveAs } from "file-saver";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import {
	useGetDownloaddataQuery,
	useUserCreatefavouriteQuery,
} from "../../store/services/FavouriteServices";
import swal from "sweetalert";

const DownloadHistory = () => {
	const { userToken } = useSelector((state) => state.AuthReducer);
	const [favoritId, setFavoritId] = useState("");
	const {
		createFav,
		isLoading: load,
		isSuccess,
	} = useUserCreatefavouriteQuery(favoritId);
	const [checkFilter, setCheckfilter] = useState("image");
	const { data, isLoading, refetch } = useGetDownloaddataQuery(checkFilter);
	const Downloaddata = data?.data;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		refetch();
	}, [checkFilter]);
	const [background, setBackground] = useState("");

	const ImageDownloader = (imageSrc, image) => {
		saveAs(imageSrc, image);
	};
	const downloaderFromUrl = async (id) => {
		let url = `https://backend-moonscape.developer-ourbase-camp.com/api/v1/products/download-get-general?&id=${id}&token=${userToken}`;
		window.location.href = url;
	};
	const wishlistpage = (id) => {
		setFavoritId(id);
		// window.location.href = "/MyFavourite";
	};
	useEffect(() => {
		if (isSuccess) {
			refetch();
			swal("Success!", "Image added to favorites!", "success");
		}
	}, [isSuccess]);
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="download-history-wrapper">
								<div className="download-heading-wrapper">
									<h3>Download History</h3>
									<div className="asset-wrapper">
										<div className="asset-list-wrapper">
											<h6>Asset Type</h6>
											<select
												name=""
												className="form-control bg-data"
												id=""
												value={checkFilter}
												onChange={(e) => setCheckfilter(e.target.value)}
											>
												<option value="image">Images</option>
												<option value="video">Videos</option>
											</select>
										</div>
									</div>
								</div>

								{Downloaddata?.length > 0 ? (
									<div className="download-content-wrapper">
										{Downloaddata?.map((data, index) => {
											return (
												<>
													<div key={index} className="download-img-wrapper">
														<div className="download-btn-wtapper">
															<div className="dropdown">
																<button
																	className=""
																	type="button"
																	id="dropdownMenuButton1"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																>
																	<i className="fa-solid fa-ellipsis-vertical"></i>
																</button>
																<ul
																	className="dropdown-menu"
																	aria-labelledby="dropdownMenuButton1"
																>
																	{console.log(data, "sdksdj")}
																	<li>
																		<button
																			className="dropdown-item set_btnsd"
																			onClick={() => {
																				downloaderFromUrl(data?.id);
																			}}
																		>
																			Redownload
																		</button>
																	</li>
																	{data?.favourite === "yes" ? (
																		<li>
																			<button className="dropdown-item set_btnsd">
																				Remove from favourite
																			</button>
																		</li>
																	) : (
																		<li>
																			<button
																				className="dropdown-item set_btnsd"
																				onClick={() => wishlistpage(data?.id)}
																			>
																				Add To Favourite
																			</button>
																		</li>
																	)}
																</ul>
															</div>
														</div>
														<figure>
															<img
																src={data?.image_url}
																className="img-fluid"
																alt="Product Image"
															/>
														</figure>
													</div>
												</>
											);
										})}
									</div>
								) : (
									<h2
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											textAlign: "center",
											margin: "50px auto",
										}}
									>
										No Record Found
									</h2>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default DownloadHistory;
