import React, { useEffect, useRef, useState } from "react";
import { adminImg, langUsa, logo } from "../constant";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoTop from "./GoTop";
import { useDispatch, useSelector } from "react-redux";
import HeaderFilter from "./HeaderFilter";
import { useUsermeInfoQuery } from "../store/services/AuthServices";
import { useContributorusermeInfoQuery } from "../store/services/Contributor/AuthContributorServices";
import { userlogout, contributorlogout } from "../store/reducers/AuthReducer";
import { useHomePageQuery } from "../store/services/HomeServices";
import { PiShoppingCartFill } from "react-icons/pi";

const Header = () => {
	const { data: logoData } = useHomePageQuery();
	const btnRef = useRef();
	const { userToken } = useSelector((state) => state.AuthReducer);
	const { items } = useSelector((item) => item?.cartReducers);
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	const { data, isLoading } = useUsermeInfoQuery();
	const { data: contriButor } = useContributorusermeInfoQuery();
	const Homelogoicons = logoData?.data?.headerlogo?.image_url;

	const Userinfo = data?.data;
	const ContributorInfo = contriButor?.data;

	const navigate = useNavigate();
	const [canvas, setCanvas] = useState();
	const [search, setSearch] = useState(false);
	const [filter, setFilter] = useState(false);
	const [background, setBackground] = useState("");
	const [hexa, setHexa] = useState(background);

	const [categoryFilter, setCategoryFilter] = useState("");
	const [categoryTypes, setTypesFilter] = useState("");
	const [Iamgetype, setIamgeType] = useState("Image");
	const [categoryTrending, setTrendingFilter] = useState("");
	const [tagsData, setTagsData] = useState([]);
	const [ColorCode, setColorCode] = useState("");
	const [search_value, set_Search_value] = useState("");
	const dispatch = useDispatch();

	const [picker, setPicker] = useState(false);
	const toggleChecked = () => setPicker((value) => !value);

	console.log(filter == true, "adoiewu");
	useEffect(() => {
		if (search === true) {
			document.body.addEventListener("click", () => {
				setSearch(false);
			});
		}
	});

	const handleOpenSearch = (e) => {
		e.stopPropagation();
		setSearch(true);
	};

	console.log(search, "poads");

	const HandlerClick = (e) => {
		e.preventDefault();
		dispatch(userlogout());
	};
	const HandlerClickss = (e) => {
		e.preventDefault();
		dispatch(contributorlogout());
	};

	const HandleTagClick = (e, item) => {
		e.preventDefault();
		const filtered = tagsData.filter((clr) => clr.tag !== item.tag);
		setTagsData([...filtered, item]);
	};

	const deleteColor = (item) => {
		const filtered = tagsData.filter((clr) => clr.tag !== item.tag);
		setTagsData(filtered);
	};

	const saveColors = (color) => {
		setBackground(color.hex);
		setHexa(color.hex);
	};
	const onChangers = (e) => {
		e.stopPropagation();
		set_Search_value(e.target.value);
	};

	const saveColors2 = (e) => {
		setHexa(e.target.value);
		setBackground(e.target.value);
	};

	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement(
			{
				pageLanguage: "en",
				includedLanguages: "en,ar,de,zh-TW,el,fr,es,it,ja,fa",
				layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
			},
			"trans",
		);
	};
	const handleFilter = (e) => {
		setFilter(true);
		e.stopPropagation();
	};

	var duplicate_google_translate_counter = 0;

	const GoogleFunc = () => {
		if (duplicate_google_translate_counter === 0) {
			var addScript = document.createElement("script");
			addScript.setAttribute(
				"src",
				"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
			);
			document.body.appendChild(addScript);
			window.googleTranslateElementInit = googleTranslateElementInit;
			duplicate_google_translate_counter = 1;
		}
	};

	const searchCancel = (e) => {
		setSearch(false);
		e.stopPropagation();
	};

	useEffect(() => {
		//this stops google adding button multiple times
		GoogleFunc();
	}, [duplicate_google_translate_counter === 0]);

	const location = useLocation();
	const currentLocation = window.location.pathname;

	const HandleSearching = (e) => {
		e.stopPropagation();
		setSearch(false);
		navigate("/searching", {
			state: {
				package_type: categoryTypes,
				tag_ids: tagsData,
				trend_ids: categoryTrending,
				type: Iamgetype,
				category_id: categoryFilter,
				color_code: ColorCode?.split("#")?.[1],
				search: search_value,
			},
		});
	};

	return (
		<>
			<GoTop />
			{/* Header starts here */}
			<header
				id="top"
				// className={currentLocation == "/" ? "home-page" : "inner-page"}
				className={
					currentLocation == "/"
						? "home-page"
						: location?.pathname == "/agreement"
							? "inner-page-agreement"
							: "inner-page"
				}
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-2 col-4">
							<div className="logo-wrapper">
								<Link to="/">
									<figure>
										<img
											src={Homelogoicons}
											className="img-fluid"
											alt="Moonscape"
										/>
									</figure>
								</Link>
							</div>
						</div>
						<div className="col-lg-6 col-1">
							<div className="header-menu-wrapper">
								<ul className="navbar-nav flex-row justify-content-center ">
									<li className="nav-item">
										<Link
											to="/"
											className={
												currentLocation == "/" ? "nav-link active" : "nav-link"
											}
										>
											<span>Home</span>
											<span>Home</span>
										</Link>
									</li>
									{contrubutorToken === "" && (
										<li className="nav-item">
											<Link
												to="/images"
												className={
													currentLocation == "/images"
														? "nav-link active"
														: "nav-link"
												}
											>
												<span>IMAGES</span>
												<span>IMAGES</span>
											</Link>
										</li>
									)}

									{contrubutorToken === "" && (
										<li className="nav-item">
											<Link
												to="/videos"
												className={
													currentLocation == "/videos"
														? "nav-link active"
														: "nav-link"
												}
											>
												<span>VIDEOS</span>
												<span>VIDEOS</span>
											</Link>
										</li>
									)}

									<li className="nav-item">
										<Link
											to="/about"
											className={
												currentLocation == "/about"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>ABOUT</span>
											<span>ABOUT</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/contributors"
											className={
												currentLocation == "/contributors"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>CONTRIBUTORS</span>
											<span>CONTRIBUTORS</span>
										</Link>
									</li>
									{userToken === "" && (
										<li className="nav-item">
											<Link
												to="/artist-corner"
												className={
													currentLocation == "/artist-corner"
														? "nav-link active"
														: "nav-link"
												}
											>
												<span>ARTIST'S CORNER</span>
												<span>ARTIST'S CORNER</span>
											</Link>
										</li>
									)}
									{contrubutorToken === "" && (
										<li className="nav-item">
											<Link
												to="/pricing"
												className={
													currentLocation == "/pricing"
														? "nav-link active"
														: "nav-link"
												}
											>
												<span>PRICING</span>
												<span>PRICING</span>
											</Link>
										</li>
									)}

									<li className="nav-item">
										<Link
											to="/contact"
											className={
												currentLocation == "/contact"
													? "nav-link active"
													: "nav-link"
											}
										>
											<span>CONTACT</span>
											<span>CONTACT</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-7">
							<div className="header-icons-wrapper">
								<ul className="icon-menu">
									{contrubutorToken === "" && (
										<>
											<li className="icon-item">
												<button
													ref={btnRef}
													onClick={(e) => handleOpenSearch(e)}
													className="icon-link search"
												>
													<i className="fa-solid fa-magnifying-glass"></i>
												</button>
											</li>

											{/* <li className="icon-item for-responsive">
												<Link to="/cart" className="icon-link total-cart-icon">
													<i className="fa-solid fa-bag-shopping"></i>

													<span className="total-cart-no">{items}</span>
												</Link>
											</li> */}
											{/* <li className="icon-item for-responsive">
												<Link
													to="/cart"
													className="icon-link total-cart-icon position-relative"
												>
													<i className="fa-solid fa-bag-shopping"></i>

													<span className="position-absolute translate-middle p-2 bg-danger border border-light rounded-circle cart_icons">
														<span className="">{items}</span>
													</span>
												</Link>
											</li> */}

											{/* <li className="icon-item for-responsive">
												<Link to="/myfavourite" className="icon-link">
													<i className="fa-regular fa-heart"></i>
												</Link>
											</li> */}
										</>
									)}
									{/* <li className="nav-item dropdown for-responsive">
										<Link
											className="nav-link dropdown-toggle"
											to="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<img src={langUsa} alt="" />
											<span className="ms-2">ENG</span>
										</Link>
										<ul
											className="dropdown-menu"
											aria-labelledby="navbarDropdown"
										>
											<li>
												<Link className="dropdown-item" to="#">
													<img src={langUsa} alt="" />
													<span>ENG</span>
												</Link>
											</li>
										</ul>
									</li> */}
									{window.innerWidth >= 991 && (
										<div
											id="trans"
											className="nav-item dropdown for-responsive"
										></div>
									)}

									{contrubutorToken !== "" && (
										<li className="nav-item dropdown profile-icon ">
											<a
												className="nav-link dropdown-toggle"
												to="#"
												id="navbarDropdown"
												role="button"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<div className="wel-wrapper">
													{/* <div className="welcome-sec">
														<span className="greeting">Welcome</span>

														<span className="name">
															{ContributorInfo?.f_name}
														</span>
													</div> */}
													<div className="admin-img">
														<Link to="/artist-account">
															<img
																src={ContributorInfo?.image_url}
																alt="User image"
															/>
														</Link>
													</div>
												</div>
											</a>
											<ul
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<li>
													<Link className="dropdown-item" to="/artist-account">
														My Account
													</Link>
												</li>
												<li>
													<button
														onClick={HandlerClickss}
														className="dropdown-item"
													>
														Sign Out
													</button>
												</li>
											</ul>
										</li>
									)}
									{userToken !== "" && (
										<li className="nav-item dropdown profile-icon ">
											<a
												className="nav-link dropdown-toggle"
												to="#"
												id="navbarDropdown"
												role="button"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<div className="wel-wrapper">
													{/* <div className="welcome-sec">
														<span className="greeting">Welcome</span>

														<span className="name">{Userinfo?.f_name}</span>
													</div> */}
													<div className="admin-img">
														<Link to="/myaccount">
															<img src={Userinfo?.image_url} alt="User Image" />
														</Link>
													</div>
												</div>
											</a>
											<ul
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<li>
													<Link className="dropdown-item" to="/myaccount">
														My Account
													</Link>
												</li>
												<li>
													<button
														onClick={HandlerClick}
														className="dropdown-item"
													>
														Sign Out
													</button>
												</li>
											</ul>
										</li>
									)}
									{/* Login-page */}
									{userToken === "" && contrubutorToken === "" ? (
										<li style={{ zIndex: "2" }}>
											<button
												className="btn"
												onClick={() => navigate("/Login-page")}
											>
												Login
											</button>
										</li>
									) : (
										""
									)}
									{contrubutorToken === "" && (
										<>
											<li className="icon-item for-responsive">
												<Link to="/cart" className="icon-link total-cart-icon">
													{/* <i className="fa-solid fa-bag-shopping"></i> */}
													<PiShoppingCartFill />

													<span className="total-cart-no">{items}</span>
												</Link>
											</li>
											{/* <li className="icon-item for-responsive">
												<Link
													to="/cart"
													className="icon-link total-cart-icon position-relative"
												>
													<i className="fa-solid fa-bag-shopping"></i>

													<span className="position-absolute translate-middle p-2 bg-danger border border-light rounded-circle cart_icons">
														<span className="">{items}</span>
													</span>
												</Link>
											</li> */}

											<li className="icon-item for-responsive">
												<Link to="/myfavourite" className="icon-link">
													<i className="fa-regular fa-heart"></i>
												</Link>
											</li>
										</>
									)}
								</ul>
								<div className="responsive-bar">
									<button onClick={() => setCanvas(true)}>
										<i className="fa-solid fa-bars"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className={search ? "search-filter show" : "search-filter"}
						onClick={(e) => e.stopPropagation()}
					>
						<form action="">
							<div className="search-box">
								<button
									className="filter"
									type="button"
									onClick={(e) => {
										handleFilter(e);
									}}
								>
									<svg
										id="Component_17_7"
										data-name="Component 17 – 7"
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="19.985"
										viewBox="0 0 25 19.985"
									>
										<g
											id="Group_8673"
											data-name="Group 8673"
											transform="translate(-1640 -474.03)"
										>
											<g
												id="Group_8672"
												data-name="Group 8672"
												transform="translate(848.01 68.424)"
											>
												<path
													id="Path_8719"
													data-name="Path 8719"
													d="M918.466,412.33v.349q0,5.9,0,11.8a2.05,2.05,0,0,1-.019.389.89.89,0,0,1-1.764-.139c0-.093,0-.186,0-.279q0-5.885.006-11.771a.408.408,0,0,0-.316-.473,3.4,3.4,0,1,1,2.435,0C918.7,412.245,918.6,412.282,918.466,412.33Zm.734-3.292a1.627,1.627,0,1,0-1.633,1.626A1.622,1.622,0,0,0,919.2,409.039Z"
													transform="translate(-113.083 0)"
												/>
												<path
													id="Path_8720"
													data-name="Path 8720"
													d="M1040.667,422.112c0,.91.007,1.82,0,2.73a.891.891,0,1,1-1.781.02c0-.836-.009-1.673,0-2.51,0-.2-.064-.265-.242-.325a3.394,3.394,0,0,1-.018-6.406.323.323,0,0,0,.26-.371q-.012-4.239,0-8.477a.89.89,0,0,1,1.188-.917.914.914,0,0,1,.6.916q0,2.189,0,4.378c0,1.376,0,2.751,0,4.127,0,.167.027.258.21.322a3.394,3.394,0,0,1-.011,6.436C1040.8,422.057,1040.742,422.082,1040.667,422.112Zm-2.523-3.311a1.628,1.628,0,1,0,1.638-1.621A1.621,1.621,0,0,0,1038.144,418.8Z"
													transform="translate(-226.192 -0.18)"
												/>
												<path
													id="Path_8721"
													data-name="Path 8721"
													d="M796.291,409.488c0,.93,0,1.86,0,2.79a.257.257,0,0,0,.206.3,3.395,3.395,0,0,1,0,6.435c-.187.065-.209.159-.208.323q.006,2.748,0,5.5a.886.886,0,0,1-1.236.871.915.915,0,0,1-.549-.92c0-1.795,0-3.59.006-5.384a.36.36,0,0,0-.279-.417,3.386,3.386,0,0,1,.023-6.381.325.325,0,0,0,.257-.375q-.013-2.72,0-5.44a.9.9,0,0,1,1.194-.917.885.885,0,0,1,.589.861C796.294,407.647,796.291,408.567,796.291,409.488Zm-.864,4.678a1.628,1.628,0,1,0,1.616,1.645A1.625,1.625,0,0,0,795.427,414.166Z"
													transform="translate(0 -0.188)"
												/>
											</g>
										</g>
									</svg>
								</button>
								<div className="form-group">
									<input
										type="text"
										placeholder="Search Artist, Artwork, Collection .."
										className="form-control"
										value={search_value}
										onChange={(e) => onChangers(e)}
									/>

									{/* <button className="camera">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="21.497"
											height="19"
											viewBox="0 0 21.497 19"
										>
											<g
												id="Group_8989"
												data-name="Group 8989"
												transform="translate(-1791 454)"
												opacity="0.5"
											>
												<g
													id="Group_8988"
													data-name="Group 8988"
													transform="translate(1791 -454)"
												>
													<path
														id="Path_9814"
														data-name="Path 9814"
														d="M702.656,236.439q-3.937,0-7.874,0a2.726,2.726,0,0,1-2.724-1.888,3.142,3.142,0,0,1-.142-.942q-.015-4.944,0-9.888a2.735,2.735,0,0,1,2.794-2.783c.727,0,1.453-.008,2.179,0a.431.431,0,0,0,.451-.285c.268-.564.553-1.119.833-1.677a1.758,1.758,0,0,1,1.69-1.043c1.586,0,3.172,0,4.757,0a.756.756,0,0,1,.737.478.683.683,0,0,1-.194.808,1.126,1.126,0,0,1-.6.2c-1.539.017-3.078.013-4.617,0a.45.45,0,0,0-.474.286c-.27.571-.559,1.133-.844,1.7a1.738,1.738,0,0,1-1.656,1.02c-.75,0-1.5,0-2.25,0a1.236,1.236,0,0,0-1.312,1.3q0,4.944,0,9.888a1.238,1.238,0,0,0,1.313,1.307q7.932,0,15.865,0A1.242,1.242,0,0,0,711.9,233.6q0-4.112,0-8.224a1.8,1.8,0,0,1,.02-.326.744.744,0,0,1,1.474.1c.01.234,0,.469,0,.7q0,3.866,0,7.732a2.721,2.721,0,0,1-2.2,2.787,3.634,3.634,0,0,1-.745.064Q706.558,236.443,702.656,236.439Z"
														transform="translate(-691.908 -217.439)"
													/>
													<path
														id="Path_9815"
														data-name="Path 9815"
														d="M797.478,310.655a4.745,4.745,0,1,1-4.754-4.749A4.745,4.745,0,0,1,797.478,310.655Zm-1.5.016a3.245,3.245,0,1,0-3.258,3.237A3.249,3.249,0,0,0,795.978,310.671Z"
														transform="translate(-781.986 -299.911)"
													/>
													<path
														id="Path_9816"
														data-name="Path 9816"
														d="M951.449,211.958c.434,0,.846-.006,1.257,0a.754.754,0,0,1,.711.544.72.72,0,0,1-.309.817,1.031,1.031,0,0,1-.461.13c-.388.018-.777.006-1.2.006,0,.4,0,.79,0,1.177a.778.778,0,0,1-.275.643.7.7,0,0,1-.766.117.686.686,0,0,1-.453-.628c-.013-.343-.005-.686-.006-1.03,0-.084,0-.168,0-.28-.434,0-.845.008-1.255,0a.744.744,0,0,1-.667-1.08.721.721,0,0,1,.68-.416c.4,0,.809,0,1.246,0,0-.446-.008-.873,0-1.3a.715.715,0,0,1,.529-.669.706.706,0,0,1,.818.3,1.159,1.159,0,0,1,.141.506C951.463,211.171,951.449,211.552,951.449,211.958Z"
														transform="translate(-931.948 -209.958)"
													/>
												</g>
											</g>
										</svg>
									</button> */}
								</div>
								<div
									className="search-cancel"
									onClick={(e) => {
										searchCancel(e);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="#000"
										className="bi bi-x-lg"
										viewBox="0 0 16 16"
									>
										<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
									</svg>
								</div>
								<div className="search-btn">
									<button
										type="button"
										className="search"
										onClick={(e) => HandleSearching(e)}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="27.725"
											height="27.706"
											viewBox="0 0 27.725 27.706"
										>
											<path
												id="Path_9860"
												data-name="Path 9860"
												d="M-64.233-245.773a11.009,11.009,0,0,1-9.182,1.261,10.83,10.83,0,0,1-6.032-4.461,11.091,11.091,0,0,1,1.375-13.983,11.09,11.09,0,0,1,13.98-1.41A10.82,10.82,0,0,1-59.37-257.4a10.955,10.955,0,0,1-1.5,8.26l2.708,2.168q1.642,1.313,3.284,2.625a3.255,3.255,0,0,1,1.087,3.714A3.244,3.244,0,0,1-57-238.511a3.2,3.2,0,0,1-2.464-1.3q-2.1-2.633-4.208-5.261C-63.853-245.3-64.04-245.532-64.233-245.773Zm-5.933-17.613a8.3,8.3,0,0,0-8.313,8.245,8.307,8.307,0,0,0,8.306,8.342,8.3,8.3,0,0,0,8.282-8.247A8.3,8.3,0,0,0-70.166-263.386Z"
												transform="translate(81.312 266.215)"
												fill="#fff"
											/>
										</svg>
									</button>
								</div>
							</div>
						</form>
						<HeaderFilter
							filter={filter}
							setFilter={setFilter}
							hexa={hexa}
							picker={picker}
							toggledefaultChecked={toggleChecked}
							saveColors2={saveColors2}
							saveColors={saveColors}
							categoryFilter={categoryFilter}
							setCategoryFilter={setCategoryFilter}
							categoryTypes={categoryTypes}
							setTypesFilter={setTypesFilter}
							Iamgetype={Iamgetype}
							setIamgeType={setIamgeType}
							categoryTrending={categoryTrending}
							setTrendingFilter={setTrendingFilter}
							HandleTagClick={HandleTagClick}
							ColorCode={ColorCode}
							setColorCode={setColorCode}
							search={search}
							setSearch={setSearch}
							tagsData={tagsData}
						/>
					</div>
				</div>
			</header>
			{/* Header ends here */}

			{/* Mobile Header Start Here */}
			<div className={canvas ? "mobile-header active" : "mobile-header"}>
				<div className="inner-box">
					<div className="cancel">
						<button onClick={() => setCanvas(false)}>
							<i className="fa-solid fa-xmark"></i>
						</button>
					</div>
					<ul className="menus">
						<li className="menu-items">
							<Link to="/" className="menu-link">
								Home
							</Link>
						</li>
						{contrubutorToken === "" && (
							<li className="menu-items">
								<Link to="/videos" className="menu-link">
									Images
								</Link>
							</li>
						)}
						{contrubutorToken === "" && (
							<li className="menu-items">
								<Link to="/videos" className="menu-link">
									Videos
								</Link>
							</li>
						)}

						<li className="menu-items">
							<Link to="/about" className="menu-link">
								About
							</Link>
						</li>

						<li className="menu-items">
							<Link to="/contributors" className="menu-link">
								Contributors
							</Link>
						</li>
						{userToken === "" && (
							<li className="menu-items">
								<Link to="/artist-corner" className="menu-link">
									Artist corner
								</Link>
							</li>
						)}

						{contrubutorToken === "" && (
							<li className="menu-items">
								<Link to="/pricing" className="menu-link">
									Pricing
								</Link>
							</li>
						)}
						<li className="menu-items">
							<Link to="/contact" className="menu-link">
								Contact us
							</Link>
						</li>
						{userToken === "" && contrubutorToken === "" ? (
							<li className="menu-items">
								<Link to="/login" className="menu-link">
									Login
								</Link>
							</li>
						) : (
							""
						)}

						{userToken === "" && contrubutorToken === "" ? (
							<li className="menu-items">
								<Link to="/register-page" className="menu-link">
									Register
								</Link>
							</li>
						) : (
							""
						)}
					</ul>
					<div className="action-btns">
						<ul>
							{contrubutorToken === "" && (
								<>
									<li>
										{window.innerWidth < 991 && (
											<div
												id="trans"
												className="nav-item dropdown for-responsive"
											></div>
										)}
									</li>
									<li className="d-flex align-items-center">
										<Link to="/cart" className="icon-link total-cart-icon">
											{/* <i className="fa-solid fa-bag-shopping"></i> */}
											<PiShoppingCartFill />
											<span className="total-cart-no">{items}</span>
										</Link>
									</li>

									<li className="icon-item for-responsive d-flex align-items-center">
										<Link to="/myfavourite" className="icon-link">
											<i className="fa-regular fa-heart"></i>
										</Link>
									</li>

								</>
							)}
						</ul>
					</div>
				</div>
			</div>
			{/* Mobile Header End Here */}
		</>
	);
};

export default Header;
