import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/agreement.css";
import { useWebsiteTermQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const WebsiteTerm = () => {
	const { data, isLoading } = useWebsiteTermQuery("terms_of_use");
	const Website_term = data?.data?.content?.value;
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start */}
			<Header />
			{/* Header End */}

			{/* Agreement Sec Start Here */}
			<section className="agreement_sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<p
						dangerouslySetInnerHTML={{
							__html: Website_term,
						}}
					></p>
				</div>
			</section>
			{/* Agreement Sec End Here */}

			{/* Footer Start */}
			<Footer />
			{/* Footer End */}
		</>
	);
};

export default WebsiteTerm;
