import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-news.css";
import { BlogImg1, newsdetail } from "../../constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useParams } from "react-router-dom";
import { useArtistnewsdetailsContentQuery } from "../../store/services/ArtistCornerServices/ArtistNewsService";
import { useQuery } from "../../components/Query";

const ArtistNewsDetail = () => {
	let query = useQuery();
	let slugs = query.get("slug");
	const navigate = useNavigate();
	const { data, isLoading } = useArtistnewsdetailsContentQuery(slugs);
	console.log(data, "eiuwe");
	const NewsDetail = data?.data?.currentNews;
	const NewsNextDetail =
		data?.data?.nextNews !== null
			? data?.data?.nextNews?.slug
			: data?.data?.nextNews;
	const NewsPreviousDetail = data?.data?.previousNews?.slug;
	const HandlePrevious = () => {
		if (NewsPreviousDetail == undefined) {
			navigate(`/artist-news`);
		} else {
			navigate(`?slug=${NewsPreviousDetail}`);
		}
	};
	const HandleNext = () => {
		navigate(`?slug=${NewsNextDetail}`);
	};
	const back = () => {
		window.history.back();
	};

	useEffect(() => {
		if (slugs === null || slugs === "") {
			return navigate("/artist-news");
		}
	}, [slugs]);

	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* News detail Sec Start Here */}
			<section className="news-detail">
				<div className="container">
					<div className="resources_btn_wrap d-flex align-items-center justify-content-between mb-3">
						{/* <a href="#" className="btn">
							Previous
						</a> */}
						<button className="btn" onClick={HandlePrevious}>
							Previous
						</button>

						<button
							className="btn"
							disabled={NewsNextDetail === null ? true : false}
							onClick={HandleNext}
						>
							Next
						</button>
					</div>
					<div className="row">
						<div className="col-lg-11">
							{isLoading ? (
								<>
									<Skeleton
										height="400px"
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
									<Skeleton
										height="50px"
										style={{ marginTop: 30 }}
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
									<Skeleton
										height="30px"
										style={{ marginTop: 30 }}
										count={3}
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
								</>
							) : (
								<div className="news-detail-card-wrapper">
									<div className="news-detail-img-wrapper">
										<figure>
											<img
												src={NewsDetail?.image_url}
												className="img-fluid"
												alt="news-detail-image"
											/>
										</figure>
									</div>
									<div className="news-detail-content-wrapper">
										<div className="news-detail-heading-wrapper">
											<h2>{NewsDetail?.title}</h2>
										</div>
										<div className="news-detail-des-wrapper">
											<p
												dangerouslySetInnerHTML={{
													__html: NewsDetail?.description,
												}}
											></p>
										</div>
										<div className="go-to-previous-page">
											<button onClick={back} className="btn">
												{" "}
												<i className="fa fa-angle-left"></i> Go Back
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* News detail Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistNewsDetail;
