import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-recourses.css";
import {
	aboutpageimg,
	angleimg,
	frame1,
	frame2,
	framevideoimg,
	recoursecheckimg,
} from "../../constant";
import { useNavigate, useParams } from "react-router-dom";
import { useAtistResourcedetailsQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import Loader from "../../components/Loader";
import ReactPlayer from "react-player";
import { useQuery } from "../../components/Query";

const ArtistResources = () => {
	let query = useQuery();
	let slugs = query.get("slug");
	const navigate = useNavigate();
	const { data, isLoading } = useAtistResourcedetailsQuery(slugs);
	console.log(data, "pdospas");
	const Resources = data?.data?.currentResource;
	const NewsNextDetail =
		data?.data?.nextResource !== null
			? data?.data?.nextResource?.slug
			: data?.data?.nextResource;
	const NewsPreviousDetail = data?.data?.previousResource?.slug;
	const HandlePrevious = () => {
		if (NewsPreviousDetail == undefined) {
			navigate(`/artist-masonry`);
		} else {
			navigate(`?slug=${NewsPreviousDetail}`);
		}
	};
	const HandleNext = () => {
		navigate(`?slug=${NewsNextDetail}`);
	};
	useEffect(() => {
		if (slugs === null || slugs === "") {
			return navigate("/artist-masonry");
		}
	}, [slugs]);
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}
			{/* recources Sec Start Here */}
			<section className="recourses-sec ">
				<div className="container">
					<div className="resources_btn_wrap d-flex align-items-center justify-content-between mb-3">
						<button className="btn" onClick={HandlePrevious}>
							Previous
						</button>

						<button
							className="btn"
							disabled={NewsNextDetail === null ? true : false}
							onClick={HandleNext}
						>
							Next
						</button>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="recourse-card-wrapper">
								<div className="recourse-content-wrapper">
									<h2>{Resources?.section_1_title}</h2>
									<p
										dangerouslySetInnerHTML={{
											__html: Resources?.section_1_details,
										}}
									></p>
								</div>
								<div className="about-img-wrapper border-line-left">
									<figure>
										<img
											src={Resources?.section_1_image_url}
											className="img-fluid"
											alt="Resources Image"
										/>
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* recources Sec End Here */}
			{/* our mission starts here */}
			<section className="our-mission pt-5  mt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper pe-2">
								<h2>{Resources?.section_2_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_2_details,
									}}
								></p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img
										src={Resources?.section_2_image_url}
										className="img-fluid"
										alt="Resources Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* Look sec starts here */}
			<section className="look-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="look-content-wrapper">
								<h2>{Resources?.section_3_title_1}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_3_details_1,
									}}
								></p>
							</div>
							<div className="look-content-wrapper">
								<h2>{Resources?.section_3_title_2}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_3_details_2,
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Look sec ends here */}
			{/* our mission starts here */}
			<section className="our-mission pt-lg-5  mt-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left pe-lg-5">
								<figure>
									<img
										src={Resources?.section_3_image_1_url}
										className="img-fluid"
										alt="Resources Image"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left pe-lg-5">
								<figure>
									<img
										src={Resources?.section_3_image_2_url}
										className="img-fluid"
										alt="Resources Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* Look sec starts here */}
			<section className="look-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="look-content-wrapper">
								<h2>{Resources?.section_4_title_1}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_4_details_1,
									}}
								></p>
							</div>
							<div className="look-content-wrapper">
								<h2>{Resources?.section_4_title_2}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_4_details_2,
									}}
								></p>
							</div>
							<div className="look-content-wrapper">
								<h2>{Resources?.section_4_title_3}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_4_details_3,
									}}
								></p>
							</div>
							{/* <div className="look-single-line-wrapper">
								<p className="text-center">
									You Can Read The Full Article Here{" "}
									<span>
										<a
											href="/blog-detail"
											style={{ color: "var(--extra-color-2)" }}
										>
											nim veniam, quis nostrud exercitation ullamco laboris nisi
											ut aliquip ex ea commodo consequat.
										</a>
									</span>
								</p>
							</div> */}
							{/* <div className="look-content-wrapper">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
									minim veniam, quis nostrud exercitation ullamco laboris nisi
									ut aliquip ex ea commodo consequat.
								</p>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* Look sec ends here */}
			{/* recources Sec Start Here */}
			<section className="recourses-sec pt-0 pb-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="recourse-card-wrapper">
								<div
									style={{ cursor: "pointer" }}
									className="about-img-wrapper border-line-left"
								>
									<div className="figure">
										<ReactPlayer
											url={Resources?.video_link}
											controls
											config={{
												file: {
													attributes: {
														controlsList:
															"nodownload nofullscreen noremoteplayback",
														disablePictureInPicture: "true",
													},
												},
											}}
											muted
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="recourse-content-wrapper mt-5 pt-5">
								<p
									dangerouslySetInnerHTML={{
										__html: Resources?.section_4_details_4,
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* recources Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistResources;
