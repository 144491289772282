import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/faq.css";
import { useFaqsQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Faq = () => {
	const { data, isLoading } = useFaqsQuery();
	const Faqs = data?.data;
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Faq starts here */}
			<section className="faq">
				<div className="container">
					<div className="row heading-row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="faq-heading-wrapper">
									<h1>FAQs</h1>
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="faq-wrapper">
								<div className="accordion" id="accordionExample">
									{Faqs?.map((item, index) => {
										return (
											<div className="accordion-item" key={index + 1}>
												<h2
													className="accordion-header"
													id={`heading${index + 1}`}
												>
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target={`#collapse${index + 1}`}
														aria-expanded="true"
														aria-controls={`collapse${index + 1}`}
													>
														<p
															dangerouslySetInnerHTML={{
																__html: item?.question,
															}}
														></p>
													</button>
												</h2>
												<div
													id={`collapse${index + 1}`}
													className={` ${
														index === 0
															? " accordion-collapse collapse show"
															: "accordion-collapse collapse"
													}`}
													aria-labelledby={`heading${index + 1}`}
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<p
															dangerouslySetInnerHTML={{
																__html: item?.answer,
															}}
														></p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* Faq ends here */}
			<Footer />
		</>
	);
};

export default Faq;
