import { createSlice } from "@reduxjs/toolkit";
const userToken =
	window.localStorage.getItem("userData") &&
	JSON.parse(window.localStorage.getItem("userData"));
const contributorToken =
	window.localStorage.getItem("ContributorData") &&
	JSON.parse(window.localStorage.getItem("ContributorData"));

const AuthReducer = createSlice({
	name: "authReducer",
	initialState: {
		contrubutorToken: contributorToken ? contributorToken?.token : "",
		contributor: "",
		userToken: userToken ? userToken?.token : "",
		user: "",
		autherLink: "",
		productstags: "",
	},
	reducers: {
		setUserToken: (state, action) => {
			state.userToken = action?.payload?.token;
			state.user = action?.payload?.user;
			window.localStorage.removeItem("ContributorData");
			state.contributor = "";
			state.contrubutorToken = "";
		},
		setContributorToken: (state, action) => {
			state.contrubutorToken = action?.payload?.token;
			state.contributor = action?.payload?.contributor;
			window.localStorage.removeItem("userData");
			state.user = "";
			state.userToken = "";
		},
		userlogout: (state) => {
			window.localStorage.removeItem("userData");
			state.user = "";
			state.userToken = "";
		},
		contributorlogout: (state) => {
			window.localStorage.removeItem("ContributorData");
			state.contributor = "";
			state.contrubutorToken = "";
		},
		autherName: (state, action) => {
			state.autherLink = action?.payload;
		},
		productsTag: (state, action) => {
			state.productstags = action?.payload;
		},
	},
});

export const {
	setUserToken,
	userlogout,
	setContributorToken,
	contributorlogout,
	autherName,
	productsTag,
} = AuthReducer.actions;
export default AuthReducer.reducer;
