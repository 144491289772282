import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";


function Star({ stars }) {
    const ratingStar = Array.from({ length: 5 }, (elem, ind) => {
        let number = ind + 0.5;
        return (
            <span key={ind}>
                {
                    stars >= ind + 1 ? (
                        <FaStar className="icon" />
                    ) : stars >= number ? (
                        <FaStarHalfAlt className="icon" />
                    ) : <AiOutlineStar className="icon" />
                }
            </span>
        )
    });

    return (
        <div className="icon-style">
            {ratingStar}
        </div>
    )

}



export default Star