import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProfileHeader from "../../components/ProfileHeader";
import ProfileSidebar from "../../components/ProfileSidebar";
import "../../assets/css/myaccount.css";
import { paypal, stripe } from "../../constant";
import {
	useAddaddressApiMutation,
	useGetAddressApiQuery,
} from "../../store/services/ContactServices";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert";
import { FormInput } from "../../components/InputField/input";
import Loader from "../../components/Loader";

const BillingInformation = () => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const schema = yup.object().shape({
		email: yup.string().email().required("Email is Required"),
		first_name: yup
			.string()
			.min(3, "First name must be at least 3 characters long")
			.max(15, "First name cannot be greater than 15 characters long")
			.required("First Name is Required"),
		last_name: yup
			.string()
			.min(3, "Last name must be at least 3 characters long")
			.max(15, "Last name cannot be greater than 15 characters long")
			.required("Last Name is Required"),
		address: yup
			.string()
			.min(3, "Address must be at least 3 characters long")
			.max(70, "Address cannot be greater than 70 characters long")
			.required("Address is Required"),
		address_type: yup
			.string()
			.min(3, "Apartment must be at least 3 characters long")
			.max(15, "Apartment cannot be greater than 15 characters long")
			.required("Apartment is Required"),
		city: yup
			.string()
			.min(3, "City must be at least 3 characters long")
			.max(15, "City cannot be greater than 15 characters long")
			.required("City is Required"),
		country: yup
			.string()
			.min(3, "Country must be at least 3 characters long")
			.max(15, "Country cannot be greater than 15 characters long")
			.required("Country is Required"),
		zip_code: yup
			.string()
			.min(5, "Zip Code must be at least 5 characters long")
			.max(5, "Zip Code cannot be greater than 5 characters long")
			.required("Zip Code is Required"),
		phone_no: yup
			.string()
			.required("Phone No is Required")
			.matches(phoneRegExp, "Phone number is not valid"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const [addaddress, responseData] = useAddaddressApiMutation();
	const { data, isLoading } = useGetAddressApiQuery();
	const Save_address = data?.data?.address?.[0];
	const [editBilling, seteditBilling] = useState(false);
	const handleBilling = () => {
		seteditBilling(true);
	};
	// onClick={() => seteditBilling(false)}
	useEffect(() => {
		setValue("email", Save_address?.email);
		setValue("first_name", Save_address?.contact_person_name?.split(" ")?.[0]);
		setValue("last_name", Save_address?.contact_person_name?.split(" ")?.[1]);
		setValue("address", Save_address?.address);
		setValue("address_type", Save_address?.address_type);
		setValue("city", Save_address?.city);
		setValue("country", Save_address?.country);
		setValue("zip_code", Save_address?.zip);
		setValue("phone_no", Save_address?.phone);
	}, [Save_address]);

	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("first_name", data?.first_name);
		formData.append("last_name", data?.last_name);
		formData.append("address", data?.address);
		formData.append("address_type", data?.address_type);
		formData.append("city", data?.city);
		formData.append("country", data?.country);
		formData.append("zip_code", data?.zip_code);
		formData.append("email", data?.email);
		formData.append("state", Save_address?.state);
		formData.append("phone_no", data?.phone_no);
		addaddress(formData);
	};

	useEffect(() => {
		if (responseData?.isSuccess) {
			swal("Success!", responseData?.data?.message, "success");
			seteditBilling(false);
			reset();
		}
	}, [responseData?.isSuccess]);

	useEffect(() => {
		if (responseData?.isError) {
			if (responseData?.isError && responseData?.error?.data?.errors) {
				setError("first_name", {
					type: "manual",
					message: responseData?.error?.data?.errors?.first_name,
				});
				setError("last_name", {
					type: "manual",
					message: responseData?.error?.data?.errors?.last_name,
				});
				setError("email", {
					type: "manual",
					message: responseData?.error?.data?.errors?.email,
				});
				setError("address", {
					type: "manual",
					message: responseData?.error?.data?.errors?.address,
				});
				setError("address_type", {
					type: "manual",
					message: responseData?.error?.data?.errors?.address_type,
				});
				setError("city", {
					type: "manual",
					message: responseData?.error?.data?.errors?.city,
				});
				setError("country", {
					type: "manual",
					message: responseData?.error?.data?.errors?.country,
				});
				setError("zip_code", {
					type: "manual",
					message: responseData?.error?.data?.errors?.zip_code,
				});
				setError("phone_no", {
					type: "manual",
					message: responseData?.error?.data?.errors?.phone_no,
				});
			}
		}
	}, [responseData?.isError]);

	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header starts here */}
			<Header />
			{/* Header Ends here */}
			{/* Profile Header starts here */}
			<ProfileHeader />
			{/* Profile Header Ends here */}
			<section className="profile">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ProfileSidebar />
						</div>
						<div className="col-lg-9">
							<div className="my-account-wrapper">
								<div className="my-account-heading-wrapper">
									<h3>Billing Information</h3>
									<button onClick={handleBilling} className="btn">
										{Save_address ? "Edit Billing" : "Add Billing"}
									</button>
								</div>
								<div className="my-account-form-wrapper">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													First & last name <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="first_name"
														type="text"
														placeholder="Enter your FirstName"
														register={register}
														error={errors.first_name}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.contact_person_name
																? Save_address?.contact_person_name?.split(
																		" ",
																  )?.[0]
																: "Please add your firstname"
														}
														readOnly
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Last Name <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="last_name"
														type="text"
														placeholder="Enter your LastName"
														register={register}
														error={errors.last_name}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.contact_person_name
																? Save_address?.contact_person_name?.split(
																		" ",
																  )?.[1]
																: "Please add your lastname"
														}
														readOnly
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Email <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="email"
														type="email"
														placeholder="Enter your Email"
														register={register}
														error={errors.email}
													/>
												) : (
													<input
														type="email"
														placeholder={
															Save_address?.email
																? Save_address?.email
																: "Please add your email address"
														}
														readOnly
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Phone <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="phone_no"
														type="tel"
														placeholder="Enter your Phone Number"
														register={register}
														error={errors.phone_no}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.phone
																? Save_address?.phone
																: "Please add your phone number"
														}
														readOnly
													/>
												)}
											</div>
											<div className="col-lg-4"></div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<label htmlFor="">
													Address <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="address"
														type="text"
														placeholder="Enter your Address"
														register={register}
														error={errors.address}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.address
																? Save_address?.address
																: "Please add your address"
														}
														readOnly
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<label htmlFor="">
													Suite , Appartment <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="address_type"
														type="text"
														placeholder="Enter your Suite, Apartment, etc"
														register={register}
														error={errors.address_type}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.address_type
																? Save_address?.address_type
																: "Please add your suite , appartment"
														}
														readOnly
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													City <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="city"
														type="text"
														placeholder="Enter your City"
														register={register}
														error={errors.city}
													/>
												) : (
													<input
														type="text"
														readOnly
														placeholder={
															Save_address?.city
																? Save_address?.city
																: "Please add your city"
														}
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Country <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="country"
														type="text"
														placeholder="Enter your Country/Region"
														register={register}
														error={errors.country}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.country
																? Save_address?.country
																: "Please add your country"
														}
														readOnly
													/>
												)}
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Zipcode <span>*</span>
												</label>
												{editBilling ? (
													<FormInput
														id="edit"
														name="zip_code"
														type="text"
														placeholder="Enter your Zip Code"
														register={register}
														error={errors.zip_code}
													/>
												) : (
													<input
														type="text"
														placeholder={
															Save_address?.zip
																? Save_address?.zip
																: "Please add your zip code"
														}
														readOnly
													/>
												)}
											</div>
										</div>
										{editBilling ? (
											<div className="form-group">
												{" "}
												<button type="submit" className="btn">
													{responseData?.isLoading ? "loading..." : "Save"}
												</button>{" "}
											</div>
										) : (
											""
										)}
									</form>
								</div>
								{/* <div className="my-account-heading-wrapper">
									<h3>Payment Information</h3>
								</div>
								<div className="my-account-form-wrapper">
									<form action="">
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Paypal Email <span>*</span>
												</label>
												<input type="text" placeholder="John Doe@example.com" />
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Paypal Password <span>*</span>
												</label>
												<input type="text" placeholder="John Doe548" />
											</div>
											<div className="col-lg-4">
												<div className="img-wrapper text-center">
													<img src={paypal} className="img-fluid" alt="" />
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="">
													Stripe Email <span>*</span>
												</label>
												<input type="text" placeholder="John Doe@example.com" />
											</div>
											<div className="col-lg-4">
												<label htmlFor="">
													Stripe Password <span>*</span>
												</label>
												<input type="text" placeholder="John Doe548" />
											</div>
											<div className="col-lg-4">
												<div className="img-wrapper text-center">
													<img src={stripe} className="img-fluid" alt="" />
												</div>
											</div>
										</div>
									</form>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer starts here */}
			<Footer />
			{/* Footer Ends here */}
		</>
	);
};

export default BillingInformation;
