import React from "react";
import ArtistHeader from "../../components/ArtistHeader";
import ArtistSidebar from "../../components/ArtistSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/myearning.css";
import { calendar, current_table } from "../../constant";
import { useContributorEarningQuery } from "../../store/services/Contributor/AuthContributorServices";
import currency from "currency-formatter";
import moment from "moment";
import Loader from "../../components/Loader";
const MyEarnings = () => {
	const { data, isLoading } = useContributorEarningQuery();
	const CurrentMonth = data?.data?.Currentmonth;
	const Previousmonth = data?.data?.Previousmonth;
	const Package = [
		{
			id: 1,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 2,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 3,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 4,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
		{
			id: 5,
			coast: "USD 25.00",
			qty: "Per / Downloads",
		},
	];

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Profile Header starts here */}
			<ArtistHeader />
			{/* Profile Header Ends here */}
			{/* Artist starts here */}
			<section className="Artist">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<ArtistSidebar />
						</div>
						<div className="col-lg-9">
							<div className="earning-card-wrapper">
								<div className="my-earnings-wrapper">
									<div className="my-earnings-heading-wrapper">
										<h3>My Earnings</h3>
										{/* <span className="month">
											<img src={calendar} className="img-fluid" alt="" />{" "}
											<h6>Monthly</h6>
										</span> */}
									</div>
								</div>
								<div className="download-cards-wrapper">
									{CurrentMonth?.length > 0 ? (
										CurrentMonth?.map((item, index) => {
											return (
												<div key={index} className="download-card-wrapper">
													<h4>
														{`$ ${Math.round(
															item.order_details_count *
																item?.product_commission,
														)}`}
													</h4>
													<h5>Per / Downloads</h5>
												</div>
											);
										})
									) : (
										<h2
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												textAlign: "center",
												margin: "110px auto",
											}}
										>
											No Record Found
										</h2>
									)}
								</div>
								<div className="divider-wrapper">
									<div className="divide"></div>
								</div>
								<div className="current-month-wrapper">
									<div className="current-month-heading-wrapper">
										<h2>Current Month Payments</h2>
									</div>
									<div className="current-table-wrapper">
										<div className="table-responsive">
											{CurrentMonth?.length > 0 ? (
												<table className="table">
													<thead>
														<th>
															<span></span>
														</th>
														<th className="pro-head">
															{" "}
															<span>Product Name</span>{" "}
														</th>
														<th className="pro-earn">
															<span>Total Earnings</span>
														</th>
														<th className="download-head">
															<span>Download Times</span>
														</th>
													</thead>
													<tbody>
														{CurrentMonth?.map((item, index) => {
															return (
																<tr key={index}>
																	<td className="pro-img">
																		<div className="pro-img-wrapper">
																			<figure>
																				<img
																					src={item?.image_url}
																					className="img-fluid"
																					alt={item?.name}
																				/>
																			</figure>
																		</div>
																	</td>
																	<td className="pro-name">
																		<h5>{item?.name}</h5>
																	</td>
																	<td className="pro-price">
																		<h5>
																			USD{" "}
																			{currency.format(item?.price, {
																				code: "USD",
																			})}
																		</h5>
																	</td>
																	<td className="pro-download">
																		<h5>{item?.order_details_count}</h5>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											) : (
												<h2
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														textAlign: "center",
														margin: "110px auto",
													}}
												>
													No Record Found
												</h2>
											)}
										</div>
									</div>
								</div>
								<div className="divider-wrapper">
									<div className="divide"></div>
								</div>
								<div className="prev-wrapper">
									<div className="prev-heading-wrapper">
										<h2>Previous Month Payments</h2>
									</div>
									<div className="row">
										<div className="col-lg-10">
											<div className="prev-table-wrapper">
												{Previousmonth?.length > 0 ? (
													<table className="table-responsive">
														<thead>
															<th className="date-heading">
																<span>Date</span>
															</th>
															<th className="Earnings-heading">
																<span>Total Earnings</span>
															</th>
															<th className="Payment-heading">
																<span>Payment Status</span>
															</th>
														</thead>
														<tbody>
															{Previousmonth?.map((item, index) => {
																return (
																	<tr key={index}>
																		<td className="date">
																			{moment(item?.capture_date).format("L")}
																		</td>
																		<td className="price">
																			USD{" "}
																			{currency.format(item?.price, {
																				code: "USD",
																			})}
																		</td>
																		<td className="status">
																			{!item?.isPaid ? (
																				<>
																					<span className="pending">
																						Pending
																					</span>{" "}
																				</>
																			) : (
																				<>
																					<span className="paid">Paid</span>{" "}
																				</>
																			)}
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</table>
												) : (
													<h2
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															textAlign: "center",
															margin: "50px auto",
														}}
													>
														No Record Found
													</h2>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Artist ends here */}
			<Footer />
		</>
	);
};

export default MyEarnings;
