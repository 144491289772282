import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-news.css";
import {
	blogContestimg,
	BlogImg1,
	ContentDetailImg,
	newsdetail,
} from "../../constant";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../components/Query";
import { useAtistContestdetailsQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";

const ArtistContentDetail = () => {
	let query = useQuery();
	let slugs = query.get("slug");
	const { data, isLoading } = useAtistContestdetailsQuery(slugs);
	const navigate = useNavigate();
	const Resources = data?.data?.currentNews;
	const NewsNextDetail =
		data?.data?.nextNews !== null
			? data?.data?.nextNews?.slug
			: data?.data?.nextNews;
	const NewsPreviousDetail = data?.data?.previousNews?.slug;
	const HandlePrevious = () => {
		if (NewsPreviousDetail == undefined) {
			navigate(`/artist-contest`);
		} else {
			navigate(`?slug=${NewsPreviousDetail}`);
		}
	};
	const HandleNext = () => {
		navigate(`?slug=${NewsNextDetail}`);
	};

	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* News detail Sec Start Here */}
			<section className="news-detail">
				<div className="container">
					<div className="resources_btn_wrap d-flex align-items-center justify-content-between mb-3">
						<button className="btn" onClick={HandlePrevious}>
							Previous
						</button>

						<button
							className="btn"
							disabled={NewsNextDetail === null ? true : false}
							onClick={HandleNext}
						>
							Next
						</button>
					</div>
					<div className="row ps-lg-5">
						<div className="col-lg-11">
							{isLoading ? (
								<>
									<Skeleton
										height="400px"
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
									<Skeleton
										height="50px"
										style={{ marginTop: 30 }}
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
									<Skeleton
										height="30px"
										style={{ marginTop: 30 }}
										count={3}
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
								</>
							) : (
								<div className="news-detail-card-wrapper">
									<div className="news-detail-img-wrapper">
										<figure>
											<img
												src={Resources?.image_url}
												className="img-fluid"
												alt={Resources?.title}
											/>
										</figure>
									</div>
									<div className="news-detail-content-wrapper">
										<div className="news-detail-heading-wrapper">
											<h2>{Resources?.title}</h2>
										</div>
										<div className="news-detail-des-wrapper">
											<p
												dangerouslySetInnerHTML={{
													__html: Resources?.description,
												}}
											></p>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* News detail Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistContentDetail;
