import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/agreement.css";
import {
	useAgreementDataQuery,
	useAgreementQuery,
} from "../../store/services/ContactServices";
import Loader from "../../components/Loader";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Agreement = () => {
	// const { data, isLoading } = useAgreementQuery();
	const { data, isLoading } = useAgreementDataQuery();
	const ContentList = data?.data?.list;
	const AgreementContent = data?.data?.description;
	console.log(ContentList, "Agree");
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start */}
			<Header />
			{/* Header End */}

			{/* Agreement Sec Start Here */}
			<section className="agreement_sec">
				<div className="container">
					<div className="back_gif_main d-flex justify-content-between align-items-center">
						<div className="testimonials-wrapper">
							<h1>Contributor Agreement</h1>
						</div>
						<Backhistory classNa={"back_icon text-end"} />
					</div>
					<div className="content-box">
						<ul className="table_content_list">
							{ContentList?.map((item, index) => (
								<li key={index}>
									<a
										href={`#${item?.title
											?.split(" ")
											?.join("-")
											?.toLowerCase()}`}
									>
										{item?.title}
									</a>
								</li>
							))}
						</ul>
						{/* <p
							dangerouslySetInnerHTML={{
								__html: Argeements?.value,
							}}
						></p> */}
						{AgreementContent?.map((item, index) => (
							<div
								className={`agreement-content-box pt-5`}
								id={`${item?.title?.split(" ")?.join("-")?.toLowerCase()}`}
								key={index}
							>
								<div className="title d-flex">
									<h3 className="serial-no me-3">{item?.serial_no}</h3>
									<h3 className="title">{item?.title}</h3>
								</div>
								<p className="agreement-para mb-0">{item?.description}</p>
							</div>
						))}
					</div>
				</div>
			</section>
			{/* Agreement Sec End Here */}

			{/* Footer Start */}
			<Footer />
			{/* Footer End */}
		</>
	);
};

export default Agreement;
