import React from "react";

function SingleList({
	description,
	firstPrice,
	secondPrice,
	thirdPrice,
	firstDetails,
	secondDetails,
	thirdDetails,
	index = 0,
}) {
	return (
		<>
			<div className="col-lg-2 col-3 mt-3 px-2 ps-0">
				<div className="pricing-box blue">
					<div className="data-heading">
						{/* {index === 2 && <h6 className="riban-best mb-0">BEST VALUE</h6>}
						<h6
							dangerouslySetInnerHTML={{
								__html: description,
							}}
						></h6> */}
					</div>
					<div className="pricing-list">
						<div className="single-price pricing_second">
							<span className="single_span">
								${firstPrice}{" "}
								<span className="small-size-font">({firstDetails})</span>
							</span>
						</div>
						<div className="single-price pricing_second">
							<span className="single_span">
								${secondPrice}{" "}
								<span className="small-size-font">({secondDetails})</span>
							</span>
						</div>
						<div className="single-price pricing_second">
							{thirdPrice !== undefined && (
								<>
									<span className="single_span">
										${thirdPrice}{" "}
										<span className="small-size-font">({thirdDetails})</span>
									</span>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SingleList;
