import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	BaseUrl,
	Search_Products,
	authLogins,
	authRegister,
	authRegisters,
	authStatus,
	forgetPassword,
	resetPassword,
	userDetails,
	userPassword,
	userUpdate,
} from "../../utils/Urls";

const AuthServices = createApi({
	reducerPath: "auth",
	tagTypes: "editInfo",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrl,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			authRegister: builder.mutation({
				query: (registerData) => {
					return {
						url: authRegisters,
						method: "POST",
						body: registerData,
					};
				},
			}),
			authLogin: builder.mutation({
				query: (loginData) => {
					return {
						url: authLogins,
						method: "POST",
						body: loginData,
					};
				},
			}),
			authStatuss: builder.query({
				query: (slug) => {
					return {
						url: `${authStatus}?${slug}`,
						method: "GET",
					};
				},
			}),
			authForget: builder.mutation({
				query: (forgetData) => {
					return {
						url: forgetPassword,
						method: "POST",
						body: forgetData,
					};
				},
			}),
			usermeInfo: builder.query({
				query: () => {
					return {
						url: userDetails,
						method: "GET",
					};
				},
				providesTags: ["editInfo"],
			}),
			authNewPassword: builder.mutation({
				query: (newPasswordData) => {
					return {
						url: userPassword,
						method: "POST",
						body: newPasswordData,
					};
				},
			}),
			usereditInfo: builder.mutation({
				query: (newUserData) => {
					return {
						url: userUpdate,
						method: "POST",
						body: newUserData,
					};
				},
				invalidatesTags: ["editInfo"],
			}),
			// usereditProfile: builder.mutation({
			// 	query: (newUserData) => {
			// 		return {
			// 			url: "/customer/update-profile-pic",
			// 			method: "POST",
			// 			body: newUserData,
			// 		};
			// 	},
			// 	invalidatesTags: ["editInfo"],
			// }),
			// usereditPassword: builder.mutation({
			// 	query: (newUserData) => {
			// 		return {
			// 			url: "/customer/update-profile",
			// 			method: "POST",
			// 			body: newUserData,
			// 		};
			// 	},
			// }),
			userresetPassword: builder.mutation({
				query: (newData) => {
					return {
						url: resetPassword,
						method: "POST",
						body: newData,
					};
				},
			}),
			searchProduct: builder.query({
				query: (data) => {
					return {
						url: `${Search_Products}?type=${data?.type}&tag_ids=${
							data?.tag_ids === "" ? "" : data?.tag_ids
						}&package_type=${
							data?.package_type === null || data?.package_type === ""
								? ""
								: data?.package_type
						}&trend_ids=${
							data?.trend_ids === null || data?.trend_ids === ""
								? ""
								: data?.trend_ids
						}&category_id=${
							data?.category_id === null || data?.category_id === ""
								? ""
								: data?.category_id
						}&color_code=${
							data?.color_code === "" || data?.color_code === undefined
								? ""
								: data?.color_code
						}&search=${
							data?.search === null || data?.search === "" ? "" : data?.search
						}&token=${data?.token ? data?.token : ""}`,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const {
	useAuthRegisterMutation,
	useAuthLoginMutation,
	useAuthForgetMutation,
	useAuthNewPasswordMutation,
	useUsermeInfoQuery,
	useUsereditInfoMutation,
	// useUsereditProfileMutation,
	useUserresetPasswordMutation,
	useSearchProductQuery,
	useAuthStatussQuery,
} = AuthServices;
export default AuthServices;
