import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Newsletter from "../../components/Newsletter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { aboutImg, verified } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { useHomePageQuery } from "../../store/services/HomeServices";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const Home = () => {
	const { contrubutorToken, userToken } = useSelector(
		(state) => state.AuthReducer,
	);
	const { data, isLoading } = useHomePageQuery();
	const responseData = data?.data;
	const navigate = useNavigate();
	const CheckTrending = (e, item) => {
		e.preventDefault();
		if (contrubutorToken === "" && item?.asset_type === "Image") {
			navigate(`/product-detail?slug=${item?.slug}`);
		} else if (contrubutorToken === "" && item?.asset_type === "Video") {
			navigate(`/video-detail?slug=${item?.slug}`);
		} else {
			swal("Error!", "Please login user account", "error");
		}
	};
	const setHandleCategoryFilter = (item) => {
		if (contrubutorToken === "") {
			navigate("/searching", {
				state: {
					package_type: null,
					tag_ids: null,
					trend_ids: null,
					type: "Image",
					category_id: item,
					color_code: "",
					search: null,
				},
			});
		} else {
			swal("Error!", "Please login user account", "error");
		}
	};
	const trendingFilter = (item) => {
		if (contrubutorToken === "") {
			navigate("/searching", {
				state: {
					package_type: null,
					tag_ids: null,
					trend_ids: null,
					type: "Image",
					category_id: item,
					color_code: "",
					search: null,
				},
			});
		} else {
			swal("Error!", "Please login user account", "error");
		}
	};
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 5000,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 5000,
				},
			},
		],
	};
	var bannersettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
	};
	var Trendingsli = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		adaptiveHeight: true,
		autoplay: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 3000,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 3000,
				},
			},
			{
				breakpoint: 490,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					speed: 2000,
					autoplaySpeed: 3000,
				},
			},
		],
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* BANNER starts here */}
			<section className="banner-sec">
				<div className="banner_bottom"></div>
				<Slider {...bannersettings}>
					{responseData?.sliders?.map((item, index) => {
						return (
							<img
								key={index}
								src={item?.image_url}
								className="img-fluid banner-bg-img"
								alt="Banner Image"
							/>
						);
					})}
				</Slider>
				<div className="banner-slide-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="banner-content-wrapper">
									<h1>
										{responseData?.sliders?.[0]?.heading_1} <br />{" "}
										{responseData?.sliders?.[0]?.heading_2}
									</h1>
								</div>
							</div>
							<div className="col-lg-6"></div>
						</div>
						<div className="row banner-ends">
							<div className="col-lg-6">
								<div className="banner-explore-wrapper">
									<div className="banner-explore-card-wrapper1">
										<Link to="/images">
											<div className="banner-explore-content-wrapper">
												<h6>
													{
														responseData?.homepage?.section_1_h_1.split(
															" ",
														)?.[0]
													}
												</h6>
												<h5>
													{
														responseData?.homepage?.section_1_h_1.split(
															" ",
														)?.[1]
													}
												</h5>
											</div>
										</Link>
									</div>
									<div className="banner-explore-card-wrapper2">
										<a href="#categories">
											<div className="banner-explore-content-wrapper">
												<h6>
													{
														responseData?.homepage?.section_1_h_2.split(
															" ",
														)?.[0]
													}
												</h6>
												<h5>
													{
														responseData?.homepage?.section_1_h_2.split(
															" ",
														)?.[1]
													}
												</h5>
											</div>
										</a>
									</div>
									<div className="banner-explore-card-wrapper3">
										<Link to="/videos">
											<div className="banner-explore-content-wrapper">
												<h6>
													{
														responseData?.homepage?.section_1_h_3.split(
															" ",
														)?.[0]
													}
												</h6>
												<h5>
													{
														responseData?.homepage?.section_1_h_3.split(
															" ",
														)?.[1]
													}
												</h5>
											</div>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row low-bane">
									<div className="col-lg-8">
										<div className="banner-lower-content-wrapper">
											<h2>{responseData?.homepage?.section_1_title}</h2>
											<p>{responseData?.homepage?.section_1_details}</p>
										</div>
									</div>
									<div className="col-lg-4">
										<Link className="more" to="/contributors">
											<span>
												{responseData?.homepage?.section_1_h_4.split(" ")?.[0]}
											</span>
											<span>
												{responseData?.homepage?.section_1_h_4.split(" ")?.[1]}
											</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{!userToken && (
					<div className="register-banner-btn-wrapper">
						<Link to="/Register-buyer">
							<span className="big">Register Now</span>
							<span>And Get 12 Free Images</span>
						</Link>
					</div>
				)}
			</section>
			{/* BANNER ends here */}
			{/* TRENDING starts here */}
			<section className="trending-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="trending-heading-wrapper text-center">
								<h4>See What's Trending</h4>
							</div>
						</div>
					</div>
					<div className="row pt-3">
						<div className="col-lg-12">
							<div className="trending-cards-row">
								<Slider {...Trendingsli}>
									{responseData?.trending?.map((item, index) => {
										return (
											<div
												className="trending-card-wrapper"
												key={index}
												onClick={() => trendingFilter(item?.id)}
											>
												<div className="trending-img-wrapper">
													<figure>
														<img
															src={item?.icon_url}
															className="img-fluid"
															alt="Trending Image"
														/>
													</figure>
												</div>
												<div className="trending-content-wrapper">
													<h6>{item?.name}</h6>
												</div>
											</div>
										);
									})}
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* TRENDING ends here */}
			{/* Explore sec starts here */}
			<section className="explore">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							<div className="explore-heading-wrapper text-center">
								<h2 className="text-center">Explore New Uploads</h2>
							</div>
						</div>
						{userToken && (
							<div className="col-lg-4 d-flex justify-content-end align-items-end">
								<div className="see_all_btn text-decoration-underline me-2">
									<a href="/images">See All</a>
								</div>
							</div>
						)}
					</div>
					<div className="row pt-3">
						{responseData?.newuploads?.map((item, index) => {
							return (
								<div
									className="col-lg-4"
									key={index}
									style={{ cursor: "pointer" }}
								>
									<div
										className="explore-img-wrapper"
										onClick={(e) => CheckTrending(e, item)}
									>
										<figure>
											<img
												src={item?.watermark_image_url}
												className="img-fluid"
												alt="New Upload image"
											/>
										</figure>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			{/* Explore sec ends here */}
			{/* TOP COLLECTION Starts here */}
			<section className="top-collection">
				<div className="container">
					<div className="row mb-3 align-items-center">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							<div className="top-heading-wrapper">
								<h2>Top Collections</h2>
							</div>
						</div>
						{userToken && (
							<div className="col-lg-4 d-flex justify-content-end align-items-end">
								<div className="see_all_btn text-decoration-underline me-2">
									<a href="/images">See All</a>
								</div>
							</div>
						)}
						{/* <div className="col-lg-4">
							<Link className="coll-btn" to="/images">
								View All
							</Link>
						</div> */}
					</div>
					<div className="row">
						{responseData?.topcollection?.map((item, index) => {
							return (
								<div
									className="col-lg-4"
									key={index}
									onClick={() => trendingFilter(item?.id)}
								>
									<Link to="">
										<div className="profile-card-wrapper">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="100%"
												height="100%"
											>
												<line className="top" x1="0" y1="0" x2="900" y2="0" />
												<line
													className="left"
													x1="0"
													y1="460"
													x2="0"
													y2="-920"
												/>
												<line
													className="bottom"
													x1="300"
													y1="460"
													x2="-600"
													y2="460"
												/>
												<line
													className="right"
													x1="100%"
													y1="0"
													x2="100%"
													y2="1380"
												/>
											</svg>
											<div className="profile-name-and-img-wrapper">
												<div className="profile-img-space-wrapper">
													<div className="profile-name-wrapper">
														<h5>{item?.name}</h5>
													</div>
												</div>
												{/* <div className="profile-wishlist-wrapper">
													<button className="btn">
														<i className="fa-regular fa-heart"></i>
													</button>
												</div> */}
											</div>
											<div className="profile-gallery-wrapper">
												{item?.product?.[0]?.watermark_image_url && (
													<div className="item1">
														<img
															src={item?.product?.[0]?.watermark_image_url}
															className="img-fluid pro1"
															alt="Gallery Image"
														/>
													</div>
												)}

												<div className="item2">
													{item?.product?.[1]?.watermark_image_url && (
														<img
															src={item?.product?.[1]?.watermark_image_url}
															className="img-fluid pro2"
															alt="Gallery Image"
														/>
													)}

													<div className="bottom-image">
														{item?.product?.[2]?.watermark_image_url && (
															<img
																src={item?.product?.[2]?.watermark_image_url}
																className="img-fluid pro3"
																alt="Gallery Image"
															/>
														)}
														{item?.product?.[3]?.watermark_image_url && (
															<img
																src={item?.product?.[3]?.watermark_image_url}
																className="img-fluid pro4"
																alt="Gallery Image"
															/>
														)}
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			{/* TOP COLLECTION ends here */}
			<div className="container pt-md-5 mt-5">
				<div className="row">
					<div className="col-lg-12">
						<div className="explore-divider-wraper">
							<div className="divider-line"></div>
						</div>
					</div>
				</div>
			</div>
			{/* About us starts here */}
			<section className="about">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={aboutImg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-content-wrapper">
								<h2>{responseData?.homepage?.section_2_h_1}</h2>
								<p>{responseData?.homepage?.section_2_details_1}</p>
								<h2>{responseData?.homepage?.section_2_h_2}</h2>
								<p>{responseData?.homepage?.section_2_details_2}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About us ends here */}
			{/* Promotion sec starts here */}
			<section className="promotion">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="promotion-card-1-wrapper">
								<div className="promotion-card-1-content-wrapper">
									<p>{responseData?.homepage?.section_3_title_1}</p>
									<h4>
										{responseData?.homepage?.section_3_h_1} <br />{" "}
										{responseData?.homepage?.section_3_h_2}
									</h4>
									<Link to="/tips" className="btn">
										{responseData?.homepage?.section_3_btn_text_1}
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="promotion-card-2-wrapper">
								<div className="promotion-card-2-content-wrapper">
									<h4>{responseData?.homepage?.section_3_title_2}</h4>
									<p>{responseData?.homepage?.section_3_detalis_2}</p>
									<Link to="/pricing" className="btn">
										{responseData?.homepage?.section_3_btn_text_2}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Promotion sec ends here */}
			{/* Photo sec starts here */}
			<section className="photo-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="photo-heading-wrapper">
								<h2>Photo Of This Week</h2>
								<p>
									Discover the most outstanding collections in all topics of
									life.
								</p>
							</div>
						</div>
					</div>
					<div className="row mt-3 align-items-center">
						<div className="col-lg-7">
							<div className="photo-img-wrapper">
								<figure>
									<img
										src={
											responseData?.photo_of_week?.[0]?.photo
												?.watermark_image_url
										}
										className="img-fluid w-100"
										alt="Photo Of This Week Image"
										style={{ objectFit: "cover" }}
									/>
								</figure>
							</div>
						</div>

						<div className="col-lg-5">
							<div className="photo-content-wrapper">
								<p>
									Uploaded On{" "}
									<span>{responseData?.photo_of_week?.[0]?.date}</span>
								</p>
								<h2>{responseData?.photo_of_week?.[0]?.title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: responseData?.photo_of_week?.[0]?.description,
									}}
								></p>
								<div className="photo-illustration-wrapper">
									<div className="photo-img-name-wrapper">
										<div className="photo-img-wrapper">
											<figure>
												<img
													className="user-photo"
													src={
														responseData?.photo_of_week?.[0]?.photo?.seller
															?.image_url
													}
													alt="Seller Image"
												/>
												<img src={verified} className="verified" alt="" />
											</figure>
										</div>
										<div className="photo-name-wrapper">
											<h5>
												{
													responseData?.photo_of_week?.[0]?.photo?.category
														?.name
												}
											</h5>
											<h6>
												Created By{" "}
												<span>
													{
														responseData?.photo_of_week?.[0]?.photo?.seller
															?.f_name
													}
													&nbsp;
													{
														responseData?.photo_of_week?.[0]?.photo?.seller
															?.l_name
													}
												</span>
											</h6>
										</div>
									</div>

									<div className="photo-btn-wrapper">
										{contrubutorToken !== "" ? (
											<Link
												style={{ pointerEvents: "none" }}
												to={`/artist-profile?author_slug=${responseData?.photo_of_week?.[0]?.photo?.seller?.slug}`}
												className="btn"
											>
												See more from this artist
											</Link>
										) : (
											<Link
												to={`/artist-profile?author_slug=${responseData?.photo_of_week?.[0]?.photo?.seller?.slug}`}
												className="btn"
											>
												See more from this artist
											</Link>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Photo sec ends here */}
			{/* FEATURED CATEGORIES starts here */}
			<section className="featured-cat" id="categories">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="featured-content-wrapper">
								<h2>Featured Categories</h2>
								<p>
									Discover the most outstanding collections in all topics of
									life.
								</p>
								{/* <div className="all-cat-wrapper">
									<button className="all-cat">All Categories</button>
								</div> */}
							</div>
						</div>
					</div>
					<div className="row mt-5 pt-5">
						<div className="col-lg-12">
							<div className="cat-fst-row-wrapper">
								{responseData?.featuredone?.map((item, index) => {
									return (
										<div
											onClick={() => setHandleCategoryFilter(item?.id)}
											key={index}
											className={`cat__card_outer_wrapp ${
												index === 0
													? "fst-cat"
													: index === 1
													? "sec-cat"
													: "thir-cat"
											}`}
											style={{ cursor: "pointer" }}
										>
											<div
												className={`cat-card-wrapper`}
												style={{
													backgroundImage: `url(${item?.icon_url})`,
													cursor: "pointer",
												}}
											>
												<div className="cat-content-wrapper">
													<h4>{item?.name}</h4>
												</div>
											</div>
											<div className="img__border"></div>
										</div>
									);
								})}
							</div>
							<div className="cat-sec-row-wrapper">
								{responseData?.featuredtwo?.map((item, index) => {
									return (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => setHandleCategoryFilter(item?.id)}
											className={`cat__card_outer_wrapp ${
												index === 0
													? "four-cat"
													: index === 1
													? "five-cat"
													: "six-cat"
											}`}
											key={index}
										>
											<div
												className={`cat-card-wrapper`}
												style={{
													backgroundImage: `url(${item?.icon_url})`,
													cursor: "pointer",
												}}
											>
												<div className="cat-content-wrapper">
													<h4>{item?.name}</h4>
												</div>
											</div>
											<div className="img__border"></div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* FEATURED CATEGORIES ends here */}
			{/* Promotion sec starts here */}
			<section className="join">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="promotion-card-1-wrapper">
								<div className="promotion-card-1-content-wrapper">
									<p>{responseData?.homepage?.section_4_title_1}</p>
									<h4>
										{responseData?.homepage?.section_4_h_1}
										<br /> {responseData?.homepage?.section_4_h_2}
									</h4>
									<Link to="/contributors" className="btn">
										{responseData?.homepage?.section_4_btn_text_1}
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="promotion-card-2-wrapper">
								<div className="promotion-card-2-content-wrapper">
									<h5>
										{responseData?.homepage?.section_4_h1} <br />{" "}
										{responseData?.homepage?.section_4_h2}
									</h5>
									<p>{responseData?.homepage?.section_4_details_1}</p>
									<div className="promotion-social-wrapper">
										<ul>
											{responseData?.socialicons?.map((item, index) => {
												return (
													<li key={index}>
														<a
															href={item?.link}
															target="_blank"
															className={`${
																item?.name === "twitter"
																	? "twitter"
																	: item?.name === "linkedin"
																	? "twitter"
																	: item?.name === "google-plus"
																	? "pinterest"
																	: item?.name === "pinterest"
																	? "pinterest"
																	: item?.name === "instagram"
																	? "instagram"
																	: item?.name === "facebook"
																	? "facebook"
																	: "youtube"
															}`}
														>
															<i className={`fa-brands ${item?.icon}`}></i>
														</a>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* COUNTER ends here */}
			{/* NewsLetter starts here */}
			<Newsletter />
			{/* NewsLetter ends here */}
			<Footer />
		</>
	);
};

export default Home;
