import { createSlice } from "@reduxjs/toolkit";

// const tagsNamed = JSON.parse(localStorage.getItem("TagName"));
// const categoryId = JSON.parse(localStorage.getItem("CID"));
// const typeId = JSON.parse(localStorage.getItem("TID"));
// const filterIds = JSON.parse(localStorage.getItem("FId"));

const ImageFilterReducer = createSlice({
	name: "ImageReducer",
	initialState: {
		tagsName: [],
		filterId: "",
		cateId: "",
		tyId: "",
		color: null,
		colors: null,
		imageType: "",
	},
	reducers: {
		setTagsname: (state, action) => {
			// localStorage.setItem("TagName", JSON.stringify(action?.payload));
			state.tagsName = action?.payload;
		},
		setFilterId: (state, action) => {
			// localStorage.setItem("FId", JSON.stringify(action?.payload));
			state.filterId = action?.payload;
		},
		setCategoryId: (state, action) => {
			// localStorage.setItem("CID", JSON.stringify(action?.payload));
			state.cateId = action?.payload;
		},
		setTypeId: (state, action) => {
			// localStorage.setItem("TID", JSON.stringify(action?.payload));
			state.tyId = action?.payload;
		},
		selectType: (state, action) => {
			state.imageType = action?.payload;
		},
		selectColor: (state, action) => {
			state.colors = action?.payload;
		},
	},
});

export const {
	setTagsname,
	setFilterId,
	setCategoryId,
	setTypeId,
	selectType,
	selectColor,
} = ImageFilterReducer.actions;
export default ImageFilterReducer.reducer;
