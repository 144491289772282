import React from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-corner.css";
import {
	aboutpageimg,
	visionimg,
	BlogImg1,
	BlogImg2,
	BlogImg3,
	RecentNews1,
} from "../../constant";
import { Link } from "react-router-dom";
import { useArtistblogContentQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import moment from "moment/moment";
import Pagination from "../../components/Pagination";
import { useState } from "react";
import Loader from "../../components/Loader";

const ArtistCorner = () => {
	const [Category, setCategory] = useState("");
	const [currentpage, setCurrentpage] = useState(1);
	let datads = {
		page: currentpage,
		slug: Category,
	};

	// Artist Corner Blog Api Call
	const { data, isLoading } = useArtistblogContentQuery(datads);
	let blogData = data?.data;

	// Pagination
	const pages = Math.ceil(blogData?.blogs?.total_size / blogData?.blogs?.limit);
	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec artist-blog">
				<div className="container">
					<div className="testimonials-wrapper">
						<h1>Blogs</h1>
					</div>
					<div className="row">
						<div className="col-lg-8">
							<div className="all_blogs">
								{blogData?.blogs?.blogss?.map((item, index) => {
									return (
										<div className="blog_box" key={index}>
											<div className="blog-img">
												<figure>
													<Link to={`/artist-blog-detail?slug=${item?.slug}`}>
														<img
															src={item?.image_url}
															alt="Blog Image"
															className="img-fluid"
														/>
													</Link>
												</figure>
												<div className="date">
													<h4>
														{moment(item?.created_at).format("Do MMM")}
														{/* 02 <span>Mar</span> */}
													</h4>
												</div>
											</div>
											<div className="blog-detail">
												<div className="posted">
													<h5>
														<a href="">{item?.addedby?.name}</a> /
														<span> {item?.category?.name}</span>{" "}
													</h5>
												</div>
												<div className="blog-description">
													<h2>{item?.title}</h2>
													<div
														dangerouslySetInnerHTML={{
															__html: item?.description,
														}}
													></div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
							<div className="paginations-wrapper">
								<Pagination pages={pages} handlePageClick={handlePageClick} />
							</div>
						</div>
						<div className="col-lg-4">
							<div className="sidebar">
								<div className="categories sidebar-box">
									<div className="title">
										<h4>Categories</h4>
									</div>
									<ul className="list">
										{blogData?.categories?.map((item, index) => {
											return (
												<li key={index}>
													<a
														onClick={() => setCategory(item?.slug)}
														style={{ cursor: "pointer" }}
													>
														<span className="name">{item?.name}</span>
														<span className="count">({item?.blogs_count})</span>
													</a>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistCorner;

{
	/* <h5>
																{item?.addedby?.name} /
																<span>{item?.category?.name}</span>
															</h5>
													<h5> */
}
