import React from "react";
import ArtistPageLinks from "./ArtistPageLinks";

import { backArrow } from "../constant";
import Backhistory from "./Backhistory";
import { useLocation, useNavigate } from "react-router-dom";

const AritstPageHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	console.log(location?.pathname);
	const HandleResource = () => {
		if (location?.pathname == "/artist-recourses") {
			navigate(`/artist-masonry`);
		} else {
			navigate(`/artist-contest`);
		}
	};
	return (
		<>
			{/* artist page header starts here */}
			<section className="artist-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="heading-wrapper">
								<h1>Artist's Corner</h1>
								<div className="right-div d-flex align-items-center">
									<Backhistory classNa={"back_icon"} />
									{location?.pathname == "/artist-recourses" && (
										<button className="btn mt-0 ms-3" onClick={HandleResource}>
											Back to Resources
										</button>
									)}
									{location?.pathname == "/artist-content-detail" && (
										<button className="btn mt-0 ms-3" onClick={HandleResource}>
											Back to Contest & Events
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<ArtistPageLinks />
						</div>
					</div>
				</div>
			</section>
			{/* artist page header ends here */}
		</>
	);
};

export default AritstPageHeader;
