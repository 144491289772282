import React, { useState } from "react";
import { profileAvatar, backArrow } from "../constant";
import "../assets/css/ProfileHeaderSidebar.css";
import { useLocation } from "react-router-dom";
import {
	useUsereditInfoMutation,
	useUsermeInfoQuery,
} from "../store/services/AuthServices";
import { useEffect } from "react";
import Backhistory from "./Backhistory";

const ProfileHeader = ({ EditData = null, response = null }) => {
	const location = useLocation();
	const { data, isLoading } = useUsermeInfoQuery();
	const Userinfo = data?.data;
	const [filename, setfilename] = useState("");
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState(Userinfo?.image_url);
	const hiddenFileInput = React.useRef(null);
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		const formData = new FormData();
		formData.append("full_name", Userinfo?.f_name + Userinfo?.l_name);
		formData.append("email", Userinfo?.email);
		formData.append("image", file);
		EditData(formData);
	};
	// formData.append(
	// 	"facebook_email",
	// 	Userinfo?.facebook_email ? Userinfo?.facebook_email : null,
	// );

	return (
		<>
			{/* Profile Header starts here */}
			<section className="ProfileHeader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="profile-heading-wrapper">
									<h1>My Account</h1>
									{/* <p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</p> */}
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="artist-avatar-wrapper">
								<div className="artist-img-wrapper">
									<figure>
										<img
											src={Userinfo?.image_url}
											className="img-fluid"
											alt="User Image"
										/>

										{location.pathname == "/myaccount" && (
											<>
												<label htmlFor="avatarInput" className="changeimg">
													Change image
												</label>
												<input
													type="file"
													id="avatarInput"
													multiple={false}
													accept="image/*"
													onChange={handleImageUpload}
													style={{ display: "none" }}
												/>
											</>
										)}
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Profile Header ends here */}
		</>
	);
};

export default ProfileHeader;
