import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	BaseUrl,
	userCreateFavourite,
	userFavourite,
	downloadDatas,
	detailPage,
	deleteFavourite,
	videoSection,
} from "../../utils/Urls";

const FavouriteServices = createApi({
	reducerPath: "Favouritedata",
	tagTypes: "Favourite",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrl,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			videoSection: builder.query({
				query: (data) => {
					return {
						url: `${videoSection}?type=${data?.type}&author_slug=${
							data?.autherName ? data?.autherName : ""
						}&tag_ids=${
							data?.tag_ids?.length === 0 || data?.tag_ids === ""
								? ""
								: data?.tag_ids
						}&package_type=${
							data?.package_type === null || data?.package_type === ""
								? ""
								: data?.package_type
						}&trend_ids=${
							data?.trend_ids === null || data?.trend_ids === ""
								? ""
								: data?.trend_ids
						}&category_id=${
							data?.category_id === null || data?.category_id === ""
								? ""
								: data?.category_id
						}&color_code=${
							data?.color_code === undefined || data?.color_code === null
								? ""
								: data?.color_code
						}&page=${data?.page}&token=${data?.token ? data?.token : ""}`,
						method: "GET",
					};
				},
				providesTags: ["Favourite"],
			}),
			detailSection: builder.query({
				query: (data) => {
					return {
						url: `${detailPage}?slug=${data?.slug}&token=${
							data?.token ? data?.token : ""
						}`,
						method: "GET",
					};
				},
				providesTags: ["Favourite"],
			}),
			userfavourite: builder.query({
				query: (AllData) => {
					return {
						url: `${userFavourite}?type=${AllData?.filterData}&token=${
							AllData?.token ? AllData?.token : ""
						}`,
						method: "GET",
					};
				},
				providesTags: ["Favourite"],
			}),
			userCreatefavourite: builder.query({
				query: (id) => {
					return {
						url: `${userCreateFavourite}/${id}`,
						method: "GET",
					};
				},
				providesTags: ["Favourite"],
			}),
			getDownloaddata: builder.query({
				query: (data) => {
					return {
						url: `${downloadDatas}?type=${data}`,
						method: "GET",
					};
				},
				providesTags: ["Favourite"],
			}),
			deletefavourite: builder.mutation({
				query: (id) => {
					return {
						url: `${deleteFavourite}/${id}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["Favourite"],
			}),
		};
	},
});

export const {
	useDeletefavouriteMutation,
	useDetailSectionQuery,
	useGetDownloaddataQuery,
	useUserCreatefavouriteQuery,
	useVideoSectionQuery,
	useUserfavouriteQuery,
} = FavouriteServices;
export default FavouriteServices;
