import React from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-conduct.css";
import { useAtistUploadguidelineQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import Loader from "../../components/Loader";

const ArtistUploadGuide = () => {
	const { data, isLoading } = useAtistUploadguidelineQuery();
	const Guideline = data?.data;

	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header ends here */}
			{/* Code of Conduct starts here */}
			<section className="code-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-11">
							<div className="conduct-heading-wrapper">
								<h2>{Guideline?.title}</h2>
							</div>
							<p
								dangerouslySetInnerHTML={{
									__html: Guideline?.description,
								}}
							></p>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* Code of Conduct ends here */}
			<Footer />
		</>
	);
};

export default ArtistUploadGuide;
