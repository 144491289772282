import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	ArtistBlogDetailsEndPoint,
	ArtistBlogEndPoint,
	ArtistCodeofconductEndPoint,
	ArtistContestDetailsEndPoint,
	ArtistContestEventEndPoint,
	ArtistCornerEndPoint,
	ArtistReleaseFormsEndPoint,
	ArtistResourcesDetailsEndPoint,
	ArtistResourcesEndPoint,
	ArtistUploadEndPoint,
	ArtistlicenseAgreementsEndPoint,
	BaseUrlTwo,
	TipofTip,
} from "../../../utils/Urls";

const ArtistBlogService = createApi({
	reducerPath: "artist-blog-service",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrlTwo,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.contrubutorToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			artistblogContent: builder.query({
				query: (data) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistBlogEndPoint}?page=${data?.page}&slug=${data?.slug}`,
						method: "Get",
					};
				},
			}),
			artistblogdetailsContent: builder.query({
				query: (slug) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistBlogDetailsEndPoint}?slug=${slug}`,
						method: "Get",
					};
				},
			}),
			atistCodeofconduct: builder.query({
				query: (slug) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistCodeofconductEndPoint}`,
						method: "Get",
					};
				},
			}),
			atistReleaseForms: builder.query({
				query: (page) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistReleaseFormsEndPoint}?page=${page}`,
						method: "Get",
					};
				},
			}),
			atistContestevents: builder.query({
				query: () => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistContestEventEndPoint}`,
						method: "Get",
					};
				},
			}),
			atistlicenseAgreements: builder.query({
				query: (page) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistlicenseAgreementsEndPoint}?page=${page}`,
						method: "Get",
					};
				},
			}),
			atistResources: builder.query({
				query: (page) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistResourcesEndPoint}?page=${page}`,
						method: "Get",
					};
				},
			}),
			atistResourcedetails: builder.query({
				query: (slug) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistResourcesDetailsEndPoint}/${slug}`,
						method: "Get",
					};
				},
			}),
			atistContestdetails: builder.query({
				query: (slug) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistContestDetailsEndPoint}/${slug}`,
						method: "Get",
					};
				},
			}),
			atistUploadguideline: builder.query({
				query: () => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistUploadEndPoint}`,
						method: "Get",
					};
				},
			}),
			tipofTips: builder.query({
				query: () => {
					return {
						url: TipofTip,
						method: "Get",
					};
				},
			}),
		};
	},
});

export const {
	useArtistblogContentQuery,
	useArtistblogdetailsContentQuery,
	useAtistCodeofconductQuery,
	useAtistReleaseFormsQuery,
	useAtistContesteventsQuery,
	useAtistlicenseAgreementsQuery,
	useAtistResourcesQuery,
	useAtistResourcedetailsQuery,
	useAtistContestdetailsQuery,
	useAtistUploadguidelineQuery,
	useTipofTipsQuery,
} = ArtistBlogService;
export default ArtistBlogService;
