import React from "react";
import { useNavigate } from "react-router-dom";
import { backArrow } from "../constant";

// "back_icon text-end"
function Backhistory({ classNa }) {
	const navigate = useNavigate();
	const handleBack = () => {
		navigate(-1);
	};
	return (
		<div className="back_icon text-end">
			<button onClick={handleBack} className=" bg-transparent border-0">
				<img src={backArrow} alt="backArrow" className="img-fluid" />
			</button>
		</div>
	);
}

export default Backhistory;
