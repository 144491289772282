import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageFilter from "../../components/ImageFilter";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import ImagesMasnory from "../../components/ImagesMasnory";
import Masnory from "../../components/Masnory";
import { useSearchProductQuery } from "../../store/services/AuthServices";
import Loader from "../../components/Loader";
import { setUserToken } from "../../store/reducers/AuthReducer";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

function Searching() {
	const location = useLocation();
	const dispatch = useDispatch();
	const { contrubutorToken, userToken } = useSelector(
		(state) => state.AuthReducer,
	);

	let All_Values = location?.state;
	const Tagsids =
		All_Values === null ? "" : All_Values?.tag_ids?.map((item) => item?.id);
	let datads = {
		type: All_Values === null ? "Image" : All_Values?.type,
		tag_ids: Tagsids === undefined ? "" : Tagsids,
		package_type: All_Values === null ? "" : All_Values?.package_type,
		trend_ids: All_Values === null ? "" : All_Values?.trend_ids,
		category_id: All_Values === null ? "" : All_Values?.category_id,
		color_code: All_Values === null ? "" : All_Values?.color_code,
		search: All_Values === null ? "" : All_Values?.search,
		token: userToken ? userToken : "",
	};
	const { data, isLoading, refetch } = useSearchProductQuery(datads);
	// const offset = Math.ceil(data?.limit * currentpage);
	// useEffect(() => {
	// 	window.history.replaceState({}, document.title);
	// }, [location?.state]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data?.users !== undefined) {
			dispatch(
				setUserToken({
					token: userToken,
					user: data?.users,
				}),
			);
		}
	}, [data?.users]);

	// useEffect(() => {
	// 	return () => {
	// 		window.history.replaceState({}, document.title);
	// 	};
	// }, []);

	const navigate = useNavigate();

	useEffect(() => {
		if (contrubutorToken !== "") {
			return navigate("/");
		}
	}, []);
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Image Sec Start Here */}
			<section className="image_sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="img-txt-wrapper pt-5 ">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									{/* {data?.products?.length > 1 && (
										<div className="content-wrapper">
											<p>
												Search result... Showing
												{data?.total_size}
												...
											</p>
										</div>
									)} */}
								</div>
							</div>
						</div>
					</div>
					{data?.products?.length > 0 ? (
						All_Values === null || All_Values?.type === "Image" ? (
							<ImagesMasnory
								product={data?.products}
								reloadData={refetch}
								userData={data?.users}
							/>
						) : (
							<Masnory product={data?.products} />
						)
					) : (
						<h2
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
							}}
						>
							Product Not Found
						</h2>
					)}
				</div>
			</section>
			{/* Image Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
}

export default Searching;
