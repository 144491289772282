import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
	BlogImg1,
	BlogImg2,
	BlogImg3,
	RecentNews1,
	backArrow,
} from "../../constant";
import Backhistory from "../../components/Backhistory";
import "../../assets/css/blog.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useArtistblogdetailsContentQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import moment from "moment";
import Loader from "../../components/Loader";
import { useQuery } from "../../components/Query";

const BlogDetail = () => {
	let query = useQuery();
	let slugs = query.get("slug");
	const navigate = useNavigate();
	const { data, isLoading } = useArtistblogdetailsContentQuery(slugs);
	const BlogDetail = data?.data;
	const back = () => {
		window.history.back();
	};
	useEffect(() => {
		if (slugs === null || slugs === "") {
			return navigate("/blogs");
		}
	}, [slugs]);
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec blog-detail">
				<div className="container">
					<div className="back_gif_main d-flex justify-content-between align-items-center">
						<div className="testimonials-wrapper">
							<h1>Blog</h1>
						</div>
						<Backhistory classNa={"back_icon"} />
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="all_blogs">
								<div className="blog_box">
									<div className="blog-img">
										<figure>
											<img
												src={BlogDetail?.image_url}
												alt="Blog Detail Image"
												className="img-fluid"
											/>
										</figure>
										<div className="date">
											<h4>{moment(BlogDetail?.created_at).format("Do MMM")}</h4>
										</div>
									</div>
									<div className="blog-detail">
										<div className="posted">
											<h5>
												{BlogDetail?.addedby?.name} /{" "}
												<span> {BlogDetail?.category?.name}</span>{" "}
											</h5>
										</div>
										<div className="blog-description">
											<p
												dangerouslySetInnerHTML={{
													__html: BlogDetail?.description,
												}}
											></p>
											<div className="go-to-previous-page">
												<button onClick={back} className="btn">
													{" "}
													<i className="fa fa-angle-left"></i> Go Back
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default BlogDetail;
