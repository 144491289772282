import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import {
	useCreateSubscriptionApiMutation,
	useUpdatedSubscriptionApiMutation,
} from "../store/services/ContactServices";
import swal from "sweetalert";
// import { toast } from "react-toastify";
const stripePromise = loadStripe(
	"pk_test_51Ng2KLJTB1BVy7YrgllTykAbdEWftSwZkZED2hEDpl15wfeQoISyQXOZFOGmlbhWcwxpglzD14mcDO9QfK7BAMxa00HzmCbr1E",
);

const CheckoutForm = ({ slug, type, newsletter }) => {
	const navigate = useNavigate();
	//   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
	const dispatch = useDispatch();
	const [payProcessing, setPayProcessing] = useState(false);
	const [upgradesubscriptionData, responseDatas] =
		useUpdatedSubscriptionApiMutation();
	//   const [error, setError] = useState(false);
	const [done, setDone] = useState(false);
	// const { addressId, deliverId, Coupon, message } = useSelector(
	// 	(state) => state.product,
	// );
	// const product = useSelector((state) => state.cart);
	// const mapping = product.map((item) => {
	// 	let data = {
	// 		id: item.id,
	// 		qty: item.cartQuanity,
	// 		variation: item.variation,
	// 		color: item.colors,
	// 	};
	// 	return data;
	// });

	// useEffect(() => {
	// 	if (message == "Order Placed Successfully") {
	// 		navigate("/thankyou");
	// 		dispatch(clearCart());
	// 	}
	// }, [message]);

	useEffect(() => {
		if (responseDatas?.isSuccess) {
			if (responseDatas?.data?.message !== "You don't have any subscription") {
				window.location.href = responseDatas?.data?.data;
			} else {
				swal("Error!", responseDatas?.data?.message, "error");
			}
		}
	}, [responseDatas?.isSuccess]);

	useEffect(() => {
		if (responseDatas?.error?.data?.statusCode === 400) {
			swal("Error!", responseDatas?.error?.data?.message, "error");
		}
	}, [responseDatas?.isError]);

	const stripe = useStripe();
	const elements = useElements();
	const [paybutton, setPayButton] = useState(true);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (elements == null) {
			return;
		}
		const cardElement = elements.getElement(CardElement);
		const payload = await stripe.createToken(cardElement);

		// setCardToken(payload?.token?.id);
		if (payload?.token?.id) {
			setPayProcessing(true);
			setDone(true);
			setPayButton(false);
			const formData = new FormData();
			formData.set("payment_type", "stripe");
			formData.set("slug", slug);
			formData.set("stripe_token", payload.token.id);
			formData.append("type", type);
			formData.append("newsletter", newsletter ? "yes" : "no");
			upgradesubscriptionData(formData);
			// formData.set("shipping_id", deliverId);
			// formData.set("cart", JSON.stringify(mapping));
			// dispatch(SendTokenStrip(formData));

			cardElement.clear();
			// dispatch(clearCart());

			return;
		} else {
			// setTimeout(() => {
			// 	closeForm();
			// }, 2000);
			cardElement.clear();
			return;
		}
	};

	return (
		<>
			<form>
				<CardElement
					options={{
						style: {
							base: {
								color: "#FFF",
							},
						},
					}}
					onChange={(e) => {
						if (e.complete) {
							setPayButton(false);
						} else {
							setPayButton(true);
						}
					}}
				/>
				<br />

				<ul className="list-inline button-group">
					<li className="button-group">
						<button
							type="submit"
							style={{
								width: "100%",
								padding: "12px 80px",
								cursor: "pointer",
								background: "#1aac7a",
								color: "#fff",
								fontSize: "18px",
								textTransform: "uppercase",
								fontWeight: "600",
								border: 0,
								transition: "0.6s",
								border: "2px solid #fff",
							}}
							className="btn form-control"
							disabled={!stripe || !elements || paybutton}
							onClick={handleSubmit}
							// onClick={props.handleClickOpen}
						>
							{responseDatas?.isLoading ? "Loading..." : "Pay Now"}
						</button>
					</li>
				</ul>
			</form>
		</>
	);
};

const UpgradeStripeFrom = ({ slug, type, newsletter }) => {
	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm slug={slug} type={type} newsletter={newsletter} />
		</Elements>
	);
};

export default UpgradeStripeFrom;
