import React, { useEffect } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-news.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useArtistblogdetailsContentQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import { useQuery } from "../../components/Query";
import { useNavigate } from "react-router-dom";
const ArtistBlogDetail = () => {
	let query = useQuery();
	let slugs = query.get("slug");
	const navigate = useNavigate();
	const { data, isLoading } = useArtistblogdetailsContentQuery(slugs);
	const BlogDetail = data?.data;
	const back = () => {
		window.history.back();
	};
	useEffect(() => {
		if (slugs === null || slugs === "") {
			return navigate("/artist-corner");
		}
	}, [slugs]);

	return (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* News detail Sec Start Here */}
			<section className="news-detail">
				<div className="container">
					<div className="row">
						<div className="col-lg-11">
							{isLoading ? (
								<>
									<Skeleton
										height="400px"
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
									<Skeleton
										height="50px"
										style={{ marginTop: 30 }}
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
									<Skeleton
										height="30px"
										style={{ marginTop: 30 }}
										count={3}
										baseColor="#05092cc7"
										containerClassName="avatar-skeleton"
									/>
								</>
							) : (
								<div className="news-detail-card-wrapper">
									<div className="news-detail-img-wrapper">
										<figure>
											<img
												src={BlogDetail?.image_url}
												className="img-fluid"
												alt="Blog Image"
											/>
										</figure>
									</div>
									<div className="news-detail-content-wrapper">
										{/* description */}
										<p
											dangerouslySetInnerHTML={{
												__html: BlogDetail?.description,
											}}
										></p>
										<div className="go-to-previous-page">
											<button onClick={back} className="btn">
												{" "}
												<i className="fa fa-angle-left"></i> Go Back
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* News detail Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistBlogDetail;
