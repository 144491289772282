import React, { useRef, useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import EyesIcon from "../../components/InputField/EyesIcon";
import {
	useContributorLoginMutation,
	useContributorStatussQuery,
} from "../../store/services/Contributor/AuthContributorServices";
import { setContributorToken } from "../../store/reducers/AuthReducer";
import { Logout } from "../../components/AuthSetup";
import Modal from "react-bootstrap/Modal";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const LoginCont = () => {
	const href = window?.location.href;
	const SplitData = href?.split("?")?.[1];
	const { data, isError } = useContributorStatussQuery(SplitData);
	const [Logindata, response] = useContributorLoginMutation();
	const [showss, setShow] = useState(false);

	const schema = yup.object().shape({
		email: yup.string().email().required("Email is Required"),
		password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Password is required"),
		privacyCheckbox: yup
			.boolean()
			.oneOf(
				[true],
				"You must agree to the Privacy Policy and Terms & Conditions",
			),
	});
	const [show, setShowss] = useState(SplitData === undefined ? false : true);
	const handleClose = () => setShowss(false);
	const handleShow = () => {
		setShow(true);
	};
	const Closemodal = () => {
		setShowss(false);
	};
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [subError, subErrors] = useState("");
	useEffect(() => {
		getcookiedata();
	}, []);

	const getcookiedata = () => {
		let Eml = getCookies("email");
		let PaS = getCookies("password");
		if (Eml && PaS) {
			setValue("email", Eml);
			setValue("password", PaS);
		}
	};

	const getCookies = (cemail) => {
		let email = cemail + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(email) === 0) {
				return c.substring(email.length, c.length);
			}
		}
		return "";
	};

	const HandleRemember = () => {
		const values = getValues();
		let email = values?.email;
		let password = values?.password;
		document.cookie = `email=${email};path=http://localhost:3000/login-contributor`;
		document.cookie = `password=${password};path=http://localhost:3000/login-contributor`;
	};

	useEffect(() => {
		if (response?.data?.statusCode === 200) {
			swal("Success!", response?.data?.message, "success");
			dispatch(setContributorToken(response?.data?.data));
			Logout();
			window.localStorage.setItem(
				"ContributorData",
				JSON.stringify(response?.data?.data),
			);
			reset();
			navigate("/artist-account");
		}
		if (response?.data?.statusCode === 204) {
			swal("Success!", response?.data?.message, "error");
		}
	}, [response?.data?.statusCode]);

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors?.length > 0) {
			if (response?.isError && response?.error?.data?.errors) {
				setError("email", {
					type: "manual",
					message: response?.error?.data?.errors?.email,
				});
				setError("password", {
					type: "manual",
					message: response?.error?.data?.errors?.password,
				});
			}
		}

		if (response?.error?.data?.statusCode === 400) {
			subErrors(response?.error?.data?.errors?.[0]?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
		if (response?.error?.data?.statusCode === 401) {
			subErrors(response?.error?.data?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
	}, [response?.isError]);

	const inputElement = useRef();

	const focusInput = () => {
		inputElement.current.focus();
	};
	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("email", data?.email);
		formData.append("password", data?.password);
		Logindata(formData);
	};
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<Modal show={show} onHide={handleClose} className="login-modal">
				<Modal.Body>
					<div className="login-card-wrapper">
						<button className="modal-close" onClick={Closemodal}>
							X
						</button>
						<div className="login-heading-wrapper">
							<h2>Email Verified</h2>
							<p>Your Account has been activated</p>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<section className="login-sec">
				<div className="container">
					<Backhistory />
					<div className="row pt-3">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Login to Your Account</h2>
									{/* <h2>Contributor Login</h2> */}
									<p>
										Dont't have an account?{" "}
										<Link to="/Register-page">Register</Link>
										{/* Become a contributor then?{" "} */}
										{/* <a href="/Register-page">Register</a> */}
									</p>
								</div>
								<p className="SubErrrors text-center">{subError}</p>
								<div className="login-form-wrapper">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group">
											<label htmlFor="">
												Enter Email <span>*</span>
											</label>
											<FormInput
												id="email"
												name="email"
												type="text"
												placeholder="Email"
												register={register}
												error={errors.email}
											/>
										</div>
										<div className="form-group setEyesIcon">
											<div className="pass-label-wrapper">
												<label htmlFor="">
													Password <span>*</span>
												</label>
												{/* <a href="/forget-password-contributor">
													Forgot Password?
												</a> */}
											</div>
											<FormInput
												id="password"
												type={showss ? "text" : "password"}
												name="password"
												placeholder="Password"
												register={register}
												error={errors.password}
											/>
											<EyesIcon showss={showss} setShow={setShow} />
										</div>
										<div className="d-flex justify-content-between setFonts">
											<label
												className="custom_check_box1"
												onClick={HandleRemember}
											>
												Remember Me
												<input type="checkbox" />
												<span className="checkmark1"></span>
											</label>
											<a href="/forget-password-contributor">
												Forgot Password?
											</a>
										</div>
										<div className="form-group">
											<button
												onClick={focusInput}
												className="btn form-control"
												type="submit"
											>
												{response?.isLoading ? "Loading..." : "Login"}{" "}
											</button>
										</div>
										{/* <div className="form-group prvcyCheck mt-3">
											<label
												className="custom_check_box1"
												htmlFor="privacyCheckboxbox"
											>
												Agree{" "}
												<Link style={{ color: "#00bbff" }} to="/Policy">
													Privacy Policy
												</Link>{" "}
												&{" "}
												<Link style={{ color: "#00bbff" }} to="/terms">
													Terms & Condition
												</Link>
												<input
													type="checkbox"
													id="privacyCheckboxbox"
													{...register("privacyCheckbox")}
												/>
												<span className="checkmark1"></span>
											</label>
											{errors.privacyCheckbox && (
												<p className="errormessage">
													{errors.privacyCheckbox.message}
												</p>
											)}
										</div> */}
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default LoginCont;
