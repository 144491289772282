import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/cart.css";
import { Link, useNavigate } from "react-router-dom";
import { cart2 } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../store/reducers/CartrReducer";
import currency from "currency-formatter";
import swal from "sweetalert";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Cart2 = () => {
	const { contrubutorToken, userToken } = useSelector(
		(state) => state.AuthReducer,
	);
	const { cart, total } = useSelector((item) => item?.cartReducers);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const HandleCancel = (e, obj) => {
		e.preventDefault();
		dispatch(removeItem(obj));
	};

	const HandlerChanger = (e) => {
		e.preventDefault();
		if (userToken === "") {
			swal("Error!", "Please Login First", "error");
		} else {
			navigate("/checkout");
		}
	};

	useEffect(() => {
		if (contrubutorToken !== "") {
			navigate("/");
		}
	}, []);
	const handleBack = () => {
		navigate(-1);
	};
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}

			{/* Cart Sec Start Here */}
			<section className="cart_sec cart2">
				{cart?.length !== 0 ? (
					<div className="container">
						<div className="back_gif_main d-flex justify-content-between align-items-center">
							<div className="testimonials-wrapper">
								<h1>My Cart</h1>
								{/* <p>Lorem ipsum dolor sit amet, consectetur</p> */}
							</div>
							<Backhistory classNa={"back_icon text-end"} />
						</div>
						<div className="cart_warpper">
							<div className="row">
								{cart?.map((data, index) => {
									let obj = {
										id: data?.id,
										name: data?.name,
									};
									return data?.item_type === "video" ? (
										<div className="col-lg-6" key={index}>
											<div className="cart-box main-cart-box">
												<div className="cart-img-wrap">
													<button
														onClick={(e) => HandleCancel(e, obj)}
														className="cancel-btn"
														style={{ zIndex: "2" }}
													>
														x
													</button>

													<img
														src={data?.thumbnail_url}
														className="img-fluid"
														alt="Product Video"
													/>
													{/* <video
														controls
														className="img-fluid"
														controlsList="nodownload nofullscreen noremoteplayback"
														disablePictureInPicture={true}
														style={{ borderRadius: "11px" }}
													>
														<source src={data?.video_url} type="video/mp4" />
													</video> */}
												</div>
												<div className="cart-main-content-wrap">
													<h5>
														Item ID <span className="number">{data?.code}</span>
													</h5>
													<h3>{data?.PerDownload}</h3>
													<h2>${data?.price}</h2>
													<p>{data?.file_resolution}</p>
												</div>
											</div>
										</div>
									) : (
										<div className="col-lg-6" key={index}>
											<div className="cart-box main-cart-box">
												<div className="cart-img-wrap">
													<button
														onClick={(e) => HandleCancel(e, obj)}
														className="cancel-btn"
													>
														x
													</button>
													<img
														src={data?.watermark_image_url}
														className="img-fluid"
														alt="Product Image"
													/>
												</div>
												<div className="cart-main-content-wrap">
													<h5>
														Item ID <span className="number">{data?.code}</span>
													</h5>
													<h3>{data?.PerDownload}</h3>
													<h2>${data?.price}</h2>
													<p>{data?.file_resolution}</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className="total-wrapper">
							<div className="item-total">
								<h5>
									<span className="property">Item Total :</span>
									<span className="value">
										{currency.format(total, {
											code: "USD",
										})}
									</span>
								</h5>
								{/* <h5>
								<span className="property">Package charges</span>
								<span className="value">$0</span>
							</h5>
							<h5>
								<span className="property">Taxes Applied :</span>
								<span className="value">-$5</span>
							</h5> */}
							</div>
							<div className="bottom-bar">
								<h5>
									<span className="property">Order Total</span>
									<span className="value">
										{currency.format(total, {
											code: "USD",
										})}
									</span>
								</h5>
								<div className="button-group">
									<button className="btn" onClick={handleBack}>
										Back To Browsing
									</button>
									{/* <Link to="/checkout" className="btn">
										Checkout
									</Link> */}
									<button onClick={HandlerChanger} className="btn">
										Checkout
									</button>
								</div>
							</div>
						</div>
					</div>
				) : (
					<h2 className="Headingset">Cart Is Empty</h2>
				)}
			</section>
			{/* Cart Sec End Here */}
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Cart2;
