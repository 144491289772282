import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/terms.css";
import { useTermsconditionQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";

const Terms = () => {
	const { data, isLoading } = useTermsconditionQuery();
	const Term = data?.data?.content;
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Faq starts here */}
			<section className="terms">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="terme-heading-wrapper">
								<h2>Terms & Conditions</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="terms-content-wrapper">
								<p
									dangerouslySetInnerHTML={{
										__html: Term?.value,
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Faq ends here */}
			<Footer />
		</>
	);
};

export default Terms;
