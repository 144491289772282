import React, { useRef, useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/login.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import EyesIcon from "../../components/InputField/EyesIcon";
import {
	useAuthLoginMutation,
	useAuthStatussQuery,
} from "../../store/services/AuthServices";
import { setUserToken } from "../../store/reducers/AuthReducer";
import { Contributorlogout } from "../../components/AuthSetup";
import Modal from "react-bootstrap/Modal";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Login = () => {
	const href = window?.location.href;
	const SplitData = href?.split("?")?.[1];
	const { data, isError } = useAuthStatussQuery(SplitData);
	const [showss, setShow] = useState(false);
	const [Logindata, response] = useAuthLoginMutation();
	const [checkId, setCheckId] = useState("");
	const [show, setShowss] = useState(SplitData === undefined ? false : true);
	const handleClose = () => setShowss(false);
	const handleShow = () => {
		setShow(true);
	};
	const Closemodal = () => {
		setShowss(false);
	};
	const schema = yup.object().shape({
		email: yup.string().email().required("Email is Required"),
		password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$",
				`should contain between 8 to 12 character atleast one digit,one uppercase,one symbol`,
			)
			.required("Password is required"),
		privacyCheckbox: yup
			.boolean()
			.oneOf(
				[true],
				"You must agree to the Privacy Policy and Terms & Conditions",
			),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [subError, subErrors] = useState("");
	useEffect(() => {
		getcookiedata();
	}, []);

	const getcookiedata = () => {
		let Eml = getCookies("email");
		let PaS = getCookies("password");
		if (Eml && PaS) {
			setValue("email", Eml);
			setValue("password", PaS);
		}
	};

	const getCookies = (cemail) => {
		let email = cemail + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(email) === 0) {
				return c.substring(email.length, c.length);
			}
		}
		return "";
	};

	const HandleRemember = () => {
		const values = getValues();
		let emi = values?.email;
		let pass = values?.password;
		document.cookie = `email=${emi};path=http://localhost:3000/login`;
		document.cookie = `password=${pass};path=http://localhost:3000/login`;
	};

	useEffect(() => {
		if (response?.isSuccess) {
			if (response?.data?.message === "Please Verify your account.") {
				swal("Error!", response?.data?.message, "error");
			} else {
				if (response?.data?.data?.length !== 0) {
					dispatch(setUserToken(response?.data?.data));
					swal("Success!", response?.data?.message, "success");
					window.localStorage.setItem(
						"userData",
						JSON.stringify(response?.data?.data),
					);
					reset();
					Contributorlogout();
				} else {
					swal("Error!", response?.data?.message, "error");
				}
				// navigate("/myaccount");
			}
		}
	}, [response?.isSuccess]);

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors?.length > 0) {
			if (response?.isError && response?.error?.data?.errors) {
				setError("email", {
					type: "manual",
					message: response?.error?.data?.errors?.email,
				});
				setError("password", {
					type: "manual",
					message: response?.error?.data?.errors?.password,
				});
			}
		}

		if (response?.error?.data?.statusCode === 400) {
			subErrors(response?.error?.data?.errors?.[0]?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
		if (response?.error?.data?.statusCode === 401) {
			subErrors(response?.error?.data?.message);
			setTimeout(() => {
				subErrors("");
			}, 5000);
		}
	}, [response?.isError]);

	const inputElement = useRef();

	const focusInput = () => {
		inputElement.current.focus();
	};
	const onSubmitHandler = (data) => {
		let formData = new FormData();
		formData.append("email", data?.email);
		formData.append("password", data?.password);
		Logindata(formData);
	};
	return (
		<>
			<Header />
			{/* Login sec starts here */}
			<Modal show={show} onHide={handleClose} className="login-modal">
				<Modal.Body>
					<div className="login-card-wrapper">
						<button className="modal-close" onClick={Closemodal}>
							X
						</button>
						<div className="login-heading-wrapper">
							<h2>Email Verified</h2>
							<p>Your Account has been activated</p>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<section className="login-sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="row pt-3">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login-card-wrapper">
								<div className="login-heading-wrapper">
									<h2>Login to Your Account </h2>
									<p>
										Don’t have an account? <a href="/Register-page">Register</a>
									</p>
									<p className="SubErrrors text-center">{subError}</p>
								</div>
								<div className="login-form-wrapper">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group">
											<label htmlFor="">
												Enter Email <span>*</span>
											</label>
											<FormInput
												id="email"
												name="email"
												type="email"
												placeholder="Email"
												register={register}
												error={errors.email}
											/>
										</div>
										<div className="form-group setEyesIcon">
											<div className="pass-label-wrapper">
												<label htmlFor="">
													Password <span>*</span>
												</label>
											</div>
											<FormInput
												id="password"
												type={showss ? "text" : "password"}
												name="password"
												placeholder="Password"
												register={register}
												error={errors.password}
											/>
											<EyesIcon showss={showss} setShow={setShow} />
										</div>
										<div className="d-flex justify-content-between setFonts">
											<label
												className="custom_check_box1"
												onClick={HandleRemember}
											>
												Remember Me
												<input type="checkbox" />
												<span className="checkmark1"></span>
											</label>
											<a href="/forget-password">Forgot Password?</a>
										</div>
										<div className="form-group">
											<button
												onClick={focusInput}
												className="btn form-control"
												type="submit"
											>
												{response?.isLoading ? "Loading..." : "Login"}{" "}
											</button>
										</div>
										{/* <div className="form-group prvcyCheck mt-3">
											<label
												className="custom_check_box1"
												htmlFor="privacyCheckboxbox"
											>
												Agree{" "}
												<Link style={{ color: "#00bbff" }} to="/Policy">
													Privacy Policy
												</Link>{" "}
												&{" "}
												<Link style={{ color: "#00bbff" }} to="/terms">
													Terms & Condition
												</Link>
												<input
													type="checkbox"
													id="privacyCheckboxbox"
													{...register("privacyCheckbox")}
												/>
												<span className="checkmark1"></span>
											</label>
											{errors.privacyCheckbox && (
												<p className="errormessage">
													{errors.privacyCheckbox.message}
												</p>
											)}
										</div> */}
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login sec ends here */}
			<Footer />
		</>
	);
};

export default Login;
