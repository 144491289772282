import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/pricing.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { usePricePageQuery } from "../../store/services/PricePackagesServices";
import PricingList from "./PricingList";
import SingleList from "./SingleList";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Pricing = () => {
	const { data, isLoading } = usePricePageQuery();
	const navigate = useNavigate();
	const subscribtionPlan = data?.data?.Plan;
	const singleProductPlan = data?.data?.ProductPackage;

	const { contrubutorToken, userToken } = useSelector(
		(state) => state.AuthReducer,
	);
	useEffect(() => {
		if (contrubutorToken !== "") {
			navigate("/");
		}
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}
			<section className="pricing-section">
				<div className="container">
					<div className="pricing-start">
						{/* This is Top Heading */}
						<div className="pricing-top-text">
							<div className="headings">
								<h1 className="mb-0">Pricing</h1>
								<h4>Subscriptions</h4>
							</div>
							{!userToken && (
								<div className="right_box d-flex align-items-center">
									<div className="register-option me-4">
										<Link to="/register-buyer">
											<h4>Register Now</h4>
										</Link>
										<span>And Get 12 Free Images</span>
									</div>
									<Backhistory classNa={"back_icon text-end"} />
								</div>
							)}
							{userToken && <Backhistory classNa={"back_icon text-end"} />}
						</div>
						{/* Here Pricing data Start */}
						<div className="pricingdata">
							<div className="thetable">
								<div className="row mx-0">
									<div className="col-lg-3 col-3 mt-3 px-2 pe-0">
										<div className="pricing-box simple">
											<div className="data-heading">
												<h4>Description Of Package</h4>
											</div>
											<div className="pricing-list">
												{subscribtionPlan?.map((item, index) => {
													return (
														<div className="single-price" key={index}>
															<span>{item?.name}</span>
														</div>
													);
												})}
											</div>
										</div>
									</div>
									<PricingList
										radioShow="no"
										description={
											subscribtionPlan?.[0]?.get_plans?.[0]?.description
										}
										firstPrice={subscribtionPlan?.[0]?.get_plans?.[0]?.price}
										secondPrice={subscribtionPlan?.[1]?.get_plans?.[0]?.price}
										thirdPrice={subscribtionPlan?.[2]?.get_plans?.[0]?.price}
										firstName={subscribtionPlan?.[0]?.name}
										SecondName={subscribtionPlan?.[1]?.name}
										thirdName={subscribtionPlan?.[2]?.name}
										firstSlug={subscribtionPlan?.[0]?.get_plans?.[0]?.slug}
										secondSlug={subscribtionPlan?.[1]?.get_plans?.[0]?.slug}
										thirdSlug={subscribtionPlan?.[2]?.get_plans?.[0]?.slug}
										link={"/cart"}
									/>
									<PricingList
										radioShow="yes"
										description={
											subscribtionPlan?.[1]?.get_plans?.[1]?.description
										}
										firstPrice={subscribtionPlan?.[0]?.get_plans?.[1]?.price}
										secondPrice={subscribtionPlan?.[1]?.get_plans?.[1]?.price}
										thirdPrice={subscribtionPlan?.[2]?.get_plans?.[1]?.price}
										firstName={subscribtionPlan?.[0]?.name}
										SecondName={subscribtionPlan?.[1]?.name}
										thirdName={subscribtionPlan?.[2]?.name}
										firstSlug={subscribtionPlan?.[0]?.get_plans?.[1]?.slug}
										secondSlug={subscribtionPlan?.[1]?.get_plans?.[1]?.slug}
										thirdSlug={subscribtionPlan?.[2]?.get_plans?.[1]?.slug}
										link={"/cart"}
									/>
									<PricingList
										radioShow="yes"
										description={
											subscribtionPlan?.[2]?.get_plans?.[2]?.description
										}
										firstPrice={subscribtionPlan?.[0]?.get_plans?.[2]?.price}
										secondPrice={subscribtionPlan?.[1]?.get_plans?.[2]?.price}
										thirdPrice={subscribtionPlan?.[2]?.get_plans?.[2]?.price}
										firstName={subscribtionPlan?.[0]?.name}
										SecondName={subscribtionPlan?.[1]?.name}
										thirdName={subscribtionPlan?.[2]?.name}
										firstSlug={subscribtionPlan?.[0]?.get_plans?.[2]?.slug}
										secondSlug={subscribtionPlan?.[1]?.get_plans?.[2]?.slug}
										thirdSlug={subscribtionPlan?.[2]?.get_plans?.[2]?.slug}
										index={2}
										link={"/cart"}
									/>
								</div>
							</div>
						</div>
						<div className="pricing-rules">
							<div className="row">
								<div className="col-lg-9 col-md-12 ms-auto">
									<div className="points-start">
										<p
											dangerouslySetInnerHTML={{
												__html: data?.data?.termsone?.value,
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
						<div className="terms-condition">
							<h6
								dangerouslySetInnerHTML={{
									__html: data?.data?.termstwo?.value,
								}}
							></h6>
						</div>
						<div className="headings pt-2">
							<h1 className="mb-0">Pricing</h1>
							<h4>Individual Assets</h4>
						</div>
						<div className="pricingdata">
							<div className="thetable">
								<div className="row mx-0">
									<div className="col-lg-4 col-3 mt-3 px-2 pe-0">
										<div className="pricing-box simple">
											<div className="data-heading">
												<h4>Description Of Package</h4>
											</div>
											<div className="pricing-list">
												{singleProductPlan?.map((item, index) => {
													return (
														<div
															className="single-price pricing_second"
															key={index}
														>
															<span>{item?.name}</span>
														</div>
													);
												})}
											</div>
										</div>
									</div>
									<SingleList
										firstPrice={
											singleProductPlan?.[0]?.get_product_packagse?.[0]?.price
										}
										secondPrice={
											singleProductPlan?.[1]?.get_product_packagse?.[0]?.price
										}
										thirdPrice={
											singleProductPlan?.[2]?.get_product_packagse?.[0]?.price
										}
										firstDetails={
											singleProductPlan?.[0]?.get_product_packagse?.[0]?.details
										}
										secondDetails={
											singleProductPlan?.[1]?.get_product_packagse?.[0]?.details
										}
										thirdDetails={
											singleProductPlan?.[2]?.get_product_packagse?.[0]?.details
										}
									/>
									<SingleList
										firstDetails={
											singleProductPlan?.[0]?.get_product_packagse?.[1]?.details
										}
										secondDetails={
											singleProductPlan?.[1]?.get_product_packagse?.[1]?.details
										}
										thirdDetails={
											singleProductPlan?.[2]?.get_product_packagse?.[1]?.details
										}
										firstPrice={
											singleProductPlan?.[0]?.get_product_packagse?.[1]?.price
										}
										secondPrice={
											singleProductPlan?.[1]?.get_product_packagse?.[1]?.price
										}
										thirdPrice={
											singleProductPlan?.[2]?.get_product_packagse?.[1]?.price
										}
									/>
									<SingleList
										firstPrice={
											singleProductPlan?.[0]?.get_product_packagse?.[2]?.price
										}
										secondPrice={
											singleProductPlan?.[1]?.get_product_packagse?.[2]?.price
										}
										thirdPrice={
											singleProductPlan?.[2]?.get_product_packagse?.[2]?.price
										}
										firstDetails={
											singleProductPlan?.[0]?.get_product_packagse?.[2]?.details
										}
										secondDetails={
											singleProductPlan?.[1]?.get_product_packagse?.[2]?.details
										}
										thirdDetails={
											singleProductPlan?.[2]?.get_product_packagse?.[2]?.details
										}
									/>
									<SingleList
										firstPrice={
											singleProductPlan?.[0]?.get_product_packagse?.[3]?.price
										}
										secondPrice={
											singleProductPlan?.[1]?.get_product_packagse?.[3]?.price
										}
										thirdPrice={
											singleProductPlan?.[2]?.get_product_packagse?.[3]?.price
										}
										firstDetails={
											singleProductPlan?.[0]?.get_product_packagse?.[3]?.details
										}
										secondDetails={
											singleProductPlan?.[1]?.get_product_packagse?.[3]?.details
										}
										thirdDetails={
											singleProductPlan?.[2]?.get_product_packagse?.[3]?.details
										}
									/>
								</div>
							</div>
						</div>
						<div className="terms-condition">
							<h6
								dangerouslySetInnerHTML={{
									__html: data?.data?.termsthree?.value,
								}}
							></h6>
						</div>
					</div>
				</div>
			</section>
			{/* Footer Start Here */}
			<Footer />
			{/* Footer Start Here */}
		</>
	);
};
export default Pricing;
