import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/terms.css";
import { useCopyrightQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

const Copyright = () => {
	const { data, isLoading } = useCopyrightQuery();
	const CopyRight = data?.data?.content;
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Faq starts here */}
			<section className="terms">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="terme-heading-wrapper">
									<h2>Copyright</h2>
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="terms-content-wrapper">
								<p
									dangerouslySetInnerHTML={{
										__html: CopyRight?.value,
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Faq ends here */}
			<Footer />
		</>
	);
};

export default Copyright;
