import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		// title: {
		// 	display: true,
		// 	text: " Chart",
		// },
	},
};

// const labels = [
// 	"",
// 	"FEB",
// 	"MAR",
// 	"APR",
// 	"MAY",
// 	"JUN",
// 	"JUL",
// 	"AUG",
// 	"SEP",
// 	"OCT",
// 	"NOV",
// 	"DEC",
// ];

// export

const ArtistChart = ({ data }) => {
	const subdata = {
		labels: data?.map((item) => item?.month),
		datasets: [
			{
				fill: true,
				label: "Montly Income",
				data: data?.map((item) => item?.count),
				borderColor: "#00BBFF",
				backgroundColor: "#043C69",
			},
		],
	};
	return (
		<>
			<Line options={options} data={subdata} />
		</>
	);
};

export default ArtistChart;
