import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	BaseUrl,
	agreement,
	contactUs,
	contributor,
	copyright,
	// detailPage,
	faqs,
	newsLetter,
	privacy_policy,
	terms_condition,
	testimonials,
	// videoSection,
	detailVariantssection,
	reviewsPage,
	subscriptionCreate,
	subscriptionCurrent,
	updateSubcription,
	imageFilterApi,
	PlaceorderApi,
	ThankyouApi,
	Addaddress,
	Getaddress,
	placeOrderapithanks,
	autherProducts,
	Products_review,
	help_help,
	Generaltips,
	DownloadImage,
	coupon_code,
	renewSubcription,
	DownloadImageUrl,
	agrement_terms,
} from "../../utils/Urls";

const ContactServices = createApi({
	reducerPath: "contactdata",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrl,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	tagTypes: ["editaddress", "ProductReview"],
	endpoints: (builder) => {
		return {
			contactUS: builder.mutation({
				query: (contactData) => {
					return {
						url: contactUs,
						method: "POST",
						body: contactData,
					};
				},
			}),
			newsLetter: builder.mutation({
				query: (contactData) => {
					return {
						url: newsLetter,
						method: "POST",
						body: contactData,
					};
				},
			}),
			reviewApi: builder.mutation({
				query: (contactData) => {
					return {
						url: reviewsPage,
						method: "POST",
						body: contactData,
					};
				},
				invalidatesTags: ["ProductReview"],
			}),
			faqs: builder.query({
				query: () => {
					return {
						url: faqs,
						method: "GET",
					};
				},
			}),
			testimonials: builder.query({
				query: () => {
					return {
						url: testimonials,
						method: "GET",
					};
				},
			}),
			copyright: builder.query({
				query: () => {
					return {
						url: copyright,
						method: "GET",
					};
				},
			}),
			termscondition: builder.query({
				query: () => {
					return {
						url: terms_condition,
						method: "GET",
					};
				},
			}),
			agreementData: builder.query({
				query: () => {
					return {
						url: agrement_terms,
						method: "GET",
					};
				},
			}),
			websiteTerm: builder.query({
				query: (slug) => {
					return {
						url: `${terms_condition}?slug=${slug}`,
						method: "GET",
					};
				},
			}),
			privacypolicy: builder.query({
				query: () => {
					return {
						url: privacy_policy,
						method: "GET",
					};
				},
			}),
			contributor: builder.query({
				query: () => {
					return {
						url: contributor,
						method: "GET",
					};
				},
			}),
			agreement: builder.query({
				query: () => {
					return {
						url: agreement,
						method: "GET",
					};
				},
			}),

			// videoSection: builder.query({
			// 	query: (data) => {
			// 		return {
			// 			url: `${videoSection}?type=${data?.type}&author_slug=${
			// 				data?.autherName ? data?.autherName : ""
			// 			}&tag_ids=${
			// 				data?.tag_ids?.length === 0 || data?.tag_ids === ""
			// 					? ""
			// 					: data?.tag_ids
			// 			}&package_type=${
			// 				data?.package_type === null || data?.package_type === ""
			// 					? ""
			// 					: data?.package_type
			// 			}&trend_ids=${
			// 				data?.trend_ids === null || data?.trend_ids === ""
			// 					? ""
			// 					: data?.trend_ids
			// 			}&category_id=${
			// 				data?.category_id === null || data?.category_id === ""
			// 					? ""
			// 					: data?.category_id
			// 			}&color_code=${
			// 				data?.color_code === undefined || data?.color_code === null
			// 					? ""
			// 					: data?.color_code
			// 			}&page=${data?.page}&token=${data?.token ? data?.token : ""}`,
			// 			method: "GET",
			// 		};
			// 	},
			// }),
			// detailSection: builder.query({
			// 	query: (data) => {
			// 		return {
			// 			url: `${detailPage}?slug=${data?.slug}&token=${
			// 				data?.token ? data?.token : ""
			// 			}`,
			// 			method: "GET",
			// 		};
			// 	},
			// }),
			detailVariants: builder.query({
				query: (slug) => {
					return {
						url: `${detailVariantssection}?slug=${slug}`,
						method: "GET",
					};
				},
			}),
			createSubscriptionApi: builder.mutation({
				query: (contactData) => {
					return {
						url: subscriptionCreate,
						method: "POST",
						body: contactData,
					};
				},
			}),
			updatedSubscriptionApi: builder.mutation({
				query: (contactData) => {
					return {
						url: updateSubcription,
						method: "POST",
						body: contactData,
					};
				},
			}),
			renewSubscriptionApi: builder.mutation({
				query: (contactData) => {
					return {
						url: renewSubcription,
						method: "POST",
						body: contactData,
					};
				},
			}),
			placeOrderDataApi: builder.mutation({
				query: (contactData) => {
					return {
						url: PlaceorderApi,
						method: "POST",
						body: contactData,
					};
				},
			}),
			addaddressApi: builder.mutation({
				query: (contactData) => {
					return {
						url: Addaddress,
						method: "POST",
						body: contactData,
					};
				},
				invalidatesTags: ["editaddress"],
			}),
			currentSubscriptionApi: builder.query({
				query: () => {
					return {
						url: subscriptionCurrent,
						method: "GET",
					};
				},
			}),
			productsFilter: builder.query({
				query: () => {
					return {
						url: imageFilterApi,
						method: "GET",
					};
				},
			}),
			thankyouFilter: builder.query({
				query: (type) => {
					return {
						url: `${ThankyouApi}?${type}`,
						method: "GET",
					};
				},
			}),
			getAddressApi: builder.query({
				query: () => {
					return {
						url: Getaddress,
						method: "GET",
					};
				},
				providesTags: ["editaddress"],
			}),
			getThanksPlaceApi: builder.query({
				query: (id) => {
					return {
						url: `${placeOrderapithanks}?order_id=${id}`,
						method: "GET",
					};
				},
			}),

			getHelpData: builder.query({
				query: (data) => {
					return {
						url: `${help_help}`,
						method: "GET",
					};
				},
			}),
			getProductreview: builder.query({
				query: (id) => {
					return {
						url: `${Products_review}/${id}`,
						method: "GET",
					};
				},
				providesTags: ["ProductReview"],
			}),
			generalTips: builder.query({
				query: (id) => {
					return {
						url: `${Generaltips}`,
						method: "GET",
					};
				},
			}),
			downloadImageUrl: builder.query({
				query: (data) => {
					return {
						url: `${DownloadImageUrl}?filename=${data?.url}&id=${data?.id}&token=${data?.userToken}`,
						method: "GET",
					};
				},
			}),
			downloadimage: builder.mutation({
				query: (id) => {
					return {
						url: `${DownloadImage}`,
						method: "POST",
						body: id,
					};
				},
			}),
			couponCode: builder.mutation({
				query: (data) => {
					return {
						url: `${coupon_code}`,
						method: "POST",
						body: data,
					};
				},
			}),
			autherproducts: builder.query({
				query: (data) => {
					return {
						url: `${autherProducts}?type=${data?.type}&author_slug=${
							data?.autherName ? data?.autherName : ""
						}&tag_ids=${
							data?.tag_ids?.length === 0 || data?.tag_ids === ""
								? ""
								: data?.tag_ids
						}&package_type=${
							data?.package_type === null || data?.package_type === ""
								? ""
								: data?.package_type
						}&trend_ids=${
							data?.trend_ids === null || data?.trend_ids === ""
								? ""
								: data?.trend_ids
						}&category_id=${
							data?.category_id === null || data?.category_id === ""
								? ""
								: data?.category_id
						}&color_code=${
							data?.color_code === undefined || data?.color_code === null
								? ""
								: data?.color_code
						}&page=${data?.page ? data?.page : 1}`,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const {
	useContactUSMutation,
	useNewsLetterMutation,
	useReviewApiMutation,
	useRenewSubscriptionApiMutation,
	useCreateSubscriptionApiMutation,
	useUpdatedSubscriptionApiMutation,
	usePlaceOrderDataApiMutation,
	useAddaddressApiMutation,
	useFaqsQuery,
	useTestimonialsQuery,
	useCopyrightQuery,
	useCurrentSubscriptionApiQuery,
	useTermsconditionQuery,
	usePrivacypolicyQuery,
	useContributorQuery,
	useAgreementQuery,
	// useDetailSectionQuery,
	useDetailVariantsQuery,
	// useVideoSectionQuery,
	useProductsFilterQuery,
	useThankyouFilterQuery,
	useGetAddressApiQuery,
	useGetThanksPlaceApiQuery,
	useAutherproductsQuery,
	useGetProductreviewQuery,
	useWebsiteTermQuery,
	useGeneralTipsQuery,
	useGetHelpDataQuery,
	useDownloadImageUrlQuery,
	useAgreementDataQuery,
	useDownloadimageMutation,
	useCouponCodeMutation,
} = ContactServices;
export default ContactServices;
