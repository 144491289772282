import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/thank-you.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useThankyouFilterQuery } from "../../store/services/ContactServices";
import currency from "currency-formatter";
import Loader from "../../components/Loader";
const ThankYou = () => {
	const href = window?.location.href;
	const { data, isLoading } = useThankyouFilterQuery(href?.split("?")?.[1]);
	const thanksyou = data?.data?.subscription;
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Thank You Start Here */}
			<section className="thank_you">
				<div className="container">
					<div className="heading_wrapper">
						<h2>THANK YOU FOR PURCHASING</h2>
						<h4>YOUR DOWNLOAD LINK IS READY</h4>
						<div className="back-button">
							<Link to="/">Go back to homepage</Link>
						</div>
						<p className="note">
							<i>Check your mail for the paid receipt</i>
						</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<div className="product-detail">
								<div className="cart-box-wrapper">
									<div className="title">
										<h3>PRODUCT DETAILS</h3>
									</div>
									<div className="cart-box">
										{/* <button className="cancel-btn">x</button> */}
										<h3>{thanksyou?.get_package?.packagetype?.name}</h3>

										<h2>
											{currency.format(thanksyou?.get_package?.price, {
												code: "USD",
											})}
										</h2>
										<p>
											{thanksyou?.get_package?.video_limit} Video Downloads per
											month
										</p>
										<p>
											{thanksyou?.get_package?.images_limit} Image Downloads per
											month
										</p>
										<p
											dangerouslySetInnerHTML={{
												__html: thanksyou?.get_package?.description,
											}}
										></p>
									</div>
								</div>
								<div className="item-total">
									<h4>
										<span className="property">Item Total :</span>
										<span className="value">
											{currency.format(thanksyou?.get_package?.price, {
												code: "USD",
											})}
										</span>
									</h4>
								</div>
								<div className="order-total">
									<h4>
										<span className="property">Order Total</span>
										<span className="value">
											{currency.format(thanksyou?.get_package?.price, {
												code: "USD",
											})}
										</span>
									</h4>
								</div>
							</div>
							<div className="button-group">
								<Link className="btn" to="/MySubscription">
									View Subscription
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Thank You End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ThankYou;
