import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
	const { pages, handlePageClick } = props;
	return (
		<>
			<ReactPaginate
				previousLabel={<i className="fa fa-arrow-left"></i>}
				nextLabel={<i className="fa fa-arrow-right"></i>}
				breakLabel={"..."}
				pageCount={pages}
				marginPagesDisplayed={2}
				pageRangeDisplayed={3}
				onPageChange={handlePageClick}
				containerClassName={"pagination justify-content-start mt-2"}
				pageClassName={"page-item"}
				pageLinkClassName={"page-link"}
				previousClassName={"page-item"}
				previousLinkClassName={"page-link"}
				nextClassName={"page-item"}
				nextLinkClassName={"page-link"}
				breakClassName={"page-item"}
				breakLinkClassName={"page-link"}
				activeClassName={"active"}
			/>
		</>
	);
};

export default Pagination;
